import { useEffect, useState } from 'react';
import './ChangelogPanel.css'
import { format as formatDate } from 'date-fns'
import { HomeTabPanel, Panel, PanelContentCentered, PanelHeader } from "./Shared"
import { Badge, Center, Divider, Group, Header, List, ListItem, Loader, ScrollArea, Stack, Text, Title } from "@mantine/core"
import parse, { domToReact } from 'html-react-parser';
import axios from 'axios';
import { useOutletContext } from 'react-router-dom';




export const ReleaseLogPanel = () => {
    return (
        <Panel>
            <PanelHeader title="Release Notes" />
            <ReleaseLogPanelBody />
        </Panel>
    )
}

const ReleaseLogPanelBody = () => {

    const { authServices } = useOutletContext()

    const [releaseLog, setReleaseLog] = useState()
    const [status, setStatus] = useState("idle")
    const [error, setError] = useState(null)

    useEffect(() => {
        getReleaseLog()
    }, [])

    const getReleaseLog = () => {
        axios.get("/api/precious/home/get_changelog")
        .then((data) => {
            setReleaseLog(data.data)
            setStatus("success")
        })
        .catch((error) => {
            if (error.response?.status === 401) {
                authServices.logout()
                return
            }
            console.error(error)
            setError(error.toJSON())
            setStatus("failed")
        })
        setStatus("loading")
    }

    if (status==="loading") {
        return (
        <PanelContentCentered>
            <Loader variant="bars" color="pink" size="sm" />
        </PanelContentCentered>
        )
    }
    else if (status==="failed") {
        return (
            <PanelContentCentered>
                <Text size="sm">{error?.message}</Text>
            </PanelContentCentered>
        )
    }
    else if (status==="success") {
        return (
            <ReleaseLog releaseLog={releaseLog} />
        )
    }
    return null
}

const ReleaseLog = ({releaseLog}) => {
    return (
        <ScrollArea>
            <Stack spacing="xs" py={10} px={20} >
                {releaseLog.map(release => <Release key={release.semantic_version} release={release} />)}
            </Stack>
        </ScrollArea>
    )
}

const Release = ({release}) => {

    const releaseDate = release.release_date
    const changes = release.changes

    const releaseLabelFormatted = releaseDate ? formatDate(new Date(releaseDate), "MMMM d, uuuu") : "N/A"

    return (
        <Stack spacing={0} >
            <Text size="md" weight={500}>{releaseLabelFormatted}</Text>
            <Divider size="xs" my={0} mb={6} style={{width: "85%"}} />
            <Changes changes={changes} />
        </Stack>
    )
}

const Changes = ({changes}) => {
    return (
        <Stack justify="flex-start" spacing="xs" >
            {changes.map(change => <Change change={change} />)}
        </Stack>
    )
}

const Change = ({change}) => {

    const category = change.category
    const subcategory = change.subcategory
    const content = change.content

    let color
    switch (category) {
        case "Forms":
            color = "cyan"
            break
        case "Tables":
            color = "orange"
            break
        case "Features":
            color = "violet"
            break
    }

    return (
        <Stack spacing={4}>
            <Group position="left" spacing={0}>
                <Badge className="change-category" color={color} size="sm" style={{textTransform: "capitalize"}}>
                    <Group spacing={3}>
                        <Text style={{fontSize: "12px", fontWeight: 400}}>{category}</Text>
                        {subcategory && <>
                            <Text style={{fontSize: "12px", fontWeight: 400}}> / </Text>
                            <Text style={{fontSize: "12px", fontWeight: 600}}>{subcategory}</Text>
                        </>}
                    </Group>
                </Badge>
            </Group>
            <ChangeContent>{content}</ChangeContent>
        </Stack>
    )
}
const ChangeContent = ({children}) => {

    const options = {
        replace({type, name, children}) {
            if (type==="tag" && name==="p") return <Text size="xs">{domToReact(children, options)}</Text>
            if (type==="tag" && name==="ol") return <List size="xs" type="ordered" withPadding style={{color: "inherit"}}>{domToReact(children, options)}</List>
            if (type==="tag" && name==="ul") return <List size="xs" withPadding style={{color: "inherit"}}>{domToReact(children, options)}</List>
            if (type==="tag" && name==="li") return <ListItem>{domToReact(children, options)}</ListItem>
        }
    }

    return (
        <Stack spacing={0}>
            {parse(children, options)}
        </Stack>
    )
}