import PlantPanel from '../view_panel/plant/Plant'
import ProjectTaxPanel from '../view_panel/plant/ProjectTax'
import PersonnelPanel from '../view_panel/personnel/Personnel'
import EPCPanel from '../view_panel/contracts/epc/Epc'
import OfftakePanel from '../view_panel/contracts/offtake/Offtake'
import OandMPanel from '../view_panel/contracts/oandm/OandM'
import InterconnectionPanel from '../view_panel/contracts/interconnection/Interconnection'
import TaxEquityPanel from '../view_panel/contracts/tax_equity/TaxEquity'
import PermittingPanel from '../view_panel/contracts/permitting/Permitting'
import LandownerPanel from '../view_panel/contracts/landowner/Landowner'
import DebtPartnerPanel from '../view_panel/contracts/debt_partner/DebtPartner'
import EquipmentContractPanel from '../view_panel/contracts/equipment/Equipment'

import ProjectInverterPanel from '../view_panel/equipment/ProjectInverter';
import ProjectModulePanel from '../view_panel/equipment/ProjectModule';
import ProjectTransformerPanel from '../view_panel/equipment/ProjectTransformer';
import ProjectTrackerPanel from '../view_panel/equipment/ProjectTracker';
import ProjectBESSPanel from '../view_panel/equipment/ProjectBESS';
import ProjectBreakerPanel from '../view_panel/equipment/ProjectBreaker';
import ProjectTechnicalDueDiligence from '../view_panel/plant/TechnicalDueDiligence'
import ProjectTechnicalDataSiteChecklist from '../view_panel/plant/TechnicalDatasiteCheck'
import ProjectStageMilestoneMatrix from '../view_panel/plant/StageMilestoneMatrix'
import RiskRegisterChecklist from '../view_panel/plant/RiskRegister'
import OfftakeContractOffer from '../view_panel/contract_offers/OfftakeContractOffer'
import InterconnectionExpectedCostsPanel from '../view_panel/InterconnectionCosts'
import TemporalProjectPricingPanel from '../view_panel/plant/ProjectPricing'
import LessonLearnedPanel from '../view_panel/plant/LessonLearned'
import ProjectMilestoneDatesPanel from '../view_panel/plant/ProjectMilestoneDates'
import EnvironmentalAndPermittingChecklistPanel from '../view_panel/contracts/permitting/EnvironmentalAndPermittingChecklist'
import { DevelopmentReportCommentsPanel, ConstructionReportCommentsPanel } from '../view_panel/ReportingComments'

export class PanelConfig {
    constructor(key, label, endpoint, component, allowNullProject=false) {
        this.key = key
        this.label = label
        this.endpoint = endpoint
        this.component = component
        this.allowNullProject = allowNullProject
    }
}
export class ViewConfig {
    constructor(key, label, endpoint, components, allowNullProject=false) {
        this.key = key
        this.label = label
        this.endpoint = endpoint
        this.components = components.map(component => {
            return new PanelConfig(component.key, component.label, component.endpoint, component.component, component.allowNullProject)
        })
        this.allowNullProject = allowNullProject
    }
}

export const PanelConfigs = {
    plant: {
        key: "plant",
        label: "Plant",
        endpoint: "/api/precious/table/plant",
        component: PlantPanel,
    },
    plantTax: {
        key: "plantTax",
        label: "Project Tax",
        endpoint: "/api/precious/table/plant",
        component: ProjectTaxPanel
    },
    personnel: {
        key: "personnel",
        label: "Personnel",
        endpoint: "/api/precious/table/personnel",
        component: PersonnelPanel
    },
    epcContract: {
        key: "epcContract",
        label: "EPC",
        endpoint: "/api/precious/table/epc_contract",
        component: EPCPanel
    }, 
    offtakeContract: {
        key: "offtakeContract",
        label: "Offtake",
        endpoint: "/api/precious/table/offtake_contract",
        component: OfftakePanel
    },
    interconnectionContract: {
        key: "interconnectionContract",
        label: "Interconnection",
        endpoint: "/api/precious/table/interconnection_contract",
        component: InterconnectionPanel
    },
    permittingContract: {
        key: "permittingContract",
        label: "Permits",
        endpoint: "/api/precious/table/permitting_contract",
        component: PermittingPanel
    },
    landownerContract: {
        key: "landownerContract",
        label: "Landowner",
        endpoint: "/api/precious/table/landowner_contract",
        component: LandownerPanel
    },
    taxEquityContract: {
        key: "taxEquityContract",
        label: "Tax Equity",
        endpoint: "/api/precious/table/tax_equity_contract",
        component: TaxEquityPanel
    },
    debtPartner: {
        key: "debtPartner",
        label: "Debt Partner",
        endpoint: "/api/precious/table/debt_partner_contract",
        component: DebtPartnerPanel
    },
    oandmContract: {
        key: "oandmContract",
        label: "O&M",
        endpoint: "/api/precious/table/oandm_contract",
        component: OandMPanel
    },
    projectInverter: {
        key: "projectInverter",
        label: "Inverters",
        endpoint: "/api/precious/table/project_inverter",
        component: ProjectInverterPanel
    },
    projectModule: {
        key: "projectModule",
        label: "Module",
        endpoint: "/api/precious/table/project_module",
        component: ProjectModulePanel
    },
    projectTransformer: {
        key: "projectTransformer",
        label: "Transformer",
        endpoint: "/api/precious/table/project_transformer",
        component: ProjectTransformerPanel
    },
    projectTracker: {
        key: "projectTracker",
        label: "Tracker",
        endpoint: "/api/precious/table/project_tracker",
        component: ProjectTrackerPanel
    },
    projectBess: {
        key: "projectBess",
        label: "BESS",
        endpoint: "/api/precious/table/project_bess",
        component: ProjectBESSPanel
    },
    projectBreaker: {
        key: "projectBreaker",
        label: "Breaker",
        endpoint: "/api/precious/table/project_breaker",
        component: ProjectBreakerPanel
    },
    technicalDueDiligence: {
        key: "technicalDueDiligence",
        label: "Technical Due-Diligence",
        endpoint: "/api/precious/table/technical_due_diligence",
        component: ProjectTechnicalDueDiligence
    },
    technicalDataSiteChecklist: {
        key: "technicalDataSiteChecklist",
        label: "Technical Datasite Checklist",
        endpoint: "/api/precious/table/technical_datasite_checklist",
        component: ProjectTechnicalDataSiteChecklist
    },
    offtakeContractOffer: {
        key: "offtakeContractOffer",
        label: "Offtake Offer",
        endpoint: "/api/precious/table/offtake_contract_offer",
        component: OfftakeContractOffer
    },
    equipmentContract: {
        key: "equipmentContract",
        label: "Equipment Contract",
        endpoint: "/api/precious/table/equipment_purchase_contract",
        component: EquipmentContractPanel
    },
    riskRegister: {
        key: "riskRegister",
        label: "Risk Register",
        endpoint: "/api/precious/table/risk_register",
        component: RiskRegisterChecklist
    },
    interconnectionPayments: {
        key: "interconnectionPayments",
        label: "Interconnection Expected Costs",
        endpoint: "/api/precious/table/interconnection_expected_costs",
        component: InterconnectionExpectedCostsPanel
    },
    stageMilestoneMatrix: {
        key: "stageMilestoneMatrix",
        label: "Stage Gate Matrix",
        endpoint: "/api/precious/table/stage_milestone_matrix",
        component: ProjectStageMilestoneMatrix
    },
    temporalProjectPricing: {
        key: "temporalProjectPricing",
        label: "Project Pricing",
        endpoint: "/api/precious/table/temporal_project_pricing",
        component: TemporalProjectPricingPanel
    },
    lessonLearned: {
        key: "lessonLearned",
        label: "Lesson Learned",
        endpoint: "/api/precious/table/lesson_learned",
        component: LessonLearnedPanel,
        allowNullProject: true,
    },
    projectMilestoneDates: {
        key: "projectMilestoneDates",
        label: "Project Milestone Dates",
        endpoint: "/api/precious/table/project_milestone_dates",
        component: ProjectMilestoneDatesPanel,
    },
    environmentalAndPermittingChecklist: {
        key: "environmentalAndPermittingChecklist",
        label: "Environmental and Permitting Checklist",
        endpoint: "/api/precious/table/environmental_and_permitting_checklist",
        component: EnvironmentalAndPermittingChecklistPanel,
    },
    developmentReportingComments: {
        key: "developmentReportingComments",
        label: "Development Reporting",
        endpoint: "/api/precious/table/report_comments",
        component: DevelopmentReportCommentsPanel,
    },
    constructionReportingComments: {
        key: "constructionReportingComments",
        label: "Construction Reporting",
        endpoint: "/api/precious/table/report_comments",
        component: ConstructionReportCommentsPanel,
    }
}

export const StandAlonePlantPanelConfig = new PanelConfig(PanelConfigs.plant.label, PanelConfigs.plant.label, PanelConfigs.plant.endpoint, PanelConfigs.plant.component)


const GeneralViewConfig = new ViewConfig(
    "view_general",
    "General",
    "/api/precious/view/general",
    [
        PanelConfigs.plant, PanelConfigs.projectMilestoneDates, PanelConfigs.personnel, PanelConfigs.lessonLearned
    ]
)
const EquipmentViewConfig = new ViewConfig(
    "view_equipment",
    "Equipment",
    "/api/precious/view/equipment",
    [
        PanelConfigs.projectInverter, PanelConfigs.projectModule, PanelConfigs.projectTransformer,
        PanelConfigs.projectTracker, PanelConfigs.projectBess, PanelConfigs.projectBreaker
    ]
)
const ContractViewConfig = new ViewConfig(
    "view_contracts",
    "Contracts",
    "/api/precious/view/contracts",
    [
        PanelConfigs.epcContract, PanelConfigs.offtakeContract, PanelConfigs.oandmContract,
        PanelConfigs.interconnectionContract, PanelConfigs.landownerContract,
        PanelConfigs.taxEquityContract, PanelConfigs.debtPartner, PanelConfigs.permittingContract
    ]
)
const DevelopmentViewConfig = new ViewConfig(
    "view_development",
    "Development",
    "/api/precious/view/development",
    [
        PanelConfigs.stageMilestoneMatrix, PanelConfigs.riskRegister, PanelConfigs.environmentalAndPermittingChecklist, 
    ]
)
const EngineeringViewConfig = new ViewConfig(
    "view_engineering",
    "Engineering",
    "/api/precious/view/engineering",
    [
        PanelConfigs.technicalDueDiligence, PanelConfigs.technicalDataSiteChecklist, 
    ]
)
const OriginationViewConfig = new ViewConfig(
    "view_origination",
    "Origination",
    "/api/precious/view/origination",
    [
        PanelConfigs.offtakeContractOffer
    ]
)
const InterconnectionViewConfig = new ViewConfig(
    "view_interconnection",
    "Interconnection",
    "/api/precious/view/interconnection",
    [
        PanelConfigs.interconnectionPayments
    ]
)
const ProcurementViewConfig = new ViewConfig(
    "view_procurement",
    "Procurement",
    "/api/precious/view/procurement",
    [
        PanelConfigs.equipmentContract, PanelConfigs.temporalProjectPricing
    ]
)
const ReportingViewConfig = new ViewConfig(
    "view_reporting",
    "Reporting",
    "/api/precious/view/reporting",
    [
        PanelConfigs.developmentReportingComments, PanelConfigs.constructionReportingComments
    ],
    true
)
const MiscellaneousViewConfig = new ViewConfig(
    "view_misc",
    "Miscellaneous",
    "/api/precious/view/miscellaneous",
    [
        PanelConfigs.plantTax
    ],
    true
)


export const views = [GeneralViewConfig, ContractViewConfig, EquipmentViewConfig, DevelopmentViewConfig, EngineeringViewConfig, InterconnectionViewConfig, ProcurementViewConfig, OriginationViewConfig, ReportingViewConfig, MiscellaneousViewConfig]