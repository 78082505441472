import Highcharts from 'highcharts';

export var defaultOptions = {
    chart: {
        type: 'line',
        zoomType: 'x'
    },
    credits: {
        enabled: false
    },
    tooltip: {
        followPointer: true,
        shared: true,
        crosshairs: true,
        valueDecimals: 2,
        /*dateTimeLabelFormats: {
            minute:  '%A, %b %e, %I:%M %p',
            hour: '%A, %b %e, %I:%M %p',
        }*/
        xDateFormat: '%A, %b %e, %I:%M:%S %p',
    },
    xAxis: {
        type: 'datetime',
        dateTimeLabelFormats: {
            minute:  '%I:%M %p',
            hour: '%I:%M %p',
        }
    },
    title: {
        text: '',
        floating: true
    },
    plotOptions: {
        series: {
            marker: false,
        }
    }
}

function _formatLabelWithUnits(formatFor, units, tooltipConfigObject=null) {
    var prefixUnitsEnum = ["$"]
    if (formatFor==="axisLabel") {
        return (prefixUnitsEnum.includes(units) ? `${units} {value}` : `{value} ${units}`)
    }
    else if (formatFor==="tooltipLabel") {
        var format = (prefixUnitsEnum.includes(units) ? "valuePrefix" : "valueSuffix")
        tooltipConfigObject[format] = " " + units + " "
    }
    else {
        throw Error("Can only format for axisLabel, tooltipLabel")
    }    
}

export function convertObjectToSeries(data, round) {
    var mapFunc = (x) => [Number(x[0]), x[1]]
    /*if (Number.isInteger(round)) {
        const constant = Number("1".concat(new Array(round).fill(0).join("")))
        mapFunc = (x) => [Number(x[0]), Math.round(x[1]*constant)/constant]
    }
    else {
        mapFunc = (x) => [Number(x[0]), x[1]]
    }*/
    return Object.entries(data).map(mapFunc)
}

export function createYAxes(axes, secondary=false) {

    const createAxis = (axis) => Object({
        labels: {
            format: _formatLabelWithUnits("axisLabel", axis.units)
        },
        title: {
            text: (axis.title || axis.units)
        },
        opposite: secondary,
    })

    if (axes instanceof Array) axes.map(x => createAxis(x))

    return createAxis(axes)

}

export function createBasicSeries({data, label, color, axisUnits, yAxisIndex=0, type, valueDecimals, ...args}) {

    let highchartsData = (data instanceof Array ? data : convertObjectToSeries(data))

    let series = {
        name: label,
        data: highchartsData,
        tooltip: {
        },
        yAxis: yAxisIndex,
        ...args
    }
    _formatLabelWithUnits("tooltipLabel", axisUnits, series.tooltip)   // adds tooltip formatter to tooltip config object, mutation
    if (type) series.type = type
    if (color) series.color = color
    if (valueDecimals) series.tooltip.valueDecimals = valueDecimals
    return series
}

export function createBasicSeriesFromRawData(data, axisUnits, valueDecimals) {

    var allHighchartsSeries = []
    for (let chunk of data) {
        let highchartsSeries = createBasicSeries({data: chunk.data, label: chunk.label, axisUnits: axisUnits, valueDecimals: valueDecimals})
        allHighchartsSeries.push(highchartsSeries)
    }
    return allHighchartsSeries
}

export function createSeriesFromRawData(data, devices, tags, dimensions, assignYAxes=true) {

    var yAxes = [], series = []

    /* Iterate through each chunk of data, create a new yAxis object if necessary, and create a new Highcarts series object */
    for (let chunk of data) {

        let tagId = Number(chunk["tag"])
        let tagType = chunk["tag_type"]
        let tagTemplate = tags.find(x=>x._id===tagId)
        let engUnits = tagTemplate["eng_units"]
        let chartType = tagTemplate["default_chart_type"]
        let step = tagTemplate["default_chart_step"]// || determineDefaultChartStep(tagType)

        let seriesLabel = _createSeriesLabel(chunk, devices, dimensions, tagTemplate, tagType)

        let highchartsData = convertObjectToSeries(chunk["data"])

        let newSeries = {
            type: chartType,
            name: seriesLabel,
            data: highchartsData,
            step: step,
            tooltip: {},
        }
        _formatLabelWithUnits("tooltipLabel", engUnits, newSeries.tooltip)

        if (assignYAxes) {
            /* Create new yAxis if the one with the required engineering units does not yet exist */
            var axisIndex = Object.keys(yAxes).findIndex(el => yAxes[el].title.text===engUnits)
            if (axisIndex===-1) {
                let newAxis = createYAxes({title: engUnits, units: engUnits}, (engUnits===""))
                yAxes.push(newAxis)
                axisIndex = yAxes.length - 1
            }
            newSeries.yAxis = axisIndex
        }
        
        series.push(newSeries)
    }

    if (assignYAxes) {
        return [series, yAxes]
    }
    else {
        return series
    }

}

const determineDefaultChartStep = (dataType) => {
    // Intervals are interval ending so each point represents the time period before.
    if (dataType==="interval") {
        return "right"
    }
    // Source is point-in-time so each point represents the last value at that time.
    else if (dataType==="source") {
        return "left"
    }
    else {
        return null
    }
}

function _createSeriesLabel(data, devices, dimensions, tagTemplate, tagType) {
    let deviceId = Number(data["device"])

    let serieDimensions = data["dimensions"]
    let dimensionValueLabel = null

    if (serieDimensions) { //
        dimensionValueLabel = []
        for (let [dimensionId, dimensionValueId] of Object.entries(serieDimensions)) {
            let dimensionInfo = dimensions.find(x => x._id===Number(dimensionId))
            //let dimensionName = dimensionInfo["name"]
            let dimensionValue = dimensionInfo["values"][Number(dimensionValueId)]
            let dimensionValueName = dimensionValue["name"]
            dimensionValueLabel.push(dimensionValueName)
        }
        dimensionValueLabel = " - " + dimensionValueLabel.join(" - ")
    }

    let deviceMetadata = devices.find(x=>x._id===deviceId)

    let seriesLabel = `${deviceMetadata["id"]} - ${deviceMetadata["name"]} - ${tagTemplate["name"]}`
    if (dimensionValueLabel) seriesLabel = seriesLabel.concat(dimensionValueLabel)

    seriesLabel = seriesLabel.concat("." + tagType.toUpperCase())

    return seriesLabel
}

export var reflowHighcharts = (delay=600) => {
    // Use a timer here to ensure react has rerendered UI before reflowing charts
    setTimeout(() => {
        var charts = Highcharts.charts
        console.log(charts)
        charts.forEach(chart => {
            if (chart?.reflow) {
                chart.reflow()
            };
        })
    }, delay)
} //asd

export var reShowHighcharts = () => {
    // This should only be called after a component has rendered so no need for timeout
    //setTimeout(() => {
    var charts = Highcharts.charts
    charts.forEach(chart => {
        if (chart?.series) {
            chart.series.forEach(serie => {
                serie.show()
            })
        }
    })
    //}, 600)
}