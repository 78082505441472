import './ProjectEquipment.css'
import React from 'react'
import { ProjectInverter, equipmentStatusOptions } from "../../table_configuration/ProjectEquipment"
import { InverterSpec } from "../../table_configuration/EquipmentSpec"
import ViewPanel from '../ViewPanel'
import { generateUUID } from '../../../utils/databaseAppUtils'
import { useChangeLog, injectedChangeLogIdCol } from '../../../hooks/changeLog'
import { useDBViewFormValidation } from '../../../hooks/databaseViewFormValidation'
import { 
    ProjectEquipmentHeader, ProjectEquipmentGroupId, ProjectEquipmentSpec, ProjectEquipmentCount, ProjectEquipmentNotes, ProjectEquipmentEPCContract,
    ProjectEquipmentOwnerProvided, ProjectEquipmentStatus
} from './ProjectEquipment'
import { AddFloaterButtonWithPrompt } from '../../../components/button/FloaterButtonWithPrompt'
import { PalantirDatePicker} from '../../../components/input/SelectPicker'
import { PalantirTextField, makeTextMaskPercentage } from '../../../components/input/Text'
import { HelpInverterEquipmentITCRate } from '../../../components/help/precious/Columns'
import _ from 'lodash'
import { EPCContract } from '../../table_configuration/Contract'

const TextMaskPercentage = makeTextMaskPercentage()


export default function ProjectInverterProxy(props) {

    const inverterGroupRecords = props.data[ProjectInverter.buildId()].filter(x => x[ProjectInverter.columnSchema.plantId]===props.selectedPlantId)
    const inverterSpecRecords = props.data[InverterSpec.buildId()]
    const epcContractRecords = props.data[EPCContract.buildId()].filter(x => x[EPCContract.columnSchema.plantId]===props.selectedPlantId)

    return (
        <ProjectInverterPanel
            initialInverterGroupRecords={inverterGroupRecords}
            inverterSpecRecords={inverterSpecRecords}
            epcContractRecords={epcContractRecords}
            validateCreateInverterGroup={(record, newRecord) => true}
            {...props}
        />
    )

}

const ProjectInverterPanel = ({initialInverterGroupRecords, inverterSpecRecords, epcContractRecords, ...props}) => {

    const [
        inverterGroupRecords, prepareInverterGroupLog,
        updateInverterGroupLog, addToInverterGroupLog, deleteFromInverterGroupLog, bulkOpOnInverterGroupLog, mergeAndResetInverterGroupLog
    ] = useChangeLog(initialInverterGroupRecords, ProjectInverter)
    const [errors, addErrors, removeErrors, setErrors, verifyChangelogSubmission, resetErrors] = useDBViewFormValidation()

    const addToInverterGroupLogProxy = () => {
        let inverterGroup = ProjectInverter.buildNewRecord()
        inverterGroup[ProjectInverter.columnSchema.inverterGroupId] = generateUUID()
        inverterGroup[ProjectInverter.columnSchema.plantId] = props.selectedPlantId
        addToInverterGroupLog(inverterGroup)
    }
    const deleteFromInverterGroupLogProxy = (inverterGroup) => {
        deleteFromInverterGroupLog(inverterGroup)
    }

    const inverterGroupComponents = inverterGroupRecords.map(inverterGroup => {
        return (
            <ProjectInverterForm
                key={inverterGroup[ProjectInverter.columnSchema.inverterGroupId]}
                inverterGroup={inverterGroup}
                inverterSpecRecords={inverterSpecRecords}
                epcContractRecords={epcContractRecords}
                updateInverterGroupLog={(update) => updateInverterGroupLog(inverterGroup, update)}
                deleteFromInverterGroupLog={() => deleteFromInverterGroupLogProxy(inverterGroup)}
                errors={errors}
                removeErrors={removeErrors}
                services={props.services}
            />
        )
    })

    return (
        <ViewPanel
            services={props.services}
            title="Project Inverter Groups"
            submitUrl='/api/precious/table/project_inverter'
            verifySubmit={(payload) => {
                return verifyChangelogSubmission(
                    {
                        changeLog: payload.projectInverterChangelog,
                        checks: [{
                            checkColumn: ProjectInverter.columnSchema.inverterSpecId,
                            checkFunction: "nullCheck",
                            errMessage: "Please select an inverter specification.",
                            path: [
                                {name: ProjectInverter.columnSchema.inverterGroupId, type: "eval"},
                                {name: ProjectInverter.buildId(), type: "static"},
                                {name: injectedChangeLogIdCol, type: "eval"}
                            ]
                        }]
                    }
                )
            }}
            onSubmitSuccess={(response, requestPayload) => {
                props.handleUpdate(false)
                resetErrors()
                mergeAndResetInverterGroupLog()
            }}
            onSubmitError={null}
            buildSubmitPayload={() => {
                return {
                    projectInverterChangelog: prepareInverterGroupLog(),
                }
            }}
        >
            {inverterGroupComponents}
            <AddFloaterButtonWithPrompt
                onClick={addToInverterGroupLogProxy}
                labelContent="Add new inverter group"
                height={18}
                width={18}
            />
        </ViewPanel>
    )
}

const ProjectInverterForm = React.memo(function({inverterGroup, inverterSpecRecords, epcContractRecords, updateInverterGroupLog, deleteFromInverterGroupLog, errors, removeErrors, services}) {

    const inverterGroupId = inverterGroup[ProjectInverter.columnSchema.inverterGroupId]
    const inverterChangeLogId = inverterGroup[injectedChangeLogIdCol]

    const inverterSpecErrorPath = [inverterGroupId, ProjectInverter.buildId(), inverterChangeLogId]
    
    return (
        <div className="field-group form-instance project-equipment">
            <ProjectEquipmentHeader
                ProjectEquipmentTable={ProjectInverter}
                deleteFromEquipmentGroupLog={deleteFromInverterGroupLog}
                removeErrorsOnDelete={() => removeErrors([inverterGroupId])}
            />
            <div className="flow-horizontal body">
                <div className="flow-vertical vertical-children-spacing equipment-meta-col">
                    <ProjectEquipmentGroupId
                        ProjectEquipmentTable={ProjectInverter}
                        value={inverterGroupId}
                        style={{width: null}}
                    />
                    <ProjectEquipmentStatus
                        equipmentGroup={inverterGroup}
                        statusCol={ProjectInverter.columnSchema.status}
                        updateEquipmentGroup={updateInverterGroupLog}
                    />
                    <ProjectEquipmentEPCContract
                        equipmentContractIdCol={EPCContract.columnSchema.contractId}
                        equipmentGroupEPCContractId={inverterGroup[ProjectInverter.columnSchema.contractId]}
                        epcContracts={epcContractRecords}
                        updateEquipmentGroup={updateInverterGroupLog}
                        services={services}
                    />
                    <ProjectEquipmentOwnerProvided
                        equipmentGroup={inverterGroup}
                        ownerProvidedCol={ProjectInverter.columnSchema.isOwnerProvided}
                        updateEquipmentGroup={updateInverterGroupLog}
                    />
                    <div className="flow-horizontal" style={{justifyContent: "space-between"}}>
                        <ProjectEquipmentCount
                            ProjectEquipmentTable={ProjectInverter}
                            equipmentGroup={inverterGroup}
                            equipmentGroupCountCol={ProjectInverter.columnSchema.inverterCount}
                            updateProjectEquipmentLog={updateInverterGroupLog}
                            style={{marginRight: "10px", flexGrow: 1}}
                        />
                        <PalantirTextField
                            label="ITC Rate (%)"
                            value={inverterGroup[ProjectInverter.columnSchema.itcRate]}
                            onChange={(x) => updateInverterGroupLog({[ProjectInverter.columnSchema.itcRate]: x})}
                            InputProps={{
                                inputComponent: TextMaskPercentage
                            }}
                            HelpProps={{
                                title: "Inverter ITC Rate",
                                item: <HelpInverterEquipmentITCRate />,
                            }}
                            services={services}
                            style={{flexGrow: 1}}
                        />
                    </div>
                    <ProjectEquipmentNotes
                        value={inverterGroup[ProjectInverter.columnSchema.notes]}
                        notesColumn={ProjectInverter.columnSchema.notes}
                        updateProjectEquipment={updateInverterGroupLog}
                    />
                </div>
                <div style={{marginRight: "30px"}}>
                    <div className="header">Inverter Spec</div>
                    <ProjectEquipmentSpec
                        equipmentGroup={inverterGroup}
                        equipmentSpecRecords={inverterSpecRecords}
                        updateEquipmentGroupLog={updateInverterGroupLog}
                        EquipmentComponents={[
                            (inverterSpec) => <PalantirTextField
                                key="nominalRealPowerKw"
                                label="Nominal Real Power (kW)"
                                value={inverterSpec[InverterSpec.columnSchema.nominalRealPowerKw]}
                                disabled
                                style={{minWidth: "160px"}}
                            />,
                            (inverterSpec) => <PalantirTextField
                                key="nominalAppPowerKva"
                                label="Nominal App Power (kVA)"
                                value={inverterSpec[InverterSpec.columnSchema.nominalAppPowerKva]}
                                disabled
                                style={{minWidth: "160px"}}
                            />
                        ]}
                        equipmentType="Inverter"
                        projectEquipmentSpecIdCol={ProjectInverter.columnSchema.inverterSpecId}
                        equipmentSpecIdCol={InverterSpec.columnSchema.inverterSpecId}
                        manufacturerCol={InverterSpec.columnSchema.manufacturer}
                        modelCol={InverterSpec.columnSchema.model}
                        errors={errors}
                        equipmentSpecErrorPath={inverterSpecErrorPath}
                        removeEquipmentSpecError={() => {
                            removeErrors(inverterSpecErrorPath)
                        }}
                    />
                    
                </div>
                <div className="flow-vertical">
                    <div className="header">Dates</div>
                    <div className="flow-horizontal">
                        <div className="flow-vertical project-equipment-dates">
                            <PalantirDatePicker
                                label="Warranty Start"
                                value={inverterGroup[ProjectInverter.columnSchema.warrantyStartDate]}
                                onChange={(date) => updateInverterGroupLog({[ProjectInverter.columnSchema.warrantyStartDate]: date})}
                                style={{marginBottom: "30px"}}
                            />
                            <div className="header">Current Dates</div>
                            <PalantirDatePicker
                                label="Delivery Start"
                                value={inverterGroup[ProjectInverter.columnSchema.deliveryStartDateCurrent]}
                                onChange={(date) => updateInverterGroupLog({[ProjectInverter.columnSchema.deliveryStartDateCurrent]: date})}
                            />
                            <PalantirDatePicker
                                label="Delivery End"
                                value={inverterGroup[ProjectInverter.columnSchema.deliveryEndDateCurrent]}
                                onChange={(date) => updateInverterGroupLog({[ProjectInverter.columnSchema.deliveryEndDateCurrent]: date})}
                            />
                            <PalantirDatePicker
                                label="Purchase Order"
                                value={inverterGroup[ProjectInverter.columnSchema.purchaseOrderDateCurrent]}
                                onChange={(date) => updateInverterGroupLog({[ProjectInverter.columnSchema.purchaseOrderDateCurrent]: date})}
                            />
                        </div>
                        <div className="flow-vertical project-equipment-dates">
                            <PalantirDatePicker
                                label="Warranty End"
                                value={inverterGroup[ProjectInverter.columnSchema.warrantyEndDate]}
                                onChange={(date) => updateInverterGroupLog({[ProjectInverter.columnSchema.warrantyEndDate]: date})}
                                style={{marginBottom: "30px"}}
                            />
                            <div className="header">Budgeted Dates</div>
                            <PalantirDatePicker
                                label="Delivery Start"
                                value={inverterGroup[ProjectInverter.columnSchema.deliveryStartDateBudgeted]}
                                onChange={(date) => updateInverterGroupLog({[ProjectInverter.columnSchema.deliveryStartDateBudgeted]: date})}
                            />
                            <PalantirDatePicker
                                label="Delivery End"
                                value={inverterGroup[ProjectInverter.columnSchema.deliveryEndDateBudgeted]}
                                onChange={(date) => updateInverterGroupLog({[ProjectInverter.columnSchema.deliveryEndDateBudgeted]: date})}
                            />
                            <PalantirDatePicker
                                label="Purchase Order"
                                value={inverterGroup[ProjectInverter.columnSchema.purchaseOrderDateBudgeted]}
                                onChange={(date) => updateInverterGroupLog({[ProjectInverter.columnSchema.purchaseOrderDateBudgeted]: date})}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}, (prevProps, nextProps) => {
    return (
        _.isEqual(prevProps.inverterGroup, nextProps.inverterGroup) &&
        _.isEqual(prevProps.errors, nextProps.errors)
    )
})