import { Text, Alert } from '@mantine/core'
import { useState } from 'react'


export const HomePageAlert = () => {

    const [hasClosedHomePageAlert, setHasClosedHomePageAlert] = useState(localStorage.getItem("hasClosedHomePageAlert")==="true")

    if (!hasClosedHomePageAlert) {
        return (
            <Alert
                className="flowable"
                title="Yay!"
                color="teal"
                style={{flexShrink: 0}}
                withCloseButton
                onClose={() => {
                    setHasClosedHomePageAlert(true)
                    localStorage.setItem("hasClosedHomePageAlert", "true")
                }}
            >
                <Text size="sm">Something amazing happened! Palantir has received a new look!</Text>
                <Text size="sm"><Text weight={500} size="sm" style={{display: "inline"}}>Important</Text>: Tables and Views selection has moved to the collapsable sidebar.</Text>
            </Alert>
        )
    } else return null
}