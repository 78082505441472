import './ProjectEquipment.css'
import React from 'react'
import { ProjectBreaker } from "../../table_configuration/ProjectEquipment"
import { TransformerSpec } from "../../table_configuration/EquipmentSpec"
import ViewPanel from '../ViewPanel'
import { generateUUID } from '../../../utils/databaseAppUtils'
import { useChangeLog, injectedChangeLogIdCol } from '../../../hooks/changeLog'
import { useDBViewFormValidation } from '../../../hooks/databaseViewFormValidation'
import {
    ProjectEquipmentHeader, ProjectEquipmentGroupId, ProjectEquipmentSpec,
    ProjectEquipmentCount, ProjectEquipmentNotes, ProjectEquipmentStatus, ProjectEquipmentOwnerProvided, ProjectEquipmentEPCContract
} from './ProjectEquipment'
import { AddFloaterButtonWithPrompt, DeleteFloaterButtonWithPrompt } from '../../../components/button/FloaterButtonWithPrompt'
import { PalantirDatePicker, PalantirSelector } from '../../../components/input/SelectPicker'
import { makeTextMaskNumber, makeTextMaskPercentage, PalantirTextField } from '../../../components/input/Text'
import { EPCContract } from '../../table_configuration/Contract'
import _ from 'lodash'
import { Text, Alert } from '@mantine/core'


const TextMaskNumber = makeTextMaskNumber({})
const TextMaskPercent = makeTextMaskPercentage()

export default function ProjectBreakerProxy(props) {

    const breakerGroupRecords = props.data[ProjectBreaker.buildId()].filter(x => x[ProjectBreaker.columnSchema.projectId]===props.selectedPlantId)

    return (
        <ProjectBreakerPanel
            initialBreakerGroupRecords={breakerGroupRecords}
            {...props}
        />
    )

}

const ProjectBreakerPanel = ({initialBreakerGroupRecords, ...props}) => {

    const [
        breakerGroupRecords, prepareBreakerGroupLog,
        updateBreakerGroupLog, addToBreakerGroupLog, deleteFromBreakerGroupLog, bulkOpOnBreakerGroupLog, mergeAndResetBreakerGroupLog
    ] = useChangeLog(initialBreakerGroupRecords, ProjectBreaker)
    const [errors, addErrors, removeErrors, setErrors, verifyChangelogSubmission, resetErrors] = useDBViewFormValidation()

    const addToBreakerGroupLogProxy = () => {
        let breakerGroup = ProjectBreaker.buildNewRecord()
        breakerGroup[ProjectBreaker.columnSchema.oid] = generateUUID()
        breakerGroup[ProjectBreaker.columnSchema.projectId] = props.selectedPlantId
        addToBreakerGroupLog(breakerGroup)
    }
    
    const breakerGroupComponents = breakerGroupRecords.map(breakerGroup => {
        return (
            <ProjectBreakerForm
                key={breakerGroup[ProjectBreaker.columnSchema.oid]}
                breakerGroup={breakerGroup}
                updateBreakerGroupLog={(update) => updateBreakerGroupLog(breakerGroup, update)}
                deleteFromBreakerGroupLog={() => deleteFromBreakerGroupLog(breakerGroup)}
                errors={errors}
                removeErrors={removeErrors}
                services={props.services}
            />
        )
    })

    return (
        <ViewPanel
            services={props.services}
            title="Project Breaker Groups"
            submitUrl='/api/precious/table/project_breaker'
            verifySubmit={(payload) => {
                return verifyChangelogSubmission(
                    {
                        changeLog: payload.projectBreakerChangelog,
                        checks: []
                    }
                )
            }}
            onSubmitSuccess={(response, requestPayload) => {
                props.handleUpdate(false)
                resetErrors()
                mergeAndResetBreakerGroupLog()
            }}
            onSubmitError={null}
            buildSubmitPayload={() => {
                return {
                    projectBreakerChangelog: prepareBreakerGroupLog(),
                }
            }}
        >
            {breakerGroupComponents}
            <AddFloaterButtonWithPrompt
                onClick={addToBreakerGroupLogProxy}
                labelContent="Add new breaker group"
                height={18}
                width={18}
            />
        </ViewPanel>
    )
}

const ProjectBreakerForm = React.memo(function({
    breakerGroup, updateBreakerGroupLog, deleteFromBreakerGroupLog, errors, removeErrors, services
}) {

    const breakerGroupId = breakerGroup[ProjectBreaker.columnSchema.oid]
    
    return (
        <div className="field-group form-instance project-equipment">
            <ProjectEquipmentHeader
                ProjectEquipmentTable={ProjectBreaker}
                deleteFromEquipmentGroupLog={deleteFromBreakerGroupLog}
            />
            <div className="flow-vertical body">
                <div className="flow-vertical vertical-children-spacing" style={{maxWidth: "400px"}}>
                    <ProjectEquipmentGroupId
                        ProjectEquipmentTable={ProjectBreaker}
                        value={breakerGroupId}    
                    />
                    <ProjectEquipmentStatus
                        equipmentGroup={breakerGroup}
                        statusCol={ProjectBreaker.columnSchema.status}
                        updateEquipmentGroup={updateBreakerGroupLog}
                    />
                    <ProjectEquipmentOwnerProvided
                        equipmentGroup={breakerGroup}
                        ownerProvidedCol={ProjectBreaker.columnSchema.isOwnerProvided}
                        updateEquipmentGroup={updateBreakerGroupLog}
                    />
                    <ProjectEquipmentCount
                        ProjectEquipmentTable={ProjectBreaker}
                        equipmentGroup={breakerGroup}
                        equipmentGroupCountCol={ProjectBreaker.columnSchema.breakerCount}
                        updateProjectEquipmentLog={updateBreakerGroupLog}
                    />
                    <ProjectEquipmentNotes
                        value={breakerGroup[ProjectBreaker.columnSchema.notes]}
                        notesColumn={ProjectBreaker.columnSchema.notes}
                        updateProjectEquipment={updateBreakerGroupLog}
                    />
                </div>
                <div className="flow-vertical">
                    <div className="header">Dates</div>
                    <div className="flow-horizontal">
                        <div className="flow-vertical project-equipment-dates">
                            <PalantirDatePicker
                                label="Warranty Start"
                                value={breakerGroup[ProjectBreaker.columnSchema.warrantyStartDate]}
                                onChange={(date) => updateBreakerGroupLog({[ProjectBreaker.columnSchema.warrantyStartDate]: date})}
                                style={{marginBottom: "30px"}}
                            />
                            <div className="header">Current Dates</div>
                            <PalantirDatePicker
                                label="Delivery End"
                                value={breakerGroup[ProjectBreaker.columnSchema.deliveryEndDateCurrent]}
                                onChange={(date) => updateBreakerGroupLog({[ProjectBreaker.columnSchema.deliveryEndDateCurrent]: date})}
                            />
                            <PalantirDatePicker
                                label="Purchase Order"
                                value={breakerGroup[ProjectBreaker.columnSchema.purchaseOrderDateCurrent]}
                                onChange={(date) => updateBreakerGroupLog({[ProjectBreaker.columnSchema.purchaseOrderDateCurrent]: date})}
                            />
                        </div>
                        <div className="flow-vertical project-equipment-dates">
                            <PalantirDatePicker
                                label="Warranty End"
                                value={breakerGroup[ProjectBreaker.columnSchema.warrantyEndDate]}
                                onChange={(date) => updateBreakerGroupLog({[ProjectBreaker.columnSchema.warrantyEndDate]: date})}
                                style={{marginBottom: "30px"}}
                            />
                            <div className="header">Budgeted Dates</div>
                            <PalantirDatePicker
                                label="Delivery End"
                                value={breakerGroup[ProjectBreaker.columnSchema.deliveryEndDateBudgeted]}
                                onChange={(date) => updateBreakerGroupLog({[ProjectBreaker.columnSchema.deliveryEndDateBudgeted]: date})}
                            />
                            <PalantirDatePicker
                                label="Purchase Order"
                                value={breakerGroup[ProjectBreaker.columnSchema.purchaseOrderDateBudgeted]}
                                onChange={(date) => updateBreakerGroupLog({[ProjectBreaker.columnSchema.purchaseOrderDateBudgeted]: date})}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}, (prevProps, nextProps) => {
    return (
        _.isEqual(prevProps.breakerGroup, nextProps.breakerGroup) &&
        _.isEqual(prevProps.errors, nextProps.errors)
    )
})