import { useOutletContext, useParams } from "react-router-dom"
import ViewContainer from "./ViewContainer"
import HelpPanel from "../components/help/HelpPanel"
import { views } from "./views/ViewSchema"
import Panel from '../components/panel/Panel'


export const View = () => {

    const { services, selectedPlant, selectedPortfolio } = useOutletContext()
    console.log(services)
    let { viewId } = useParams();

    let selectedView
    if (viewId.startsWith("view_")) {
        selectedView = views.find(x => x.key===viewId)
    }
    else {
        selectedView = views.reduce((acc, cur) => cur.components.concat(acc), []).find(x => x.key===viewId)
    }

    if (!selectedView) return (
        <Panel className=" section-panel fill-parent">This view does not exist: {viewId}.</Panel>
    )

    return (
        <div className="flow-vertical fill-parent">
            <HelpPanel
                services={services}
                render={(newServices) =>
                    <ViewContainer
                        services={newServices}
                        selectedPlantId={selectedPlant}
                        selectedPortfolioId={selectedPortfolio}
                        selectedView={selectedView}
                    />
                }
            />
        </div>
    )
}