import { useState, useEffect } from 'react'
import axios from 'axios'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { reflowHighcharts } from '../../utils/Highcharts'
import { Panel, PanelContentCentered, PanelHeader } from './Shared'
import { Loader, Text, Tooltip } from '@mantine/core'
import _ from 'lodash'
import { useOutletContext } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons'


const colorLookup = {
    "Solar": "#e9d700",
    "Wind": "#8DC63F",
    "BESS": "#74D3DE",
    "Solar + BESS": "#05425B",
    "CCGT": "#E0D227"
}


export const ProjectCapacityOverviewPanel = () => {
    return (
        <Panel>
            <PanelHeader title="Project Capacity">
                <Tooltip label="Projects filtered to Fund V assets in Greenfield, Development, Construction, or Operational stages." position="right-start">
                    <FontAwesomeIcon icon={faQuestionCircle} />
                </Tooltip>
            </PanelHeader>
            <PanelContentCentered>
                <ProjectCapacityOverviewDataProvider />
            </PanelContentCentered>
        </Panel>
    )
}

const ProjectCapacityOverviewDataProvider = () => {

    const { authServices } = useOutletContext()

    const [data, setData] = useState()
    const [status, setStatus] = useState("idle")
    const [error, setError] = useState(null)

    useEffect(() => {
        getData()
    }, [])

    const getData = () => {
        axios.get("/api/precious/home/get_project_capacity_overview")
        .then((data) => {
            setData(data.data)
            setStatus("success")
        })
        .catch((error) => {
            console.error(error)
            if (error.response?.status === 401) {
                authServices.logout()
                return
            }
            setError(error.toJSON())
            setStatus("failed")
        })
        setStatus("loading")
    }

    if (status==="loading") {
        return (
        <PanelContentCentered>
            <Loader variant="bars" color="pink" size="sm" />
        </PanelContentCentered>
        )
    }
    else if (status==="failed") {
        return (
            <PanelContentCentered>
                <Text size="sm">{error?.message}</Text>
            </PanelContentCentered>
        )
    }
    else if (status==="success") {
        return (
            <ProjectCapacityOverviewChart data={data} />
        )
    }
    return null
}

export const ProjectCapacityOverviewChart = ({data}) => {

    useEffect(() => {
        reflowHighcharts(1)
    }, [])

    const chartData = data.map(x => {return {name: x.technology, y: x.pct, color: colorLookup[x.technology], mw: _.round(x.abs/1000, 2)}})

    return (
        <HighchartsReact
            highcharts={Highcharts}
            options={{
                chart: {
                    type: 'pie',
                    height: 250,
                    width: 300,
                    spacing: [0,0,0,0]
                },
                credits: {
                    enabled: false
                },
                title: {
                    text: null
                },
                tooltip: {
                    valueSuffix: "% ({point.mw} GW)",
                },
                plotOptions: {
                    series: {
                        dataLabels: {
                            enabled: true,
                            distance: 10
                        }
                    }
                },
                series: [
                    {
                        name: 'Percentage',
                        colorByPoint: true,
                        data: chartData
                    }
                ]
            }}
        />
    )
}