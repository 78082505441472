import './Shared.css'
import { Group, Stack, Text, Center } from "@mantine/core"


export const Panel = ({title, children, style={}, ...props}) => {
    let _style = {height: "100%", overflow: "auto"}
    if (style) Object.assign(_style, style)

    return (
        <Stack className="pltr-panel" justify="flex-start" spacing={0} style={_style} {...props} >
            {children}
        </Stack>
    )
}

export const PanelHeader = ({title, children}) => {
    return (
        <Group className="pltr-header">
            <Text size="md">{title}</Text>
            {children}
        </Group>
    )
}

export const PanelContentCentered = ({children}) => {
    return (
        <Center style={{height: "inherit"}}>
            {children}
        </Center>
    )
}