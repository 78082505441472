import './TableSelector.css'
import {Component} from 'react'

import { schemas } from './Constants'
import { Plant } from './table_configuration/Project'
import { Portfolio } from './table_configuration/Entity'
import CRUDTable from '../components/table/CRUDTable'
import HelpPanel from '../components/help/HelpPanel'
import Panel from '../components/panel/Panel'

import { Loader, Whisper, Tooltip } from 'rsuite'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faList, faSolarPanel, faDatabase, faRefresh, faQuestion } from '@fortawesome/free-solid-svg-icons'


class TableSelector extends Component {

    constructor(props) {
        super(props)
        this.state = {
            
        }
    }

    componentDidMount() {
        console.log("mounting table selector")
    }

    shouldComponentUpdate(prevProps, prevState) {
        if (this.props.selectedTable!==prevProps.selectedTable) {
            return false
        }
        return true
    }

    componentDidUpdate(prevProps, prevState) {
        // refresh data when update flag is set
        // this indicates data has been updated by user so need to refetch from source
        if (this.updateFlag) {
            // only need to refetch if plant/portfolio/display mode was changed, else table was changed which means data will be refreshed
            // could also check if table was changed
            if (this.props.selectedPlantId!==prevProps.selectedPlantId || this.props.selectedPortfolioId!==prevProps.selectedPortfolioId) {
                this.props.services.data.refresh()
            }
            this.updateFlag = false
        }
    }

    render() {

        var records = this.props.data.find(x => x.schema===this.props.selectedTable.schemaName && x.table===this.props.selectedTable.tableName)?.records || []

        const tableProps = this.props.selectedTable.buildTableProps(this.props.data)

        var title = this.buildTitle()
        // table should reset state whenever any of the below values change

        return (
            <HelpPanel 
                services={this.props.services}
                render={(newServices) => {
                    return (
                        <div className="section-main flow-vertical fill-parent">
                            <div className="table-display-header flow-horizontal">
                                {title}
                                <FontAwesomeIcon icon={faQuestion} onClick={() => newServices.help.setHelpConfig(this.props.selectedTable.displayNameSingular, <this.props.selectedTable.buildHelpPanel />)} className="fa-icon help-icon" size="1x" style={{marginLeft: "20px"}} />
                                <div className="fill-parent" ></div>
                                <Whisper
                                    trigger="hover"
                                    placement="left"
                                    speaker={<Tooltip>Refresh data.</Tooltip>}
                                >
                                    <FontAwesomeIcon icon={faRefresh} onClick={() => newServices.data.refresh()} className="fa-icon" size="lg" style={{marginRight: "10px"}} />
                                </Whisper>
                            </div>
                            {
                                this.props.loading ? 
                                <Panel><Loader size="sm" speed="fast" vertical content="loading.." /></Panel> : 
                                <CRUDTable
                                    services={newServices}
                                    initialItems={records}
                                    partitionKey={this.buildTablePartitionKey()}
                                    
                                    virtualized={true}
                                    itemType={this.props.selectedTable.displayNameSingular}
                                    forceLoading={this.props.loading}
                                    heightOffset={260}
                                    endpoints={{
                                        create: "/api/precious/table/insert",
                                        update: "/api/precious/table/update",
                                        delete: "/api/precious/table/delete"
                                    }}
                                    onUpdateSuccess={this.initiateRefreshes}
                                    onDeleteSuccess={this.initiateRefreshes}
                                    onCreateSuccess={this.initiateRefreshes}
                                    {...tableProps}
                                />
                            }
                        </div>
                    )
                }}
            />
        )
    }

    buildTablePartitionKey = () => {
        var keyFields = [this.props.selectedTable.schemaName, this.props.selectedTable.tableName]
        return keyFields.join(" - ")
    }

    buildTitle = () => {
        var tableTitle = this.buildTableTitle()

        return (
            <div className="title" style={{marginLeft: "10px", flexShrink: 0}}>
                {tableTitle}
            </div>
        )
    }

    buildTableTitle = () => {
        return <span className="item">
            <FontAwesomeIcon icon={faDatabase} className="fa-icon" size="sm" />
            <span>{this.props.selectedTableGroup.displayName} {">"} {this.props.selectedTable.displayNameSingular}</span>
        </span>
    }

    buildPlantTitle = () => {

        let plantIdCol = Plant.columnSchema.plantId
        let plantNameCol = Plant.columnSchema.plantName
        let portfolioIdCol = Portfolio.columnSchema.portfolioId
        let portfolioNameCol = Portfolio.columnSchema.portfolioName

        if (this.props.selectedPlantId) {
            let allPlants = this.props.services.assets.getPlants()
            let plant = allPlants.find(x => x[plantIdCol] === this.props.selectedPlantId) || {}
            let allPortfolios = this.props.services.assets.getPortfolios()
            let portfolio = allPortfolios.find(x => x[portfolioIdCol] === plant[portfolioIdCol]) || {}
            return (
                <span className="item">
                    <FontAwesomeIcon icon={faSolarPanel} className="fa-icon" size="sm" />
                    <span>{portfolio[portfolioNameCol]} {" > "} {plant[plantNameCol]}</span>
                </span>
            )
        }
        else if (this.props.selectedPortfolioId) {
            let allPortfolios = this.props.services.assets.getPortfolios()
            let portfolio = allPortfolios.find(x => x[portfolioIdCol] === this.props.selectedPortfolioId) || {}
            return (
                <span className="item">
                    <FontAwesomeIcon icon={faList} className="fa-icon" size="sm" />
                    <span>{portfolio[portfolioNameCol]}</span>
                </span>
            )
        }
        else {
            return ""
        }
    }

    initiateRefreshes = () => {
        this.updateFlag = true
        this.refreshPlants()
    }

    /**
     * If the portfolio/plant tables are selected, refresh assets on any updates
     */
    refreshPlants = () => {
        var plantSelected = this.props.selectedTable.schemaName===schemas.project && this.props.selectedTable.tableName===Plant.tableName
        var portfolioSelected = this.props.selectedTable.schemaName===schemas.entity && this.props.selectedTable.tableName===Portfolio.tableName

        if (plantSelected || portfolioSelected) {
            this.props.services.assets.refresh()
        }
    }

}

export default TableSelector