import { Plant, TechnicalDueDiligence } from "../../table_configuration/Project"
import { PalantirTextField, PalantirDispatchedTextField, makeTextMaskNumber, makeTextMaskPercentage } from "../../../components/input/Text"
import { PalantirAutocomplete, PalantirBooleanSelector, PalantirDatePicker, PalantirSelector, } from "../../../components/input/SelectPicker"
import { useChangeLog } from "../../../hooks/changeLog"
import { useDBViewFormValidation } from "../../../hooks/databaseViewFormValidation"
import ViewPanel from "../ViewPanel"
import { AddFloaterButtonWithPrompt, DeleteFloaterButtonWithPrompt } from "../../../components/button/FloaterButtonWithPrompt"
import { Text, ActionIcon } from "@mantine/core"
import { OfftakeContractOffer } from "../../table_configuration/ContractOffer"
import { HelpPrompt } from "../../../components/help/HelpPrompt"
import { HelpDryBulbTemp, HelpRainfall, HelpSnowLoad, HelpWindLoad, HelpGlare, HelpSeismic, HelpFlood, HelpWetlands, HelpBiological } from "../../../components/help/precious/Columns"
import { generateUUID } from "../../../utils/databaseAppUtils"
import { PalantirBasicCRUDMenu } from "../../../components/menuv2/Menu"
import { useState } from "react"
import { DialogProvider, useDialog } from "../../../components/dialog/DialogProvider"
import { DialogContentText } from "@mui/material"
import { injectCounterpartyName, Offtake } from "../../table_configuration/Counterparty"
import { Counterparty } from "../../table_configuration/Entity"
import { booleanOptions } from "../../table_configuration/CommonColumns"
import _ from "lodash"
import { FormPanelHeader, CardHeaderWithMenu } from "../../../components/panelv2/Panel2"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCopy, faTrashCan } from "@fortawesome/free-solid-svg-icons"
import { cleanTrailingZeroInNumericSectionValue } from "@mui/x-date-pickers/internals/hooks/useField/useField.utils"
import { MultiSelect, SegmentedControl } from "@mantine/core"
import { format as formatDate } from 'date-fns'




const TextMaskNumber = makeTextMaskNumber()
const TextMaskSize = makeTextMaskNumber({
    min: 0
})
const TextMaskMargin = makeTextMaskNumber()
const TextMaskTenor = makeTextMaskNumber({
    min: 10, max: 99
})
const TextMaskPercentage = makeTextMaskPercentage()
const CustomHR = () => {
    return <div style={{borderBottom: "solid #e5e5ea 1px", margin: "0 0 30px 0", width: "80%", }}></div>
}


export default function OfftakeOfferPanelProxy(props) {

    var projectOfftakeOffers = props.data[OfftakeContractOffer.buildId()].filter(tdd => tdd[OfftakeContractOffer.columnSchema.projectId]===props.selectedPlantId)
    projectOfftakeOffers = _.sortBy(projectOfftakeOffers, [OfftakeContractOffer.columnSchema.sentDate])
    const offtakers = props.data[Offtake.buildId()]

    return (
        <DialogProvider>
        <OfftakOfferPanel
            initialProjectOfftakeOffers={projectOfftakeOffers}
            offtakers={offtakers}
            {...props}
        />
        </DialogProvider>
    )
}

function OfftakOfferPanel({initialProjectOfftakeOffers, offtakers, ...props}) {

    const [
        offtakeOfferRecords, prepareLog, updateLog, addToLog,
        deleteFromLog, bulkOpOnLog, mergeAndResetLog, resetLog, popLog
    ] = useChangeLog(initialProjectOfftakeOffers, OfftakeContractOffer)
    const [selectedOfferId, setSelectedOfferId] = useState(null)
    const [isCreating, setIsCreating] = useState(false)
    const selectedOffer = offtakeOfferRecords.find(x => x[OfftakeContractOffer.columnSchema.oid]===selectedOfferId)

    const addToOfferLog = (copySelected) => {
        const offerID = generateUUID()
        var offer
        if (copySelected && selectedOfferId) {
            offer = Object.assign({}, selectedOffer)

            const sameNameOffers = offtakeOfferRecords.filter(x => {
                return (
                    x[OfftakeContractOffer.columnSchema.projectId]===offer[OfftakeContractOffer.columnSchema.projectId] &&
                    x[OfftakeContractOffer.columnSchema.offtakerId]===offer[OfftakeContractOffer.columnSchema.offtakerId] &&
                    x[OfftakeContractOffer.columnSchema.sentDate]===offer[OfftakeContractOffer.columnSchema.sentDate]
                )
            })
            const dupeOfferNums = sameNameOffers.map(x => (x[OfftakeContractOffer.columnSchema.offerName] || "").slice(-2, -1))
            const maxNum = Number(_.max(dupeOfferNums))
            if (maxNum) {
                const suffix = ` (${maxNum+1})`
                const newOfferName = offer[OfftakeContractOffer.columnSchema.offerName].split(" ").slice(0, -1).join(" ") + suffix
                offer[OfftakeContractOffer.columnSchema.offerName] = newOfferName
            }
            // existing offer must not yet be enumerated so we can safely start with 1
            else {
                offer[OfftakeContractOffer.columnSchema.offerName] = offer[OfftakeContractOffer.columnSchema.offerName] + " (1)"
            }
        }
        else {
            offer = OfftakeContractOffer.buildNewRecord()
            offer[OfftakeContractOffer.columnSchema.projectId] = props.selectedPlantId
        }
        offer[OfftakeContractOffer.columnSchema.oid] = offerID
        setSelectedOfferId(offerID)

        bulkOpOnLog([{
            method: "clear"
        }, {
            method: "insert", changes: [offer]
        }])
    }
    const createOffer = (copySelected) => {
        const create = () => {
            addToOfferLog(copySelected)
            
            resetErrors()
            setIsCreating(true)
        }
        if (isCreating) {
            window.alert("You may only add one at a time.")
            return
        }
        if (prepareLog().length > 0) {
            openDialog({
                title: "You have unsaved edits.",
                body: <DialogContentText>Are you sure you want to discard your changes?</DialogContentText>,
                onAccept: () => {
                    closeDialog()
                    create()
                }
            });
        }
        else create()
    }
    const selectOffer = (record) => {
        // Don't do anything if selecting already selected offer
        if (record[OfftakeContractOffer.columnSchema.oid]===selectedOfferId) return
        const switchSelection = () => {
            setSelectedOfferId(record[OfftakeContractOffer.columnSchema.oid])
            resetLog()
            resetErrors()
            setIsCreating(false)
        }
        if (prepareLog().length > 0) {
            openDialog({
                title: "You have unsaved edits.",
                body: <DialogContentText>Are you sure you want to discard your changes?</DialogContentText>,
                onAccept: () => {
                    closeDialog()
                    switchSelection()
                }
            });
        }
        else {
            switchSelection()
        }
    }
    const deleteOffer = (callback) => {
        openDialog({
            title: "Watch out!",
            body: <DialogContentText>Are you sure you want to delete the selected Offer?</DialogContentText>,
            onAccept: () => {
                closeDialog()
                setSelectedOfferId(null)
                deleteFromLog(selectedOffer)
                setTimeout(() => {
                    callback({
                        onFail: () => {
                            resetErrors()
                            setSelectedOfferId(selectedOfferId)
                            popLog()
                        }
                    })
                }, 100)
            }
        });
    }
    const [errors, addErrors, removeErrors, setErrors, verifyChangelogSubmission, resetErrors] = useDBViewFormValidation()

    const [openDialog, closeDialog] = useDialog();

    const updateOffer = (update) => updateLog(selectedOffer, update)


    return (
        <div className="flow-horizontal fill-parent" style={{overflow: "auto"}}>
            <PalantirBasicCRUDMenu
                title="Offtake Offers"
                selectedId={selectedOfferId}
                records={offtakeOfferRecords}
                recordIdCol={OfftakeContractOffer.columnSchema.oid}
                recordNameCol={OfftakeContractOffer.columnSchema.offerName}
                onSelect={(record) => {
                    selectOffer(record)
                }}
                //isCreateActive={isCreating}
                onAdd={() => {
                    createOffer()
                }}
                style={{marginRight: "20px", marginTop: "25px", width: "225px", flexShrink: 0}}
            />
            <ViewPanel
                services={props.services}
                submitUrl='/api/precious/table/offtake_contract_offer'
                verifySubmit={(payload) => {
                    return verifyChangelogSubmission(
                        {
                            changeLog: payload.offtakeOfferChangeLog,
                            checks: [{
                                checkColumn: OfftakeContractOffer.columnSchema.technology,
                                checkFunction: "nullCheck",
                                errMessage: "Please enter a value.",
                                path: [{name: OfftakeContractOffer.columnSchema.technology, type: "static"}]
                            }, {
                                checkColumn: OfftakeContractOffer.columnSchema.offtakerId,
                                checkFunction: "nullCheck",
                                errMessage: "Please select a value.",
                                path: [{name: OfftakeContractOffer.columnSchema.offtakerId, type: "static"}]
                            }, {
                                checkColumn: OfftakeContractOffer.columnSchema.contractType,
                                checkFunction: "nullCheck",
                                errMessage: "Please select a value.",
                                path: [{name: OfftakeContractOffer.columnSchema.contractType, type: "static"}]
                            }]
                        }
                    )
                }}
                onSubmitSuccess={(response, requestPayload) => {
                    props.handleUpdate(false)
                    resetErrors()
                    mergeAndResetLog()
                    setIsCreating(false)
                }}
                showDelete={!isCreating && selectedOfferId}
                onDelete={deleteOffer}
                onSubmitError={null}
                buildSubmitPayload={() => {
                    return {
                        offtakeOfferChangeLog: prepareLog()
                    }
                }}
            >
                {selectedOffer ?
                    <OfferForm
                        key={selectedOfferId}
                        offer={selectedOffer}
                        offtakeOfferRecords={offtakeOfferRecords}
                        updateOffer={updateOffer}
                        offtakers={offtakers}
                        createOffer={createOffer}
                        deleteOffer={deleteOffer}
                        errors={errors}
                        removeErrors={removeErrors}
                        selectedProjectId={props.selectedPlantId}
                        selectedProjectName={props.selectedPlant[Plant.columnSchema.plantName]}
                    />
                    :
                    <div className="flow-vertical" style={{textAlign: "center"}}>Please select an Offer.</div>}
            </ViewPanel>
        </div>
    )
}

const OfferForm = ({offer, offtakeOfferRecords, updateOffer, offtakers, createOffer, errors, removeErrors, selectedProjectId, selectedProjectName}) => {

    const offtakeId = offer[OfftakeContractOffer.columnSchema.offtakerId]
    const offtakeRecord = offtakers.find(x => x[Counterparty.columnSchema.counterpartyId]===offtakeId)
    const offtakeName = offtakeRecord ? offtakeRecord[Counterparty.columnSchema.counterpartyName] : null

    const previousOfferId = offer[OfftakeContractOffer.columnSchema.previousOfferId]
    const previousOfferRecord = offtakeOfferRecords.find(x => x[OfftakeContractOffer.columnSchema.oid]===previousOfferId)
    const previousOfferName = previousOfferRecord ? previousOfferRecord[OfftakeContractOffer.columnSchema.offerName] : null

    const contractType = offer[OfftakeContractOffer.columnSchema.contractType]

    const offerTechnologyError = errors.get(OfftakeContractOffer.columnSchema.technology)
    const offerTechnologyErrorProps = offerTechnologyError ? {error: true, helperText: offerTechnologyError.getMessage()} : {}
    const offerOfftakerError = errors.get(OfftakeContractOffer.columnSchema.offtakerId)
    const offerOfftakerErrorProps = offerOfftakerError ? {error: true, helperText: offerOfftakerError.getMessage()} : {}
    const offerTypeError = errors.get(OfftakeContractOffer.columnSchema.contractType)
    const offerTypeErrorProps = offerTypeError ? {error: true, helperText: offerTypeError.getMessage()} : {}

    const buildOfferName = ({projectId, projectName, offtakerId, offtakerName, sentDate}) => {
        if (projectId===undefined) projectId = selectedProjectId
        if (projectName===undefined) projectName = selectedProjectName
        if (sentDate===undefined) sentDate = offer[OfftakeContractOffer.columnSchema.sentDate]
        if (offtakerId===undefined) offtakerId = offtakeId
        if (offtakerName===undefined) offtakerName = offtakeName

        if (sentDate instanceof Date) sentDate = formatDate(sentDate, "yyyy-MM-dd")

        const offerNameParts = []
        if (projectName) offerNameParts.push(projectName)
        if (sentDate) offerNameParts.push(sentDate)
        if (offtakerName) {
            if (offtakerName.length > 11) offerNameParts.push(offtakerName.slice(0, 8).concat("..."))
            else offerNameParts.push(offtakerName)
        }
        const offerNameInitial = offerNameParts.join(".")

        const sameNameOffers = offtakeOfferRecords.filter(x => {
            return (
                x[OfftakeContractOffer.columnSchema.projectId]===projectId &&
                x[OfftakeContractOffer.columnSchema.offtakerId]===offtakerId &&
                x[OfftakeContractOffer.columnSchema.sentDate]===sentDate &&
                x[OfftakeContractOffer.columnSchema.oid]!==offer[OfftakeContractOffer.columnSchema.oid]
            )
        })
        if (sameNameOffers.length > 0) {
            const dupeOfferNums = sameNameOffers.map(x => x[OfftakeContractOffer.columnSchema.offerName].slice(-2, -1))
            const maxNum = Number(_.max(dupeOfferNums)) || 0
            return offerNameInitial + ` (${maxNum+1})`
        }
        else return offerNameInitial
    }

    return (
            <div className="form-instance2">
                <CardHeaderWithMenu
                    label="Offtake Offer"
                    inlineComponents={[
                        <ActionIcon onClick={() => createOffer(true)}><FontAwesomeIcon icon={faCopy} size="lg" /></ActionIcon>
                    ]}
                />
                <div className="body">
                    <div className="flow-horizontal" style={{flexWrap: "wrap", marginBottom: "20px"}}>
                        <PalantirTextField
                            label="Offer Name"
                            value={offer[OfftakeContractOffer.columnSchema.offerName]}
                            onChange={(x) => {
                                updateOffer({[OfftakeContractOffer.columnSchema.offerName]: x})
                            }}
                            disabled
                            style={{marginRight: "20px", marginBottom: "20px", width: "300px"}}
                        />
                        <PalantirTextField
                            label="Offer ID"
                            value={offer[OfftakeContractOffer.columnSchema.oid]}
                            disabled
                            style={{marginRight: "20px", marginBottom: "20px",}}
                        />
                        <PalantirTextField
                            label="Project ID"
                            value={offer[OfftakeContractOffer.columnSchema.projectId]}
                            disabled
                            style={{marginRight: "20px", marginBottom: "20px",}}
                        />
                    </div>
                    <div className="flow-horizontal" style={{flexWrap: "wrap", marginBottom: "0px"}}>
                        <PalantirAutocomplete
                            label="Offtaker"
                            value={{label: offtakeName, id: offtakeId}}
                            options={offtakers.map(offtaker => {
                                return {
                                    label: offtaker[Counterparty.columnSchema.counterpartyName], id: offtaker[Counterparty.columnSchema.counterpartyId]
                                }
                            })}
                            onUpdate={(x) => {
                                const offerName = buildOfferName({offtakerId: x.id || null, offtakerName: x.label || null})
                                removeErrors(OfftakeContractOffer.columnSchema.offtakerId)
                                updateOffer({[OfftakeContractOffer.columnSchema.offtakerId]: x.id, [OfftakeContractOffer.columnSchema.offerName]: offerName})
                            }}
                            style={{marginRight: "20px", marginBottom: "20px", maxWidth: "220px"}}
                            InputProps={{...offerOfftakerErrorProps}}
                        />
                        <PalantirSelector
                            label="Technology"
                            value={offer[OfftakeContractOffer.columnSchema.technology]}
                            items={OfftakeContractOffer.options.technology}
                            onChange={(x) => {
                                const update = {[OfftakeContractOffer.columnSchema.technology]: x}
                                // If tech is switched to PV remove any selected invalid offer types
                                if (x==="pv") {
                                    const validOfferTypes = []
                                    splitOfferType(offer[OfftakeContractOffer.columnSchema.contractType]).forEach(y => {{
                                        if (OfftakeContractOffer.attributes.PVOfferTypeOptions.includes(y)) validOfferTypes.push(y)
                                    }})
                                    update[OfftakeContractOffer.columnSchema.contractType] = joinOfferType(validOfferTypes)
                                }
                                removeErrors(OfftakeContractOffer.columnSchema.technology)
                                updateOffer(update)
                            }}
                            style={{marginRight: "20px", marginBottom: "0px"}}
                            {...offerTechnologyErrorProps}
                        />
                    </div>
                    <div className="flow-horizontal" style={{flexWrap: "wrap", marginBottom: "0px"}}>
                        <PalantirSelector
                            label="Status"
                            value={offer[OfftakeContractOffer.columnSchema.status]}
                            items={OfftakeContractOffer.options.status}
                            onChange={(x) => updateOffer({[OfftakeContractOffer.columnSchema.status]: x})}
                            style={{marginRight: "20px", marginBottom: "20px", maxWidth: "220px"}}
                        />
                        <PalantirSelector
                            label="Solicitation Type"
                            value={offer[OfftakeContractOffer.columnSchema.solicitationType]}
                            items={OfftakeContractOffer.options.solicitationType}
                            onChange={(x) => updateOffer({[OfftakeContractOffer.columnSchema.solicitationType]: x})}
                            style={{marginRight: "20px", marginBottom: "20px", maxWidth: "220px"}}
                        />
                    </div>
                    <div className="flow-horizontal" style={{flexWrap: "wrap", marginBottom: "0px"}}>
                        <PalantirTextField
                            label="Offer Document Link"
                            value={offer[OfftakeContractOffer.columnSchema.offerDocumentLink]}
                            onChange={(x) => updateOffer({[OfftakeContractOffer.columnSchema.offerDocumentLink]: x})}
                            style={{marginRight: "20px", marginBottom: "20px"}}
                        />
                        <PalantirAutocomplete
                            label="Previous Offer"
                            value={{label: previousOfferName, id: previousOfferId}}
                            options={offtakeOfferRecords.map(offer => {
                                return {
                                    label: offer[OfftakeContractOffer.columnSchema.offerName], id: offer[OfftakeContractOffer.columnSchema.oid]
                                }
                            })}
                            onUpdate={(x) => {
                                updateOffer({[OfftakeContractOffer.columnSchema.previousOfferId]: x.id})
                            }}
                            style={{marginRight: "20px", marginBottom: "20px", maxWidth: "220px"}}
                        />
                    </div>
                    <MultiSelect
                        label="Contract Type"
                        value={splitOfferType(offer[OfftakeContractOffer.columnSchema.contractType])}
                        data={determineContractTypeOptions(offer[OfftakeContractOffer.columnSchema.technology])}
                        onChange={(x) => {
                            removeErrors(OfftakeContractOffer.columnSchema.contractType)
                            updateOffer({[OfftakeContractOffer.columnSchema.contractType]: joinOfferType(x)})
                        }}
                        style={{marginBottom: "20px", maxWidth: "300px"}}
                        nothingFound="Select a Technology"
                        {...offerTypeErrorProps}
                    />
                    {contractType && <OfferSections offer={offer} offtakeOfferRecords={offtakeOfferRecords} buildOfferName={buildOfferName} updateOffer={updateOffer}  errors={errors} removeErrors={removeErrors} />}
                </div>
            </div>
    )
}

const OfferSections = ({offer, updateOffer, buildOfferName}) => {

    const offerType = offer[OfftakeContractOffer.columnSchema.contractType]

    if (offerType==="BTA") {
        return (
            <>
                <DatesSection offer={offer} updateOffer={updateOffer} buildOfferName={buildOfferName} />
                <ProjectAttributesSection offer={offer} updateOffer={updateOffer} />
                <PricingSection offer={offer} updateOffer={updateOffer} showEscalation={false} showMargin={true} />
            </>
        )
    }

    const offerTypeIncludesBTA = splitOfferType(offer[OfftakeContractOffer.columnSchema.contractType]).includes("BTA")

    return (
        <>
            <DatesSection offer={offer} updateOffer={updateOffer} buildOfferName={buildOfferName} />
            <ProjectAttributesSection offer={offer} updateOffer={updateOffer} />
            <PricingSection offer={offer} updateOffer={updateOffer} showEscalation={true} showMargin={offerTypeIncludesBTA} />
            <FinancialsSection offer={offer} updateOffer={updateOffer} />
            <DetailsSection offer={offer} updateOffer={updateOffer} />
            <CommentarySection offer={offer} updateOffer={updateOffer} />
        </>
    )
}

const DatesSection = ({offer, updateOffer, buildOfferName}) => {
    return (
        <>
            <Text size="lg" weight={500} style={{marginTop: "40px"}}>Offer Dates</Text>
            <CustomHR />
            <div className="flow-horizontal" style={{flexWrap: "wrap", marginBottom: "20px"}}>
                <PalantirDatePicker
                    label="Offer Sent Date"
                    value={offer[OfftakeContractOffer.columnSchema.sentDate]}
                    onChange={(date) => {
                        const offerName = buildOfferName({sentDate: date || null})
                        updateOffer({[OfftakeContractOffer.columnSchema.sentDate]: date, [OfftakeContractOffer.columnSchema.offerName]: offerName})
                    }}
                    style={{marginRight: "20px", marginBottom: "20px",}}
                />
                <PalantirDatePicker
                    label="Due Date"
                    value={offer[OfftakeContractOffer.columnSchema.dueDate]}
                    onChange={(date) => {
                        updateOffer({[OfftakeContractOffer.columnSchema.dueDate]: date})
                    }}
                    style={{marginRight: "20px", marginBottom: "20px",}}
                />
                <PalantirDatePicker
                    label="Final Pricing Due Date"
                    value={offer[OfftakeContractOffer.columnSchema.finalPricingDueDate]}
                    onChange={(date) => {
                        updateOffer({[OfftakeContractOffer.columnSchema.finalPricingDueDate]: date})
                    }}
                    style={{marginRight: "20px", marginBottom: "20px",}}
                />
            </div>
        </>
    )
}

const ProjectSizing = ({type, offer, updateOffer, sizeCol, minSizeCol, maxSizeCol, BESSDurationSizeCol}) => {

    const [sizingType, setSizingType] = useState((offer[minSizeCol]!==null || offer[maxSizeCol]!==null) ? 'range' : 'static')

    const label = type==="pv" ? "PV" : "BESS"

    return (
        <div className="flow-vertical" style={{marginRight: "80px", marginBottom: "20px"}}>
            <div className="flow-horizontal" style={{alignItems: "center", marginBottom: "6px"}}>
                <Text size="lg" weight={500} style={{marginRight: "6px"}}>{label} Size</Text>
            </div>
            <SegmentedControl
                value={sizingType}
                onChange={setSizingType}
                data={[
                    { label: 'Static', value: 'static' },
                    { label: 'Range', value: 'range' },
                ]}
                style={{width: "fit-content", marginBottom: "15px", marginTop: "0px"}}
            />
            {
                sizingType==="static" &&
                <PalantirTextField
                    label="Size (MW AC)"
                    value={offer[sizeCol]}
                    onChange={(x) => {
                        updateOffer({
                            [minSizeCol]: null,
                            [maxSizeCol]: null,
                            [sizeCol]: x
                        })
                    }}
                    InputProps={{
                        inputComponent: TextMaskSize
                    }}
                    style={{marginBottom: "10px", width: "110px"}}
                />
            }
            {
                sizingType==="range" &&
                <div className="flow-horizontal" style={{flexWrap: "wrap", marginBottom: "10px"}}>
                    <PalantirTextField
                        label="Min (MW AC)"
                        value={offer[minSizeCol]}
                        onChange={(x) => {
                            updateOffer({[minSizeCol]: x, [sizeCol]: null})
                        }}
                        InputProps={{
                            inputComponent: TextMaskSize
                        }}
                        style={{marginRight: "20px", marginBottom: "10px", width: "110px"}}
                    />
                    <PalantirTextField
                        label="Max (MW AC)"
                        value={offer[maxSizeCol]}
                        onChange={(x) => {
                            updateOffer({[maxSizeCol]: x, [sizeCol]: null})
                        }}
                        InputProps={{
                            inputComponent: TextMaskSize
                        }}
                        style={{marginBottom: "10px", width: "110px"}}
                    />
                </div>
            }
            {type==="bess" &&
                <PalantirTextField
                    label="Duration (hrs.)"
                    value={offer[BESSDurationSizeCol]}
                    onChange={(x) => updateOffer({[BESSDurationSizeCol]: x})}
                    InputProps={{
                        inputComponent: TextMaskSize
                    }}
                    style={{marginBottom: "10px", width: "110px"}}
                />
            }
        </div>
    )
}

const ProjectAttributesSection = ({offer, updateOffer}) => {

    const displayPVFields = ["pv", "hybrid_pv_bess"].includes(offer[OfftakeContractOffer.columnSchema.technology])
    const displayBESSFields = ["standalone_bess", "hybrid_pv_bess"].includes(offer[OfftakeContractOffer.columnSchema.technology])

    return (
        <>
            <Text size="lg" weight={500}>Project Attributes</Text>
            <CustomHR />
            <PalantirDatePicker
                label="COD"
                value={offer[OfftakeContractOffer.columnSchema.cod]}
                onChange={(date) => updateOffer({[OfftakeContractOffer.columnSchema.cod]: date})}
                style={{marginRight: "20px", marginBottom: "20px",}}
            />
            <div className="flow-horizontal" style={{flexWrap: "wrap", marginTop: "10px", marginBottom: "20px"}}>
                {displayPVFields && 
                    <ProjectSizing
                        type="pv"
                        offer={offer}
                        updateOffer={updateOffer}
                        sizeCol={OfftakeContractOffer.columnSchema.sizePVMWAC}
                        minSizeCol={OfftakeContractOffer.columnSchema.sizePVMinMWAC}
                        maxSizeCol={OfftakeContractOffer.columnSchema.sizePVMaxMWAC}
                    />
                }
                {displayBESSFields &&
                    <ProjectSizing
                        type="bess"
                        offer={offer}
                        updateOffer={updateOffer}
                        sizeCol={OfftakeContractOffer.columnSchema.sizeBESSMWAC}
                        minSizeCol={OfftakeContractOffer.columnSchema.sizeBESSMinMWAC}
                        maxSizeCol={OfftakeContractOffer.columnSchema.sizeBESSMaxMWAC}
                        BESSDurationSizeCol={OfftakeContractOffer.columnSchema.durationBESS}
                    />
                }
            </div>
        </>
    )
}

const PricingSection = ({offer, updateOffer, showEscalation, showMargin}) => {

    

    return (
        <>
            <Text size="lg" weight={500}>Pricing</Text>
            <CustomHR />
            <div className="flow-horizontal" style={{flexWrap: "wrap", marginBottom: "20px"}}>
                <PalantirTextField
                    label="Price"
                    value={offer[OfftakeContractOffer.columnSchema.price]}
                    onChange={(x) => updateOffer({[OfftakeContractOffer.columnSchema.price]: x})}
                    InputProps={{
                        inputComponent: TextMaskSize
                    }}
                    style={{marginRight: "20px", marginBottom: "20px",}}
                />
                <PalantirSelector
                    label="Pricing Unit"
                    value={offer[OfftakeContractOffer.columnSchema.pricingUnit]}
                    items={OfftakeContractOffer.options.pricingUnit}
                    onChange={(x) => updateOffer({[OfftakeContractOffer.columnSchema.pricingUnit]: x})}
                    style={{marginRight: "20px", marginBottom: "20px",}}
                />
                {showEscalation &&
                    <PalantirTextField
                        label="Escalation Rate (%)"
                        value={offer[OfftakeContractOffer.columnSchema.escalationRatePercent]}
                        onChange={(x) => updateOffer({[OfftakeContractOffer.columnSchema.escalationRatePercent]: x})}
                        InputProps={{
                            inputComponent: TextMaskPercentage
                        }}
                        style={{marginRight: "20px", marginBottom: "20px",}}
                    />
                }
                {showMargin &&
                    <PalantirTextField
                        label="Margin ($)"
                        value={offer[OfftakeContractOffer.columnSchema.marginDollar]}
                        onChange={(x) => updateOffer({[OfftakeContractOffer.columnSchema.marginDollar]: x})}
                        InputProps={{
                            inputComponent: TextMaskMargin
                        }}
                        style={{marginRight: "20px", marginBottom: "20px",}}
                    />
                }
            </div>
            <div className="flow-vertical" style={{flexGrow: 0, marginBottom: "20px", width: "300px"}}>
                <PalantirSelector
                    label="Price Floor"
                    value={offer[OfftakeContractOffer.columnSchema.priceFloor]}
                    items={booleanOptions}
                    onChange={(x) => updateOffer({[OfftakeContractOffer.columnSchema.priceFloor]: x})}
                    style={{marginRight: "20px", marginBottom: "20px",}}
                />
                {offer[OfftakeContractOffer.columnSchema.priceFloor] && <PalantirDispatchedTextField
                    label="Price Floor Notes"
                    value={offer[OfftakeContractOffer.columnSchema.priceFloorNotes]}
                    onChange={(x) => updateOffer({[OfftakeContractOffer.columnSchema.priceFloorNotes]: x})}
                    multiline
                    rows={3}
                    variant="filled"
                    style={{flexGrow: 0}}
                />}
            </div>
        </>
    )
}

const FinancialsSection = ({offer, updateOffer}) => {
    return (
        <>
            <Text size="lg" weight={500}>Financials</Text>
            <CustomHR />
            <div className="flow-horizontal" style={{flexWrap: "wrap", marginBottom: "20px"}}>
                <PalantirTextField
                    label="Lifetime IRR (%)"
                    value={offer[OfftakeContractOffer.columnSchema.lifetimeIRR]}
                    onChange={(x) => updateOffer({[OfftakeContractOffer.columnSchema.lifetimeIRR]: x})}
                    InputProps={{
                        inputComponent: TextMaskPercentage
                    }}
                    style={{marginRight: "20px", marginBottom: "20px",}}
                />
                <PalantirTextField
                    label="Contracted IRR (%)"
                    value={offer[OfftakeContractOffer.columnSchema.contractedIRR]}
                    onChange={(x) => updateOffer({[OfftakeContractOffer.columnSchema.contractedIRR]: x})}
                    InputProps={{
                        inputComponent: TextMaskPercentage
                    }}
                    style={{marginRight: "20px", marginBottom: "20px",}}
                />
            </div>
            <div className="flow-horizontal" style={{flexWrap: "wrap", marginBottom: "20px"}}>
                <PalantirTextField
                    label="Contracted MOIC"
                    value={offer[OfftakeContractOffer.columnSchema.contractedMOIC]}
                    onChange={(x) => updateOffer({[OfftakeContractOffer.columnSchema.contractedMOIC]: x})}
                    InputProps={{
                        inputComponent: TextMaskNumber
                    }}
                    style={{marginRight: "20px", marginBottom: "20px",}}
                />
                <PalantirTextField
                    label="Total Capex ($/W DC)"
                    value={offer[OfftakeContractOffer.columnSchema.totalCapexDollarperwattDC]}
                    onChange={(x) => updateOffer({[OfftakeContractOffer.columnSchema.totalCapexDollarperwattDC]: x})}
                    InputProps={{
                        inputComponent: TextMaskNumber
                    }}
                    style={{marginRight: "20px", marginBottom: "20px"}}
                />
            </div>
        </>
    )
}

const DetailsSection = ({offer, updateOffer}) => {
    return (
        <>
            <Text size="lg" weight={500}>Details</Text>
            <CustomHR />
            <MultiSelect
                label="Product"
                value={splitOfferType(offer[OfftakeContractOffer.columnSchema.product])}
                data={OfftakeContractOffer.options.product}
                onChange={(x) => updateOffer({[OfftakeContractOffer.columnSchema.product]: joinOfferType(x)})}
                style={{marginRight: "20px", marginBottom: "20px", maxWidth: "300px"}}
            />
            <div className="flow-horizontal" style={{flexWrap: "wrap", marginBottom: "20px"}}>
                <PalantirSelector
                    label="Structure"
                    value={offer[OfftakeContractOffer.columnSchema.structure]}
                    items={OfftakeContractOffer.options.structure}
                    onChange={(x) => updateOffer({[OfftakeContractOffer.columnSchema.structure]: x})}
                    style={{marginRight: "20px", marginBottom: "20px",}}
                />
                {offer[OfftakeContractOffer.columnSchema.structure]==="other" && <PalantirDispatchedTextField
                    label="Structure Notes"
                    value={offer[OfftakeContractOffer.columnSchema.structureNotes]}
                    onChange={(x) => updateOffer({[OfftakeContractOffer.columnSchema.structureNotes]: x})}
                    multiline
                    rows={3}
                    variant="filled"
                    style={{marginRight: "20px", marginBottom: "20px",}}
                />}
            </div>
            <div className="flow-horizontal" style={{flexWrap: "wrap", marginBottom: "20px"}}>
                <PalantirSelector
                    label="Settlement Location"
                    value={offer[OfftakeContractOffer.columnSchema.settlementLocation]}
                    items={OfftakeContractOffer.options.settlementLocation}
                    onChange={(x) => updateOffer({[OfftakeContractOffer.columnSchema.settlementLocation]: x})}
                    style={{marginRight: "20px", marginBottom: "20px",}}
                />
                <PalantirTextField
                    label="Tenor"
                    value={offer[OfftakeContractOffer.columnSchema.tenor]}
                    onChange={(x) => updateOffer({[OfftakeContractOffer.columnSchema.tenor]: x})}
                    InputProps={{
                        inputComponent: TextMaskTenor
                    }}
                    style={{marginRight: "20px", marginBottom: "20px",}}
                />
            </div>
            <div className="flow-horizontal" style={{flexWrap: "wrap"}}>
                <div className="flow-vertical" style={{flexGrow: 0, marginBottom: "20px", marginRight: "50px", width: "300px"}}>
                    <PalantirSelector
                        label="Purchase Option"
                        value={offer[OfftakeContractOffer.columnSchema.purchaseOption]}
                        items={booleanOptions}
                        onChange={(x) => updateOffer({[OfftakeContractOffer.columnSchema.purchaseOption]: x})}
                        style={{marginRight: "20px", marginBottom: "20px",}}
                    />
                    {offer[OfftakeContractOffer.columnSchema.purchaseOption] && <PalantirDispatchedTextField
                        label="Purchase Option Notes"
                        value={offer[OfftakeContractOffer.columnSchema.purchaseOptionNotes]}
                        onChange={(x) => updateOffer({[OfftakeContractOffer.columnSchema.purchaseOptionNotes]: x})}
                        multiline
                        rows={3}
                        variant="filled"
                        style={{flexGrow: 0}}
                    />}
                </div>
            </div>
            <div className="flow-horizontal" style={{flexWrap: "wrap", marginBottom: "20px"}}>
                <PalantirTextField
                    label="Bid Fee Included"
                    value={offer[OfftakeContractOffer.columnSchema.bidFeeIncluded]}
                    onChange={(x) => updateOffer({[OfftakeContractOffer.columnSchema.bidFeeIncluded]: x})}
                    style={{marginRight: "20px", marginBottom: "20px",}}
                />
                <PalantirTextField
                    label="Basis"
                    value={offer[OfftakeContractOffer.columnSchema.basis]}
                    onChange={(x) => updateOffer({[OfftakeContractOffer.columnSchema.basis]: x})}
                    InputProps={{
                        inputComponent: TextMaskNumber
                    }}
                    style={{marginRight: "20px", marginBottom: "20px",}}
                />
            </div>
        </>
    )
}

const CommentarySection = ({offer, updateOffer}) => {
    return (
        <>
            <Text size="lg" weight={500}>Commentary</Text>
            <CustomHR />
            <div className="flow-horizontal" style={{flexWrap: "wrap", marginBottom: "20px"}}>
                <PalantirDispatchedTextField
                    label="Next Steps"
                    value={offer[OfftakeContractOffer.columnSchema.nextSteps]}
                    onChange={(x) => updateOffer({[OfftakeContractOffer.columnSchema.nextSteps]: x})}
                    multiline
                    rows={5}
                    variant="filled"
                    style={{marginRight: "20px", marginBottom: "20px", flexGrow: 1}}
                />
                <PalantirDispatchedTextField
                    label="Notes"
                    value={offer[OfftakeContractOffer.columnSchema.notes]}
                    onChange={(x) => updateOffer({[OfftakeContractOffer.columnSchema.notes]: x})}
                    multiline
                    rows={5}
                    variant="filled"
                    style={{marginRight: "20px", marginBottom: "20px", flexGrow: 1}}
                />
            </div>
        </>
    )
}


const splitOfferType = (offerType) => {
    return offerType ? offerType.split(" + ") : []
}
const joinOfferType = (offerTypes) => {
    return offerTypes.join(" + ") || null
}

const determineContractTypeOptions = (technology) => {
    if (!technology) {
        return []
    }
    else if (technology==="pv") {
        return OfftakeContractOffer.options.contractType.filter(x => OfftakeContractOffer.attributes.PVOfferTypeOptions.includes(x.value))
    }
    else {
        return OfftakeContractOffer.options.contractType
    }
}