import { useState } from 'react'

import PlantMenu from '../precious_components/menu/PlantMenu'
import ViewContainer from '../precious_components/ViewContainer'
import HelpPanel from '../components/help/HelpPanel'

import { Outlet, useOutletContext } from 'react-router-dom'
import { ViewMenu } from './menu/TableMenu'


export const ViewsTab = () => {

    const { services, authServices } = useOutletContext()

    const [selectedPortfolio, setSelectedPortfolio] = useState(null)
    const [selectedPlant, setSelectedPlant] = useState(null)
    const [selectedView, setSelectedView] = useState(null)

    const setSelectedViewProxy = (view) => {
        setSelectedView(view)
    }
    const setSelectedPlantProxy = (plant) => {
        const newSelection = plant===selectedPlant ? null : plant
        setSelectedPortfolio(null)
        setSelectedPlant(newSelection)
    }
    const setSelectedPortfolioProxy = (portfolio) => {
        //const newSelection = portfolio===this.state.selectedPortfolio ? null : portfolio
        setSelectedPortfolio(portfolio)
        setSelectedPlant(null)
    }

    return (
        <div className="flow-horizontal fill-parent">
            <div className="flow-vertical" style={{overflow: "visible"}}>
                <PlantMenu
                    portfolios={services.assets.getPortfolios()}
                    plants={services.assets.getPlants()}
                    setSelectedPlant={setSelectedPlantProxy}
                    setSelectedPortfolio={setSelectedPortfolioProxy}
                    selectedPortfolioId={selectedPortfolio}
                    selectedPlantId={selectedPlant}
                />
            </div>
            {/*<div className="sidebar flow-vertical" style={{minWidth: "250px", maxWidth: "250px"}}>*/}
            <ViewMenu
                setSelectedView={setSelectedViewProxy}
                selectedView={selectedView}
            />
            {/*</div>*/}
            <Outlet context={{services, authServices, selectedPlant, selectedPortfolio}} />
        </div>
    )
}