import './Epc.css'
import React, { useState } from 'react'

import ViewPanel from '../../ViewPanel'
import { 
    PalantirSelector,
    PalantirDatePicker,
    PalantirBooleanSelector
} from '../../../../components/input/SelectPicker'
import { PalantirTextField, PalantirDispatchedTextField, makeTextMaskNumber } from '../../../../components/input/Text'
import { EPCContract, EPCCounterpartiesContract } from '../../../table_configuration/Contract'
import { EPC } from '../../../table_configuration/Counterparty'
import { useChangeLog, injectedChangeLogIdCol } from '../../../../hooks/changeLog'
import { useDBViewFormValidation } from '../../../../hooks/databaseViewFormValidation'
import { AddFloaterButtonWithPrompt } from '../../../../components/button/FloaterButtonWithPrompt'
import ContractCounterparties from '../ContractCounterparties'
import ContractHeader from '../ContractHeader'
import ContractIdentification from '../ContractIdentification'
import { getContractCounterpartyData, validateCreateContractCounterparty, buildContractNameErrorConfig, buildContractDependencyErrorPath } from '../utils'
import { generateUUID, ErrorRND, ViewFormError } from '../../../../utils/databaseAppUtils'
import { HelpContractOwnerProvidedEquipment } from '../../../../components/help/precious/Columns'

import _ from 'lodash'
import { Plant } from '../../../table_configuration/Project'

const injectedCommercialOperationDateCurrent = "injectedCODCurrent"
const epcIdCol = EPCCounterpartiesContract.columnSchema.epcId
const injectedEpcName = "epcName"
const epcContractNameCol = EPCContract.columnSchema.contractName
const epcContractNotesCol = EPCContract.columnSchema.notes

const TextMaskTermYears = makeTextMaskNumber({
    min: 0
})


/**
 * Proxy component for EPCPanel.
 * Performs one time computations on data that can be passed in to EPCPanel as props instead of calculated within the EPCPanel, so it's not recaluclted every EPCPanel render.
 * @param {*} props 
 * @returns 
 */
export default function EPCPanelProxy(props) {

    const contractRecords = props.data[EPCContract.buildId()]
    const contractCounterpartyRecords = props.data[EPCCounterpartiesContract.buildId()]
    const epcRecords = props.data[EPC.buildId()]

    const [filteredToPlantContractRecords, filteredContractCounterpartyRecords] = getContractCounterpartyData(
        contractRecords, contractCounterpartyRecords, epcRecords,
        props.selectedPlantId,
        EPCContract.columnSchema.contractId, EPCContract.columnSchema.plantId,
        EPCCounterpartiesContract.columnSchema.contractId, EPCCounterpartiesContract.columnSchema.epcId, injectedEpcName,
    )

    return (
        <EPCPanel
            epcContractRecords={filteredToPlantContractRecords}
            epcRecords={epcRecords}
            epcContractCounterpartyRecords={filteredContractCounterpartyRecords}
            validateCreateContractCounterparty={(record, newRecord) => validateCreateContractCounterparty(EPCCounterpartiesContract, EPCCounterpartiesContract.columnSchema.epcId, record, newRecord)}
            {...props}
        />
    )

}

/**
 * Main stateful EPCPanel component.
 * @param {*} props 
 * @returns 
 */
function EPCPanel(props) {

    const [
        epcContractRecords, prepareContractLog,
        updateContractLog, addToContractLog, deleteFromContractLog, bulkOpOnContractLog, mergeAndResetContractLog
    ] = useChangeLog(props.epcContractRecords, EPCContract)
    const [
        epcContractCounterpartiesRecords, prepareCounterpartyLog,
        updateCounterpartyLog, addToCounterpartyLog, deleteFromCounterpartyLog, bulkOpOnCounterpartyLog, mergeAndResetCounterpartyLog
    ] = useChangeLog(props.epcContractCounterpartyRecords, EPCCounterpartiesContract, props.validateCreateContractCounterparty)
    const [errors, addErrors, removeErrors, setErrors, verifyChangelogSubmission, resetErrors] = useDBViewFormValidation()

    const addToContractLogProxy = () => {
        let contract = EPCContract.buildNewRecord()
        contract[EPCContract.columnSchema.contractId] = generateUUID()
        contract[EPCContract.columnSchema.plantId] = props.selectedPlantId
        addToContractLog(contract)
    }
    const deleteFromContractLogProxy = (epcContract) => {
        // Delete all counterparty records associated with this contract, if any
        let contractId = epcContract[EPCContract.columnSchema.contractId]
        let counterpartiesInDeletedContract = epcContractCounterpartiesRecords.filter(record => record[EPCCounterpartiesContract.columnSchema.contractId]===contractId)
        deleteFromCounterpartyLog(counterpartiesInDeletedContract)
        deleteFromContractLog(epcContract)
        removeErrors([contractId])
    }

    const epcContractComponents = epcContractRecords.map(epcContract => {
        let contractId = epcContract[EPCContract.columnSchema.contractId]
        let epcCounterparties = epcContractCounterpartiesRecords.filter(x => x[EPCCounterpartiesContract.columnSchema.contractId]===contractId)
        return (
            <EPCContractForm
                key={contractId}
                epcContract={epcContract}
                contractId={contractId}
                allEpcCounterparties={props.epcRecords}
                epcCounterparties={epcCounterparties}
                updateContract={(update) => updateContractLog(epcContract, update)}
                updateContractCounterparty={updateCounterpartyLog}
                addToCounterpartyLog={() => {
                    let contractCounterparty = EPCCounterpartiesContract.buildNewRecord()
                    contractCounterparty[EPCCounterpartiesContract.columnSchema.contractId] = contractId
                    addToCounterpartyLog(contractCounterparty)
                }}
                deleteFromContractLog={() => deleteFromContractLogProxy(epcContract)}
                deleteFromCounterpartyLog={deleteFromCounterpartyLog}
                errors={errors}
                removeErrors={removeErrors}
                services={props.services}
            />
        )
    })

    return (
        <ViewPanel
            services={props.services}
            title="EPC Contract"
            submitUrl='/api/precious/table/epc_contract'
            verifySubmit={(payload) => {
                return verifyChangelogSubmission(
                    {
                        changeLog: payload.epcContractChangelog,
                        checks: [buildContractNameErrorConfig(EPCContract)]
                    }, {
                        changeLog: payload.epcContractCounterpartyChangelog,
                        checks: [{
                            checkColumn: EPCCounterpartiesContract.columnSchema.epcId,
                            checkFunction: "nullCheck",
                            errMessage: "Please select an EPC.",
                            path: buildContractDependencyErrorPath(EPCCounterpartiesContract, EPCCounterpartiesContract.columnSchema.epcId)
                        }]
                    }
                )
            }}
            onSubmitSuccess={(response, requestPayload) => {
                props.handleUpdate(false)
                resetErrors()
                mergeAndResetContractLog()
                mergeAndResetCounterpartyLog()
            }}
            onSubmitError={null}
            buildSubmitPayload={() => {
                return {
                    epcContractChangelog: prepareContractLog(),
                    epcContractCounterpartyChangelog: prepareCounterpartyLog(),
                }
            }}
        >
            {epcContractComponents}
            <AddFloaterButtonWithPrompt
                onClick={addToContractLogProxy}
                labelContent="Add new contract"
                height={18}
                width={18}
            />
        </ViewPanel>
    )
}

/**
 * 
 */
const EPCContractForm = React.memo(function({
    epcContract, contractId,
    allEpcCounterparties, epcCounterparties, updateContract, updateContractCounterparty,
    addToCounterpartyLog, deleteFromCounterpartyLog, deleteFromContractLog, errors, removeErrors, services
}) {
    
    return (
        <div className="field-group form-instance" style={{scroll: "auto", marginBottom: "100px", minWidth: "1200px"}}>
            <ContractHeader
                contractName={epcContract[EPCContract.columnSchema.contractName]}
                deleteFromContractLog={deleteFromContractLog}
            />
            <ContractIdentification
                ContractTable={EPCContract}
                contract={epcContract}
                contractIdCol={EPCContract.columnSchema.contractId}
                contractNameCol={EPCContract.columnSchema.contractName}
                contractPlantIdCol={EPCContract.columnSchema.plantId}
                onContractNameChange={(x) => updateContract({[EPCContract.columnSchema.contractName]: x})}
                errors={errors}
                removeErrors={removeErrors}
            />
            <div className="flow-horizontal" style={{marginBottom: "20px"}} >
                <ContractCounterparties
                    ContractCounterpartyTable={EPCCounterpartiesContract}
                    contract={epcContract}
                    contractIdCol={EPCContract.columnSchema.contractId}
                    contractCounterpartyIdCol={epcIdCol}
                    contractCounterpartyNameCol={injectedEpcName}
                    contractCounterpartyNotesCol={EPCCounterpartiesContract.columnSchema.notes}
                    allCounterpartyRecords={allEpcCounterparties}
                    filteredContractCounterpartyRecords={epcCounterparties}
                    deleteFromCounterpartyLog={deleteFromCounterpartyLog}
                    addToCounterpartyLog={addToCounterpartyLog}
                    updateContractCounterparty={updateContractCounterparty}
                    orientation="horizontal"
                    counterpartyType={"EPC"}
                    counterpartyTypePlural={"EPCs"}
                    SupplementalComponent={({contractCounterpartyRecord}) => {
                        return (
                            <PalantirSelector
                                label="Bidder Status"
                                value={contractCounterpartyRecord[EPCCounterpartiesContract.columnSchema.bidderStatus]}
                                items={EPCCounterpartiesContract.options.bidderStatus}
                                onChange={(x) => updateContractCounterparty(contractCounterpartyRecord, {[EPCCounterpartiesContract.columnSchema.bidderStatus]: x})}
                            />
                        )
                    }}
                    errors={errors}
                    removeErrors={removeErrors}
                />
                <div style={{marginLeft: "20px"}}>
                    <EPCContractFormBody
                        epcContract={epcContract}
                        updateContract={updateContract}
                        services={services}
                    />
                </div>
            </div>
        </div>
    )
}, (prevProps, nextProps) => {
    return (
        _.isEqual(prevProps.epcContract, nextProps.epcContract) &&
        _.isEqual(prevProps.epcCounterparties, nextProps.epcCounterparties) &&
        _.isEqual(prevProps.errors, nextProps.errors)
    )
})

const EPCContractFormBody = React.memo(function({epcContract, updateContract, services}) {
    /*
    helperText="Indicates whether owner is obligated to provide any equipment. Specific equipment types can be specified in project.{equipment} forms."
    */
    return (
        <div>
            <div className="header">Contract Information</div>
            <div className="flow-horizontal">
                <PalantirSelector 
                    label="Status"
                    value={epcContract[EPCContract.columnSchema.status]}
                    items={EPCContract.options.status}
                    onChange={(x) => updateContract({[EPCContract.columnSchema.status]: x})}
                    style={{marginRight: "20px"}}
                />
                <PalantirSelector 
                    label="Owner Provided Equipment"
                    value={epcContract[EPCContract.columnSchema.ownerProvidedEquipment]}
                    items={EPCContract.options.ownerProvidedEquipment}
                    onChange={(x) => updateContract({[EPCContract.columnSchema.ownerProvidedEquipment]: x})}
                    HelpProps={{
                        title: "Contract - Owner Provided Equipment",
                        item: <HelpContractOwnerProvidedEquipment />,
                    }}
                    services={services}
                    style={{marginRight: "20px"}}
                />
                <div>
                    <PalantirDispatchedTextField
                        label="Sharepoint Folder Link"
                        value={epcContract[EPCContract.columnSchema.sharepointLink]}
                        helperText="This should be the folder link, not the file."
                        onChange={(x) => updateContract({[EPCContract.columnSchema.sharepointLink]: x})}
                    />
                    <div style={{marginTop: "4px"}}><a href={epcContract[EPCContract.columnSchema.sharepointLink]} target="_blank">Click here to visit Sharepoint link</a></div>
                </div>
            </div>
            <div style={{marginTop: "10px"}}></div>
            <PalantirDatePicker
                label="Effective Date"
                value={epcContract[EPCContract.columnSchema.effectiveDate]}
                onChange={(date) => updateContract({[EPCContract.columnSchema.effectiveDate]: date})}
                TextFieldProps={{style: {marginRight: "20px"}}}
            />
            <PalantirDatePicker
                label="End Date"
                value={epcContract[EPCContract.columnSchema.endDate]}
                onChange={(date) => updateContract({[EPCContract.columnSchema.endDate]: date})}
                TextFieldProps={{style: {marginRight: "20px"}}}
            />
            <PalantirTextField
                label="Term (Years)"
                value={epcContract[EPCContract.columnSchema.termYears]}
                InputProps={{
                    inputComponent: TextMaskTermYears
                }}
                onChange={(x) => updateContract({[EPCContract.columnSchema.termYears]: x})}
            />
            <PalantirDispatchedTextField
                label="Notes"
                value={epcContract[EPCContract.columnSchema.notes]}
                multiline
                rows={4}
                fullWidth
                variant="filled"
                onChange={(x) => updateContract({[EPCContract.columnSchema.notes]: x})}
                style={{marginTop: "20px"}}
            />
            <div className="flow-horizontal" style={{marginTop: "20px"}}>
                <div className="epc-dates-col">
                    <div className="header">Current Dates</div>
                    <PalantirDatePicker
                        label="RFP Start"
                        value={epcContract[EPCContract.columnSchema.rfpStartCurrent]}
                        onChange={(date) => updateContract({[EPCContract.columnSchema.rfpStartCurrent]: date})}
                        TextFieldProps={{fullWidth: true}}
                    />
                    <PalantirDatePicker
                        label="RFP End"
                        value={epcContract[EPCContract.columnSchema.rfpEndCurrent]}
                        onChange={(date) => updateContract({[EPCContract.columnSchema.rfpEndCurrent]: date})}
                        TextFieldProps={{fullWidth: true}}
                    />
                    <PalantirDatePicker
                        label="FNTP"
                        value={epcContract[EPCContract.columnSchema.fntpCurrent]}
                        onChange={(date) => updateContract({[EPCContract.columnSchema.fntpCurrent]: date})}
                        TextFieldProps={{fullWidth: true}}
                    />
                    <PalantirDatePicker
                        label="Start of Construction"
                        value={epcContract[EPCContract.columnSchema.startOfConstructionCurrent]}
                        onChange={(date) => updateContract({[EPCContract.columnSchema.startOfConstructionCurrent]: date})}
                        TextFieldProps={{fullWidth: true}}
                    />
                    <PalantirDatePicker
                        label="Mechanical Completion"
                        value={epcContract[EPCContract.columnSchema.mechanicalCompletionCurrent]}
                        onChange={(date) => updateContract({[EPCContract.columnSchema.mechanicalCompletionCurrent]: date})}
                        TextFieldProps={{fullWidth: true}}
                    />
                    <PalantirDatePicker
                        label="Substantial Completion"
                        value={epcContract[EPCContract.columnSchema.substantialCompletionCurrent]}
                        onChange={(date) => updateContract({[EPCContract.columnSchema.substantialCompletionCurrent]: date})}
                        TextFieldProps={{fullWidth: true}}
                    />
                    <PalantirDatePicker
                        label="Final Completion"
                        value={epcContract[EPCContract.columnSchema.finalCompletionCurrent]}
                        onChange={(date) => updateContract({[EPCContract.columnSchema.finalCompletionCurrent]: date})}
                        TextFieldProps={{fullWidth: true}}
                    />
                    <PalantirDatePicker
                        label="Placed In Service"
                        value={epcContract[EPCContract.columnSchema.placedInServiceCurrent]}
                        onChange={(date) => updateContract({[EPCContract.columnSchema.placedInServiceCurrent]: date})}
                        TextFieldProps={{fullWidth: true}}
                    />
                </div>
                <div className="epc-dates-col">
                    <div className="header">Budgeted Dates</div>
                    <PalantirDatePicker
                        label="RFP Start"
                        value={epcContract[EPCContract.columnSchema.rfpStartBudgeted]}
                        onChange={(date) => updateContract({[EPCContract.columnSchema.rfpStartBudgeted]: date})}
                        TextFieldProps={{fullWidth: true}}
                    />
                    <PalantirDatePicker
                        label="RFP End"
                        value={epcContract[EPCContract.columnSchema.rfpEndBudgeted]}
                        onChange={(date) => updateContract({[EPCContract.columnSchema.rfpEndBudgeted]: date})}
                        TextFieldProps={{fullWidth: true}}
                    />
                    <PalantirDatePicker
                        label="FNTP"
                        value={epcContract[EPCContract.columnSchema.fntpBudgeted]}
                        onChange={(date) => updateContract({[EPCContract.columnSchema.fntpBudgeted]: date})}
                        TextFieldProps={{fullWidth: true}}
                    />
                    <PalantirDatePicker
                        label="Start of Construction"
                        value={epcContract[EPCContract.columnSchema.startOfConstructionBudgeted]}
                        onChange={(date) => updateContract({[EPCContract.columnSchema.startOfConstructionBudgeted]: date})}
                        TextFieldProps={{fullWidth: true}}
                    />
                    <PalantirDatePicker
                        label="Mechanical Completion"
                        value={epcContract[EPCContract.columnSchema.mechanicalCompletionBudgeted]}
                        onChange={(date) => updateContract({[EPCContract.columnSchema.mechanicalCompletionBudgeted]: date})}
                        TextFieldProps={{fullWidth: true}}
                    />
                    <PalantirDatePicker
                        label="Substantial Completion"
                        value={epcContract[EPCContract.columnSchema.substantialCompletionBudgeted]}
                        onChange={(date) => updateContract({[EPCContract.columnSchema.substantialCompletionBudgeted]: date})}
                        TextFieldProps={{fullWidth: true}}
                    />
                    <PalantirDatePicker
                        label="Final Completion"
                        value={epcContract[EPCContract.columnSchema.finalCompletionBudgeted]}
                        onChange={(date) => updateContract({[EPCContract.columnSchema.finalCompletionBudgeted]: date})}
                        TextFieldProps={{fullWidth: true}}
                    />
                    <PalantirDatePicker
                        label="Placed In Service"
                        value={epcContract[EPCContract.columnSchema.placedInServiceBudgeted]}
                        onChange={(date) => updateContract({[EPCContract.columnSchema.placedInServiceBudgeted]: date})}
                        TextFieldProps={{fullWidth: true}}
                    />
                </div>
                <div className="epc-dates-col">
                    <div className="header">Guaranteed Dates</div>
                    <PalantirDatePicker
                        label="Mechanical Completion"
                        value={epcContract[EPCContract.columnSchema.mechanicalCompletionGuaranteed]}
                        onChange={(date) => updateContract({[EPCContract.columnSchema.mechanicalCompletionGuaranteed]: date})}
                        TextFieldProps={{fullWidth: true}}
                    />
                    <PalantirDatePicker
                        label="Substantial Completion"
                        value={epcContract[EPCContract.columnSchema.substantialCompletionGuaranteed]}
                        onChange={(date) => updateContract({[EPCContract.columnSchema.substantialCompletionGuaranteed]: date})}
                        TextFieldProps={{fullWidth: true}}
                    />
                    <PalantirDatePicker
                        label="Placed In Service"
                        value={epcContract[EPCContract.columnSchema.placedInServiceGuaranteed]}
                        onChange={(date) => updateContract({[EPCContract.columnSchema.placedInServiceGuaranteed]: date})}
                        TextFieldProps={{fullWidth: true}}
                    />
                    <PalantirDatePicker
                        label="Final Completion"
                        value={epcContract[EPCContract.columnSchema.finalCompletionGuaranteed]}
                        onChange={(date) => updateContract({[EPCContract.columnSchema.finalCompletionGuaranteed]: date})}
                        TextFieldProps={{fullWidth: true}}
                    />
                </div>
            </div>
        </div>
    )
}, (prevProps, nextProps) => {
    // Don't rerender on changes to these cols as they are not included in this component
    return _.isEqual(_.omit(prevProps.epcContract, [epcContractNameCol, epcContractNotesCol]), _.omit(nextProps.epcContract, [epcContractNameCol, epcContractNotesCol]))
})