import { useEffect, useRef, useState } from 'react'
import Map, { useMap, MapProvider, Marker, Source, Layer, Popup } from 'react-map-gl/maplibre'
import maplibregl from 'maplibre-gl';
import axios from 'axios'
import { Badge, Space, Text, Tooltip } from '@mantine/core';
import { Panel, PanelHeader } from './Shared';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';


const colorLookup = {
    "Solar": "#e9d700",
    "Wind": "#8DC63F",
    "BESS": "#74D3DE",
    "Solar + BESS": "#05425B",
    "CCGT": "#E0D227"
}

export const ProjectMapPanel = () => {
    return (
        <Panel>
            <PanelHeader title="Project Map">
                <Tooltip label="Projects filtered to Fund V assets in Greenfield, Development, Construction, or Operational stages." position="right-start">
                    <FontAwesomeIcon icon={faQuestionCircle} />
                </Tooltip>
            </PanelHeader>
            <ProjectMap />
        </Panel>
    )
}

export const ProjectMap = ({}) => {

    const mapRef = useRef();
    const [projectData, setProjectData] = useState([])
    const [popupInfo, setPopupInfo] = useState(null)

    useEffect(() => {
        axios.get("/api/precious/home/get_project_map_data")
        .then((data) => {
            const featureData = data.data.map(project => {
                return {
                    "type": "Feature",
                    "properties": {
                        color: colorLookup[project.technology],
                        name: project.plant_name,
                        ACCapacityMW: project.capacity_mw_ac,
                        StorageCapacityMW: project.storage_capacity_mw,
                        StorageCapacityMWh: project.storage_capacity_mwh,
                        technology: project.technology,
                        status: project.stage,
                        portfolioName: project.portfolio_name,
                        fundName: project.fund_name,
                    },
                    "geometry": {
                        "type": "Point",
                        "coordinates": [project.longitude, project.latitude, 0.0]
                    }
                }
            })
            setProjectData(featureData)
        })
        .catch((error) => {
            console.error(error)
        })
    }, [])

    const onProjectClick = (e) => {
        for (const feature of e.features) {
            console.log(feature)
            const coordinates = feature.geometry.coordinates.slice()
            setPopupInfo(Object.assign({
                latitude: coordinates[1],
                longitude: coordinates[0]
            }, feature.properties))
        }
    }

    const totalProjectCapacity = projectData.reduce((prev, cur, idx) => prev+cur.properties.ACCapacityMW+cur.properties.StorageCapacityMW, 0)

    const layer1 = {
        id: 'clusters',
        beforeId: 'cluster-count',
        type: 'circle',
        source: 'earthquakes',
        filter: ['has', 'point_count'],
        paint: {
            'circle-color': [
                'interpolate',
                ['linear'],
                ['get', 'total_capacity_mw'],
                0,
                '#ffe4e1',
                totalProjectCapacity / 6,
                '#EC4741'
            ],
            'circle-radius': [
                'interpolate',
                ['linear'],
                ['get', 'total_capacity_mw'],
                0,
                10,
                totalProjectCapacity / 4,
                40
            ]
        }
    }
    const layer2 = {
        id: 'cluster-count',
        type: 'symbol',
        source: 'earthquakes',
        filter: ['has', 'point_count'],
        layout: {
            'text-field': [
                'concat',
                [
                    'number-format',
                    ['get', 'total_capacity_mw'],
                    {'max-fraction-digits': 1}
                ],
                ' MW'
            ],
            'text-size': 12
        },
        paint: {
            'text-color': '#000000',
        }
    }
    const layer3 = {
        id: 'project',
        type: 'circle',
        source: 'earthquakes',
        filter: ['!', ['has', 'point_count']],
        paint: {
            'circle-color': ['get', 'color'],
            'circle-radius': 6,
            'circle-stroke-width': 1,
            'circle-stroke-color': '#fff'
        }
    }
    const layer4 = {
        id: 'project-name',
        type: 'symbol',
        source: 'earthquakes',
        filter: ['!', ['has', 'point_count']],
        layout: {
            'text-field': ['get', 'name'],
            'text-offset': [0, -1],
            'text-size': 12
        },
        paint: {
            'text-color': '#000000',
        }
    }
    const data = {
        "type": "FeatureCollection",
        "features": projectData
    }

    return (
        <Map
            initialViewState={{
                latitude: 39.8097343,
                longitude: -98.5556199,
                zoom: 3
            }}
            style={{height: "inherit"}}
            mapStyle="https://basemaps.cartocdn.com/gl/positron-gl-style/style.json"
            interactiveLayerIds={["project"]}
            onClick={onProjectClick}
            ref={mapRef}
        >
            <Source
                id="earthquakes"
                type="geojson"
                cluster={true}
                clusterMaxZoom={4}
                clusterProperties={{
                    "solar_capacity_mw": ["+", ["get", "ACCapacityMW"]],
                    "storage_capacity_mw": ["+", ["get", "StorageCapacityMW"]],
                    "total_capacity_mw": [["+", ["accumulated"], ["get", "total_capacity_mw"]], ["+", ["get", "ACCapacityMW"], ["get", "StorageCapacityMW"]]]
                }}
                data={data}
            >
                <Layer {...layer1} />
                <Layer {...layer2} />
                <Layer {...layer3} />
                <Layer {...layer4} />

                {popupInfo && (
                    <Popup longitude={popupInfo.longitude} latitude={popupInfo.latitude}
                        anchor="bottom"
                        onClose={() => setPopupInfo(null)}
                        style={{minWidth: "200px", maxWidth: "800px"}}
                    >
                        <div style={{display: "flex", alignItems: "center"}}>
                            <Text size="sm" weight={500} >{popupInfo.name}: </Text>
                            <Text size="xs" style={{marginLeft: "6px"}}>{popupInfo.status}</Text>
                        </div>
                        <Badge
                            size="sm"
                            styles={{
                                root: { backgroundColor: popupInfo.color+"40", color: popupInfo.color }
                            }}
                        >
                            {popupInfo.technology}
                        </Badge>
                        <Space h="xs" />
                        {popupInfo.technology!="BESS" &&
                            <>
                            <Text size="xs" weight={400}>AC Capacity</Text>
                            <Text size="xs" color="dimmed">{popupInfo.ACCapacityMW} MW</Text>
                            </>
                        }
                        {["BESS", "Solar + BESS"].includes(popupInfo.technology) &&
                            <>
                            <Text size="xs" weight={400}>Storage Capacity</Text>
                            <Text size="xs" color="dimmed">{popupInfo.StorageCapacityMW} MW, {popupInfo.StorageCapacityMW * 4} MWh</Text>
                            </>
                        }
                        <Text size="xs" weight={400}>Portfolio</Text>
                        <Text size="xs" color="dimmed">{popupInfo.portfolioName}</Text>
                        <Text size="xs" weight={400}>Fund</Text>
                        <Text size="xs" color="dimmed" >{popupInfo.fundName}</Text>
                        
                    </Popup>)
                }
            </Source>
        </Map>
    )
}