import './DatabaseApp.css'
import { useEffect, useState } from 'react'

import { injectServices } from './utils/ServiceInjector'
import PlantMenu from './precious_components/menu/PlantMenu'
import TableMenu from './precious_components/menu/TableMenu'
import TableEditingContainer from './precious_components/TableEditingContainer'
import ViewContainer from './precious_components/ViewContainer'
import { AppSidebarMenu } from './precious_components/menu/AppSidebarMenu'
import HelpPanel from './components/help/HelpPanel'

import DisplayMode from './precious_components/display_mode/DisplayMode'
import { Alert } from 'rsuite'
import MuiAlert from '@mui/material/Alert';
import axios from 'axios'
import { InverterManagementTab } from './precious_components/InverterWarehouse'
import { PowerBITab } from './precious_components/PowerBI'
import { SnackbarProvider, useSnackbar } from 'notistack'

import { Outlet, useOutletContext } from 'react-router-dom'
import { Avatar, Image } from '@mantine/core'
import { PalantirPopMenu, PalantirPopMenuItem } from './components/menuv2/Menu'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRightFromBracket, faX } from '@fortawesome/free-solid-svg-icons'
import { AppNavbar } from './precious_components/menu/AppNavbar'
import { Anchor } from '@mantine/core';

import { NotificationsProvider } from '@mantine/notifications';


const DEFAULT_TAB = "home"

const AppWrapper = ({...props}) => {

    return (
        <SnackbarProvider maxSnack={3}>
            <App {...props} />
        </SnackbarProvider>
    )
}
const App = () => {

    const [projectPortfolios, setProjectPortfolios] = useState([])
    const [projectPlants, setProjectPlants] = useState([])
    
    const { authServices } = useOutletContext()
    const { enqueueSnackbar, closeSnackbar } = useSnackbar()
    
    const action = <FontAwesomeIcon icon={faX} onClick={() => {localStorage.setItem("is_palantir_feedback_survey_visited", true); closeSnackbar()}} style={{marginRight: "6px"}}/>
    const msg = (
        <div style={{maxWidth: "300px", fontSize: "17px"}}>
            <span>If you haven't already, please take 2-3 minutes to provide feedback on your experience with Palantir </span>
            <Anchor href="https://forms.office.com/r/Z5yF0kw1bL" target="_blank" size="lg" onClick={() => {localStorage.setItem("is_palantir_feedback_survey_visited", true); closeSnackbar()}}> here.</Anchor >
            <span>.</span>
        </div>
    )
    const x = () => enqueueSnackbar(msg, {key: "survey", persist: true, preventDuplicate: true, action})

    useEffect(() => {
        fetchAssets()
        fetchGoogleMaps()
        if (!localStorage.getItem("is_palantir_feedback_survey_visited")) x()
    }, [])

    const fetchAssets = () => {
        axios.get("/api/precious/plants")
        .then((data) => {
            var returnedData = data.data
            setProjectPortfolios(returnedData.portfolios)
            setProjectPlants(returnedData.plants)
        })
        .catch((error) => {
            if (error.response?.status === 401) {
                authServices.logout()
            }
            console.error(error)
        })
    }

    const fetchGoogleMaps = () => {
        if (!window.google?.maps) {
            const googleMapScript = document.createElement('script');
            googleMapScript.src=`https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GMAP_KEY}&libraries=places`;
            googleMapScript.async = true;
            window.document.body.appendChild(googleMapScript);
            googleMapScript.addEventListener('load', () => {
                                console.log("loaded google maps")
                            });
        }
    }

    const services = injectServices({auth: authServices}, 'assets', {
        refresh: fetchAssets,
        getPortfolios: () => projectPortfolios,
        getPlants: () => projectPlants,
    })


    return (
        <div className="flow-vertical fill-parent" style={{backgroundColor: "#F7F9FE"}}>
            <AppNavbar authServices={authServices}></AppNavbar>
            <div className="flow-horizontal fill-parent">
                <AppSidebarMenu />
                <Outlet context={{services, authServices: authServices}} />
            </div>
        </div>
    )

}

export default AppWrapper