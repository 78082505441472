import { RiskRegister } from "../table_configuration/Project"
import { PalantirTextField, PalantirDispatchedTextField, makeTextMaskNumber } from "../../components/input/Text"
import { PalantirAutocomplete, PalantirBooleanSelector, PalantirDatePicker, PalantirSelector, } from "../../components/input/SelectPicker"
import { useChangeLog } from "../../hooks/changeLog"
import { useDBViewFormValidation } from "../../hooks/databaseViewFormValidation"
import ViewPanel from "./ViewPanel"
import { generateUUID } from "../../utils/databaseAppUtils"
import { CardHeaderWithMenu } from "../../components/panelv2/Panel2"
import { OEM } from "../table_configuration/Counterparty"
import { useState } from "react"
import { DialogProvider, useDialog } from "../../components/dialog/DialogProvider"
import { DialogContentText } from "@mui/material"
import { PalantirBasicCRUDMenu } from "../../components/menuv2/Menu"
import { Text, ActionIcon } from "@mantine/core"
import { Counterparty } from "../table_configuration/Entity"
import _ from 'lodash'

import { InterconnectionExpectedCosts, InterconnectionStudyPhasePayment, InterconnectionStudyPhasePaymentEntityMapping } from "../table_configuration/Interconnection"
import { Interconnection } from "../table_configuration/Counterparty"
import { AddFloaterButtonWithPrompt, DeleteFloaterButtonWithPrompt } from "../../components/button/FloaterButtonWithPrompt"
import { faTrash } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const paymentTextMask = makeTextMaskNumber({})



export default function OfftakePanelProxy(props) {

    const expectedCostRecords = props.data[InterconnectionExpectedCosts.buildId()]
    const studyPhasePaymentRecords = props.data[InterconnectionStudyPhasePayment.buildId()]

    const interconnectionRecords = props.data[Interconnection.buildId()]

    const projectExpectedCostRecord = expectedCostRecords.find(x => x[InterconnectionExpectedCosts.columnSchema.projectId]===props.selectedPlantId)
    const projectStudyPhasePaymentRecords = projectExpectedCostRecord ? studyPhasePaymentRecords.filter(x => x[InterconnectionStudyPhasePayment.columnSchema.expectedCostsId]===projectExpectedCostRecord[InterconnectionExpectedCosts.columnSchema.oid]) : []

    return (
        <OfftakePanel
            selectedPlantId={props.selectedPlantId}
            initialExpectedCostRecord={projectExpectedCostRecord}
            initialStudyPhasePaymentRecords={projectStudyPhasePaymentRecords}
            interconnectionCounterpartyRecords={interconnectionRecords}
            {...props}
        />
    )
}

/**
 * Main stateful OfftakePanel component.
 * @param {*} props 
 * @returns 
 */
function OfftakePanel({initialExpectedCostRecord, initialStudyPhasePaymentRecords, interconnectionCounterpartyRecords, selectedPlantId, ...props}) {

    const [
        expectedCostRecords, prepareExpectedCostsLog,
        updateExpectedCostsLog, addToExpectedCostsLog, deleteFromExpectedCostsLog, bulkOpOnExpectedCostsLog, mergeAndResetExpectedCostsLog
    ] = useChangeLog(initialExpectedCostRecord ? [initialExpectedCostRecord] : [], InterconnectionExpectedCosts)
    const [
        studyPhasePaymentRecords, prepareStudyPhasePaymentLog,
        updateStudyPhasePaymentLog, addToStudyPhasePaymentLog, deleteFromStudyPhasePaymentLog, bulkOpOnStudyPhasePaymentLog, mergeAndResetStudyPhasePaymentLog
    ] = useChangeLog(initialStudyPhasePaymentRecords, InterconnectionStudyPhasePayment)
    const [errors, addErrors, removeErrors, setErrors, verifyChangelogSubmission, resetErrors] = useDBViewFormValidation()

    const hasExpectedCostsRecord = expectedCostRecords.length > 0
    const expectedCostsRecord = hasExpectedCostsRecord ? expectedCostRecords[0] : null

    const addToExpectedCostsLogProxy = () => {
        let record = InterconnectionExpectedCosts.buildNewRecord()
        record[InterconnectionExpectedCosts.columnSchema.oid] = generateUUID()
        record[InterconnectionExpectedCosts.columnSchema.projectId] = selectedPlantId
        addToExpectedCostsLog(record)
    }
    const deleteFromExpectedCostsLogProxy = () => {
        let recordId = expectedCostsRecord[InterconnectionExpectedCosts.columnSchema.oid]
        let studyPhasePaymentsInDeletedContract = studyPhasePaymentRecords.filter(x => x[InterconnectionStudyPhasePayment.columnSchema.expectedCostsId]===recordId)
        deleteFromStudyPhasePaymentLog(studyPhasePaymentsInDeletedContract)
        deleteFromExpectedCostsLog(expectedCostsRecord)
        removeErrors([recordId])
    }
    const addToStudyPhasePaymentLogProxy = () => {
        let record = InterconnectionStudyPhasePayment.buildNewRecord()
        record[InterconnectionStudyPhasePayment.columnSchema.oid] = generateUUID()
        record[InterconnectionStudyPhasePayment.columnSchema.expectedCostsId] = expectedCostsRecord ? expectedCostsRecord[InterconnectionExpectedCosts.columnSchema.oid] : null
        console.log(record)
        addToStudyPhasePaymentLog(record)
    }
    const updateExpectedCostsLogProxy = (update) => updateExpectedCostsLog(expectedCostsRecord, update)


    return (
        <ViewPanel
            services={props.services}
            title="Interconnection Expected Costs"
            submitUrl='/api/precious/table/interconnection_expected_costs'
            verifySubmit={(payload) => {
                return verifyChangelogSubmission(
                    {
                        changeLog: payload.interconnectionExpectedCostsChangelog,
                        checks: [{
                            checkColumn: InterconnectionExpectedCosts.columnSchema.name,
                            checkFunction: "nullCheck",
                            errMessage: "Please enter a Name.",
                            path: [
                                {name: InterconnectionExpectedCosts.buildId(), type: "static"},
                                {name: InterconnectionExpectedCosts.columnSchema.name, type: "static"}
                            ]
                        }]
                    }, {
                        changeLog: payload.interconnectionStudyPhasePaymentsChangelog,
                        checks: []
                        /*checks: [{
                            checkColumn: OfftakeCreditRating.columnSchema.agency,
                            checkFunction: "nullCheck",
                            errMessage: "Please select an agency.",
                            path: buildContractDependencyErrorPath(OfftakeCreditRating, OfftakeCreditRating.columnSchema.agency)
                        }, {
                            checkColumn: OfftakeCreditRating.columnSchema.rating,
                            checkFunction: "nullCheck",
                            errMessage: "Please select a rating.",
                            path: buildContractDependencyErrorPath(OfftakeCreditRating, OfftakeCreditRating.columnSchema.rating)
                        }]*/
                    }
                )
            }}
            onSubmitSuccess={(response, requestPayload) => {
                props.handleUpdate(false)
                resetErrors()
                mergeAndResetExpectedCostsLog()
                mergeAndResetStudyPhasePaymentLog()
            }}
            onSubmitError={null}
            buildSubmitPayload={() => {
                return {
                    interconnectionExpectedCostsChangelog: prepareExpectedCostsLog(),
                    interconnectionStudyPhasePaymentsChangelog: prepareStudyPhasePaymentLog()
                }
            }}
        >
            {
                hasExpectedCostsRecord
                ? 
                <ExpectedCostsForm
                    expectedCostsRecord={expectedCostsRecord}
                    studyPhasePaymentRecords={studyPhasePaymentRecords}
                    updateExpectedCosts={updateExpectedCostsLogProxy}
                    deleteExpectedCosts={deleteFromExpectedCostsLogProxy}
                    updateStudyPhasePaymentLog={updateStudyPhasePaymentLog}
                    addToStudyPhasePaymentLog={addToStudyPhasePaymentLogProxy}
                    deleteFromStudyPhasePaymentLog={deleteFromStudyPhasePaymentLog}
                    errors={errors}
                    removeErrors={removeErrors}
                />
                :
                <div style={{minWidth: "350px"}}>
                    <AddFloaterButtonWithPrompt
                        onClick={addToExpectedCostsLogProxy}
                        labelContent="Add new interconnection expected costs record."
                        height={18}
                        width={18}
                    />
                </div>
            }
        </ViewPanel>
    )
}

const ExpectedCostsForm = ({expectedCostsRecord, studyPhasePaymentRecords, updateExpectedCosts, deleteExpectedCosts, updateStudyPhasePaymentLog, addToStudyPhasePaymentLog, deleteFromStudyPhasePaymentLog, errors, removeErrors}) => {
    
    const itemErrorPath = [InterconnectionExpectedCosts.buildId(), InterconnectionExpectedCosts.columnSchema.name]
    const itemError = errors.get(...itemErrorPath)
    const itemErrorProps = itemError ? {error: true, helperText: itemError.getMessage()} : {}

    return (
        <div className="form-instance2">
            <CardHeaderWithMenu
                label="Expected Interconnection Costs"
                inlineComponents={[
                    <ActionIcon onClick={deleteExpectedCosts}><FontAwesomeIcon icon={faTrash} size="lg" color="red" /></ActionIcon>
                ]}
            />
            <div className="body flow-vertical">
                <PalantirTextField
                    label="Record ID"
                    value={expectedCostsRecord[InterconnectionExpectedCosts.columnSchema.oid]}
                    disabled
                    style={{marginRight: "20px", marginBottom: "20px", width: "300px"}}
                />
                <PalantirDispatchedTextField
                    label="Record Name"
                    value={expectedCostsRecord[InterconnectionExpectedCosts.columnSchema.name]}
                    onChange={(x) => {
                        removeErrors(itemErrorPath)
                        updateExpectedCosts({[InterconnectionExpectedCosts.columnSchema.name]: x})
                    }}
                    style={{marginRight: "20px", marginBottom: "20px", width: "300px"}}
                    {...itemErrorProps}
                />

                <PaymentSection
                    title="Queue Submission"
                    expectedCostsRecord={expectedCostsRecord}
                    updateExpectedCosts={updateExpectedCosts}
                    amountCol={InterconnectionExpectedCosts.columnSchema.queueSubmissionPayment}
                    dateCol={InterconnectionExpectedCosts.columnSchema.queueSubmissionPaymentDate}
                />

                <PaymentSection
                    title="Site Control"
                    expectedCostsRecord={expectedCostsRecord}
                    updateExpectedCosts={updateExpectedCosts}
                    amountCol={InterconnectionExpectedCosts.columnSchema.siteControlPayment}
                    dateCol={InterconnectionExpectedCosts.columnSchema.siteControlPaymentDate}
                />

                <MultiPaymentSection
                    studyPhasePaymentRecords={studyPhasePaymentRecords}
                    updateStudyPhasePaymentLog={updateStudyPhasePaymentLog}
                    addToStudyPhasePaymentLog={addToStudyPhasePaymentLog}
                    deleteFromStudyPhasePaymentLog={deleteFromStudyPhasePaymentLog}
                />

                <PaymentSection
                    title="Construction Attachments Facility"
                    expectedCostsRecord={expectedCostsRecord}
                    updateExpectedCosts={updateExpectedCosts}
                    amountCol={InterconnectionExpectedCosts.columnSchema.constructionAttachmentsFacilityPayment}
                    dateCol={InterconnectionExpectedCosts.columnSchema.constructionAttachmentsFacilityPaymentDate}
                />
                <PaymentSection
                    title="Construction Attachments Facility (Security)"
                    expectedCostsRecord={expectedCostsRecord}
                    updateExpectedCosts={updateExpectedCosts}
                    amountCol={InterconnectionExpectedCosts.columnSchema.constructionAttachmentsFacilitySecurityPosted}
                    dateCol={InterconnectionExpectedCosts.columnSchema.constructionAttachmentsFacilitySecurityPostedDate}
                    dateLabel="Posted Date"
                />

                <PaymentSection
                    title="Construction Network Upgrade"
                    expectedCostsRecord={expectedCostsRecord}
                    updateExpectedCosts={updateExpectedCosts}
                    amountCol={InterconnectionExpectedCosts.columnSchema.constructionNetworkUpgradePayment}
                    dateCol={InterconnectionExpectedCosts.columnSchema.constructionNetworkUpgradePaymentDate}
                />
                <PaymentSection
                    title="Construction Network Upgrade (Security)"
                    expectedCostsRecord={expectedCostsRecord}
                    updateExpectedCosts={updateExpectedCosts}
                    amountCol={InterconnectionExpectedCosts.columnSchema.constructionNetworkUpgradeSecurityPosted}
                    dateCol={InterconnectionExpectedCosts.columnSchema.constructionNetworkUpgradeSecurityPostedDate}
                    dateLabel="Posted Date"
                />
                
                <PaymentSection
                    title="Affected System Facility"
                    expectedCostsRecord={expectedCostsRecord}
                    updateExpectedCosts={updateExpectedCosts}
                    amountCol={InterconnectionExpectedCosts.columnSchema.affectedSystemFacilityPayment}
                    dateCol={InterconnectionExpectedCosts.columnSchema.affectedSystemFacilityPaymentDate}
                />
                <PaymentSection
                    title="Affected System Facility (Security)"
                    expectedCostsRecord={expectedCostsRecord}
                    updateExpectedCosts={updateExpectedCosts}
                    amountCol={InterconnectionExpectedCosts.columnSchema.affectedSystemFacilitySecurityPosted}
                    dateCol={InterconnectionExpectedCosts.columnSchema.affectedSystemFacilitySecurityPostedDate}
                    dateLabel="Posted Date"
                />
                
            </div>
        </div>
    )
    
}


const PaymentSection = ({title, ...props}) => {
    return (
        <>
            <PaymentSectionHeader title={title} />
            <PaymentSectionRow {...props}  />
        </>
    )
}
const PaymentSectionHeader = ({title}) => {
    return (
        <>
            <hr style={{width: "100%"}}></hr>
            <Text size="lg" weight={500} style={{marginRight: "6px"}}>{title}</Text>
        </>
    )
}
const PaymentSectionRow = ({expectedCostsRecord, updateExpectedCosts, amountCol, dateCol, dateLabel="Payment Date"}) => {
    return (
        <div className="flow-horizontal">
            <PalantirTextField
                label="Amount ($)"
                value={expectedCostsRecord[amountCol]}
                onChange={(x) => updateExpectedCosts({[amountCol]: x})}
                InputProps={{inputComponent: paymentTextMask}}
                style={{marginRight: "20px", marginBottom: "10px", width: "250px"}}
            />
            <PalantirDatePicker
                label={dateLabel}
                value={expectedCostsRecord[dateCol]}
                onChange={(date) => updateExpectedCosts({[dateCol]: date})}
                style={{marginRight: "20px", marginBottom: "10px", width: "200px"}}
            />
        </div>
    )
}


const MultiPaymentSection = ({studyPhasePaymentRecords, updateStudyPhasePaymentLog, addToStudyPhasePaymentLog, deleteFromStudyPhasePaymentLog}) => {

    const paymentSectionRows = _.sortBy(studyPhasePaymentRecords, InterconnectionStudyPhasePayment.columnSchema.paymentType).map(studyPhasePaymentRecord => {
        const updateProxy = (update) => {
            updateStudyPhasePaymentLog(studyPhasePaymentRecord, update)
        }
        return (
            <StudyPhasePaymentSectionRow
                studyPhasePaymentRecord={studyPhasePaymentRecord}
                updateStudyPhasePayment={updateProxy}
                deleteFromStudyPhasePaymentLog={deleteFromStudyPhasePaymentLog}
            />
        )
    })

    return (
        <>
            <PaymentSectionHeader title="Study Phase Payments" />
            {paymentSectionRows}
            <AddFloaterButtonWithPrompt
                onClick={addToStudyPhasePaymentLog}
                labelContent="Add new Study Phase Payment."
                height={18}
                width={18}
            />
        </>
    )

}

const StudyPhasePaymentSectionRow = ({studyPhasePaymentRecord, updateStudyPhasePayment, deleteFromStudyPhasePaymentLog}) => {
    return (
        <div className="flow-horizontal">
            <DeleteFloaterButtonWithPrompt
                onClick={() => deleteFromStudyPhasePaymentLog(studyPhasePaymentRecord)}
                //labelContent="Add new Study Phase Payment."
                height={18}
                width={18}
            />
            <PalantirSelector
                label="Type"
                value={studyPhasePaymentRecord[InterconnectionStudyPhasePayment.columnSchema.paymentType]}
                items={InterconnectionStudyPhasePaymentEntityMapping.options.standardPaymentName}
                onChange={(x) => updateStudyPhasePayment({[InterconnectionStudyPhasePayment.columnSchema.paymentType]: x})}
                style={{marginRight: "20px", marginBottom: "20px", width: "250px"}}
            />
            <PalantirTextField
                label="Amount ($)"
                value={studyPhasePaymentRecord[InterconnectionStudyPhasePayment.columnSchema.paymentAmount]}
                onChange={(x) => updateStudyPhasePayment({[InterconnectionStudyPhasePayment.columnSchema.paymentAmount]: x})}
                style={{marginRight: "20px", marginBottom: "20px", width: "250px"}}
            />
            <PalantirDatePicker
                label="Payment Date"
                value={studyPhasePaymentRecord[InterconnectionStudyPhasePayment.columnSchema.paymentDate]}
                onChange={(date) => updateStudyPhasePayment({[InterconnectionStudyPhasePayment.columnSchema.paymentDate]: date})}
                style={{marginRight: "20px", marginBottom: "20px", width: "200px"}}
            />
        </div>
    )
}