import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the Data Grid
import "ag-grid-community/styles/ag-theme-alpine.css"; // Optional Theme applied to the Data Grid
import { useState } from "react";
import { AgGridReact } from 'ag-grid-react'; // React Data Grid Component
import { useNavigate } from 'react-router-dom'
import { Panel, PanelHeader } from "./Shared";
import { Text, Tooltip } from "@mantine/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLink, faQuestionCircle } from "@fortawesome/free-solid-svg-icons";


export const FormQuickLinksPanel = () => {
    return (
        <Panel>
            <PanelHeader title="Form Quick Links" />
            <FormQuickLinksTable />
        </Panel>
    )
}

const FormQuickLinksTable = () => {

    let navigate = useNavigate();

    // Row Data: The data to be displayed.
    const [rowData, setRowData] = useState([
      { name: "Project Info", description: "General project information", key: "plant"},
      { name: "Project Key Dates", description: "Consolidated view of important project dates", key: "projectMilestoneDates"},
      { name: "Project Personnel", description: "All personnel assigned to projects", key: "personnel"},
      { name: "Risk Register", description: "An inventory of risks associated with projects", key: "riskRegister"},
    ]);
    
    // Column Definitions: Defines the columns to be displayed.
    const [colDefs, setColDefs] = useState([
        {
            field: "link",
            maxWidth: 70,
            cellRenderer: props => {
                return <FontAwesomeIcon color="blue" icon={faLink}></FontAwesomeIcon>
            }
        },
        {field: "name"},
        {field: "description", flex: 1},
    ]);
   
    return (
        // wrapping container with theme & size
            <AgGridReact
                rowData={rowData}
                columnDefs={colDefs}
                onRowClicked={({data}) => navigate("views/"+data.key)}
                className="ag-theme-alpine"
            />
       )
}