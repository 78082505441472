import { TechnicalDueDiligence } from "../../table_configuration/Project"
import { PalantirTextField, PalantirDispatchedTextField, makeTextMaskNumber } from "../../../components/input/Text"
import { PalantirBooleanSelector, PalantirDatePicker, PalantirSelector, } from "../../../components/input/SelectPicker"
import { useChangeLog } from "../../../hooks/changeLog"
import { useDBViewFormValidation } from "../../../hooks/databaseViewFormValidation"
import ViewPanel from "../ViewPanel"
import { AddFloaterButtonWithPrompt, DeleteFloaterButtonWithPrompt } from "../../../components/button/FloaterButtonWithPrompt"
import { Text } from "@mantine/core"
import { HelpPrompt } from "../../../components/help/HelpPrompt"
import { HelpDryBulbTemp, HelpRainfall, HelpSnowLoad, HelpWindLoad, HelpSeismic, HelpFlood, HelpWetlands, HelpBiological } from "../../../components/help/precious/Columns"


const TextMaskBasic = makeTextMaskNumber({scale: 4})
const TextMaskYear = makeTextMaskNumber({
    thousandsSeparator: "",
    scale: 0,
    min: 1111
})


export default function ProjectTDDPanelProxy(props) {

    const projectTDD = props.data[TechnicalDueDiligence.buildId()].find(tdd => tdd[TechnicalDueDiligence.columnSchema.projectId]===props.selectedPlantId) || null

    return (
        <ProjectTDDPanel
            initialTDD={projectTDD}
            {...props}
        />
    )
}

function ProjectTDDPanel({initialTDD, updateFormChangeTracker, ...props}) {

    const [
        projectTDDRecords, prepareLogs, updateProjectTDDLog, addToLog,
        deleteFromLog, bulkOpOnLog, mergeAndResetLogs
    ] = useChangeLog(initialTDD!==null ? [initialTDD] : [], TechnicalDueDiligence)

    const addToProjectTDDLog = () => {
        let projectTDD = TechnicalDueDiligence.buildNewRecord()
        projectTDD[TechnicalDueDiligence.columnSchema.projectId] = props.selectedPlantId
        addToLog(projectTDD)
    }
    const [errors, addErrors, removeErrors, setErrors, verifyChangelogSubmission, resetErrors] = useDBViewFormValidation()

    const hasProjectTDDRecord = projectTDDRecords.length > 0
    const projectTDD = hasProjectTDDRecord ? projectTDDRecords[0] : null

    const updateProjectTDDProxy = (update) => updateProjectTDDLog(projectTDD, update)

    return (
        <ViewPanel
            services={props.services}
            title="Technical Due-Diligence"
            submitUrl='/api/precious/table/technical_due_diligence'
            verifySubmit={(payload) => {
                return verifyChangelogSubmission({
                    changeLog: payload.tddChangeLog,
                    checks: [{
                        checkColumn: TechnicalDueDiligence.columnSchema.projectId,
                        checkFunction: "nullCheck",
                        errMessage: "Please enter a Project ID.",
                        path: [
                            {name: TechnicalDueDiligence.columnSchema.projectId, type: "static"}
                        ]
                    }]
                })
            }}
            onSubmitSuccess={(response, requestPayload) => {
                props.handleUpdate(false)
                resetErrors()
                mergeAndResetLogs()
            }}
            onSubmitError={null}
            buildSubmitPayload={() => {
                return {
                    tddChangeLog: prepareLogs(),
                }
            }}
        >
            {
                !hasProjectTDDRecord ? 
                <div className="flow-horizontal" >
                    <div style={{marginLeft: "20px"}}>
                        <AddFloaterButtonWithPrompt
                            onClick={addToProjectTDDLog}
                            labelContent={<Text size="xs">This Project has no Technical Due-Diligence associated with it. Click to add some.</Text>}
                            height={18}
                            width={18}
                        />
                    </div>
                </div>
                :
                <div className="flow-vertical" style={{minWidth: "600px"}}>
                    <DeleteFloaterButtonWithPrompt
                        onClick={() => {
                            deleteFromLog(projectTDD)
                        }}
                        labelContent={`Delete this due-diligence.`}
                        height={18}
                        width={18}
                        style={{marginBottom: "15px"}}
                    />
                    <div className="flow-horizontal" style={{flexWrap: "wrap"}}>
                        <div className="form-instance flow-vertical" style={{height: "250px", minWidth: "450px", flexWrap: "wrap", flexGrow: 1, flexShrink: 0, marginRight: "15px"}}>
                            <PalantirDatePicker
                                label="Date of Completion"
                                value={projectTDD[TechnicalDueDiligence.columnSchema.dateOfCompletion]}
                                onChange={(date) => updateProjectTDDProxy({[TechnicalDueDiligence.columnSchema.dateOfCompletion]: date})}
                                style={{width: "200px", marginBottom: "15px", marginRight: "15px"}}
                            />
                            <PalantirSelector
                                label="Risk Category"
                                value={projectTDD[TechnicalDueDiligence.columnSchema.riskCategory]}
                                onChange={(x) => updateProjectTDDProxy({[TechnicalDueDiligence.columnSchema.riskCategory]: x})}
                                items={TechnicalDueDiligence.options.riskCategory}
                                style={{width: "200px", marginBottom: "15px", marginRight: "15px"}}
                            />
                            <PalantirSelector
                                label="ASCE Code"
                                value={projectTDD[TechnicalDueDiligence.columnSchema.ASCECode]}
                                onChange={(x) => updateProjectTDDProxy({[TechnicalDueDiligence.columnSchema.ASCECode]: x})}
                                items={TechnicalDueDiligence.options.ASCECode}
                                style={{width: "200px", marginBottom: "15px", marginRight: "15px"}}
                            />
                            <PalantirTextField
                                label="IBC Adopted Year"
                                value={projectTDD[TechnicalDueDiligence.columnSchema.IBCAdoptedYear]}
                                InputProps={{inputComponent: TextMaskYear}}
                                onChange={(x) => updateProjectTDDProxy({[TechnicalDueDiligence.columnSchema.IBCAdoptedYear]: x})}
                                style={{width: "200px", marginBottom: "15px", marginRight: "15px"}}
                            />
                            <PalantirTextField
                                label="NEC Adopted Year"
                                value={projectTDD[TechnicalDueDiligence.columnSchema.NECAdoptedYear]}
                                InputProps={{inputComponent: TextMaskYear}}
                                onChange={(x) => updateProjectTDDProxy({[TechnicalDueDiligence.columnSchema.NECAdoptedYear]: x})}
                                style={{width: "200px", marginBottom: "15px", marginRight: "15px"}}
                            />
                        </div>
                        <div className="form-instance flow-vertical" style={{height: "250px", minWidth: "550px", flexGrow: 1}}>
                            <div className="flow-vertical" style={{flexWrap: "wrap"}}>
                                <PalantirTextField
                                    label="Elevation (ft.)"
                                    value={projectTDD[TechnicalDueDiligence.columnSchema.elevationFt]}
                                    InputProps={{inputComponent: TextMaskBasic}}
                                    onChange={(x) => updateProjectTDDProxy({[TechnicalDueDiligence.columnSchema.elevationFt]: x})}
                                    style={{width: "220px", marginBottom: "15px", marginRight: "15px", marginLeft: "24px"}}
                                />
                                <div>
                                <HelpPrompt
                                    services={props.services}
                                    style={{fontSize: "14px", position: "relative", top: "17px", marginRight: "10px"}}
                                    title="24 Hour 100 Year Flood"
                                    item={<HelpRainfall />}
                                />
                                <PalantirTextField
                                    services={props.services}
                                    label="24-Hour 100 Year Flood (in.)"
                                    value={projectTDD[TechnicalDueDiligence.columnSchema.annualRainfallIn]}
                                    InputProps={{inputComponent: TextMaskBasic}}
                                    onChange={(x) => updateProjectTDDProxy({[TechnicalDueDiligence.columnSchema.annualRainfallIn]: x})}
                                    style={{width: "220px", marginBottom: "15px", marginRight: "15px"}}
                                />
                                </div>
                                <div>
                                <HelpPrompt
                                    services={props.services}
                                    style={{fontSize: "14px", position: "relative", top: "17px", marginRight: "10px"}}
                                    title={"Snow Load"}
                                    item={<HelpSnowLoad />}
                                />
                                <PalantirTextField
                                    services={props.services}
                                    label="Snow Load (lbs/sqft)"
                                    value={projectTDD[TechnicalDueDiligence.columnSchema.snowLoad]}
                                    InputProps={{inputComponent: TextMaskBasic}}
                                    onChange={(x) => updateProjectTDDProxy({[TechnicalDueDiligence.columnSchema.snowLoad]: x})}
                                    style={{width: "220px", marginBottom: "15px", marginRight: "15px"}}
                                />
                                </div>
                                <div>
                                <HelpPrompt
                                    services={props.services}
                                    style={{fontSize: "14px", position: "relative", top: "17px", marginRight: "10px"}}
                                    title={"Wind Load"}
                                    item={<HelpWindLoad />}
                                />
                                <PalantirTextField
                                    services={props.services}
                                    label="Wind Load (mph)"
                                    value={projectTDD[TechnicalDueDiligence.columnSchema.windLoad]}
                                    InputProps={{inputComponent: TextMaskBasic}}
                                    onChange={(x) => updateProjectTDDProxy({[TechnicalDueDiligence.columnSchema.windLoad]: x})}
                                    style={{width: "220px", marginRight: "15px"}}
                                />
                                </div>
                            </div>
                            <div className="flow-vertical" style={{flexShrink: 0}}>
                                <div style={{display: "flex", alignItems: "center", flexGrow: 1}}>
                                    <Text style={{flexShrink: 0}}>Extreme Annual Dry Bulb Temperature (°C)</Text>
                                    <HelpPrompt
                                        services={props.services}
                                        style={{fontSize: "14px", marginLeft: "10px"}}
                                        title={"Extreme Annual Dry Bulb Temperature"}
                                        item={<HelpDryBulbTemp />}
                                    />
                                </div>
                                <div className="flow-horizontal" style={{flexWrap: "wrap"}}>
                                    <PalantirTextField
                                        services={props.services}
                                        label="min"
                                        value={projectTDD[TechnicalDueDiligence.columnSchema.extremeAnnualDryBulbTempMinCelcius]}
                                        InputProps={{inputComponent: TextMaskBasic}}
                                        onChange={(x) => updateProjectTDDProxy({[TechnicalDueDiligence.columnSchema.extremeAnnualDryBulbTempMinCelcius]: x})}
                                        style={{marginRight: "10px"}}
                                    />
                                    <PalantirTextField
                                        services={props.services}
                                        label="max"
                                        value={projectTDD[TechnicalDueDiligence.columnSchema.extremeAnnualDryBulbTempMaxCelcius]}
                                        InputProps={{inputComponent: TextMaskBasic}}
                                        onChange={(x) => updateProjectTDDProxy({[TechnicalDueDiligence.columnSchema.extremeAnnualDryBulbTempMaxCelcius]: x})}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="form-instance flex-children-spaced">
                            <HelpPrompt
                                services={props.services}
                                style={{fontSize: "16px", marginRight: "10px", position: "relative", top: "17px"}}
                                title={"Seismic Risk Factors"}
                                item={<HelpSeismic />}
                            />
                        <PalantirSelector
                            label="Seismic Risk"
                            value={projectTDD[TechnicalDueDiligence.columnSchema.seismicRiskCategory]}
                            onChange={(x) => updateProjectTDDProxy({[TechnicalDueDiligence.columnSchema.seismicRiskCategory]: x})}
                            items={TechnicalDueDiligence.options.seismicRisk}
                            style={{marginRight: "15px", marginBottom: "15px"}}
                        />
                        <PalantirTextField
                            label="Seismic SS"
                            value={projectTDD[TechnicalDueDiligence.columnSchema.seismicSS]}
                            InputProps={{inputComponent: TextMaskBasic}}
                            onChange={(x) => updateProjectTDDProxy({[TechnicalDueDiligence.columnSchema.seismicSS]: x})}
                            style={{marginRight: "15px", marginBottom: "15px"}}
                        />
                        <PalantirTextField
                            label="Seismic S1"
                            value={projectTDD[TechnicalDueDiligence.columnSchema.seismicS1]}
                            InputProps={{inputComponent: TextMaskBasic}}
                            onChange={(x) => updateProjectTDDProxy({[TechnicalDueDiligence.columnSchema.seismicS1]: x})}
                            style={{marginRight: "15px", marginBottom: "15px"}}
                        />
                        <PalantirTextField
                            label="Seismic SDS"
                            value={projectTDD[TechnicalDueDiligence.columnSchema.seismicSDS]}
                            InputProps={{inputComponent: TextMaskBasic}}
                            onChange={(x) => updateProjectTDDProxy({[TechnicalDueDiligence.columnSchema.seismicSDS]: x})}
                            style={{marginRight: "15px", marginBottom: "15px"}}
                        />
                        <PalantirTextField
                            label="Seismic SD1"
                            value={projectTDD[TechnicalDueDiligence.columnSchema.seismicSD1]}
                            InputProps={{inputComponent: TextMaskBasic}}
                            onChange={(x) => updateProjectTDDProxy({[TechnicalDueDiligence.columnSchema.seismicSD1]: x})}
                        />
                    </div>
                    <div className="form-instance flex-children-spaced">
                            <HelpPrompt
                                services={props.services}
                                style={{fontSize: "16px", marginRight: "10px", position: "relative", top: "17px"}}
                                title={"Flood"}
                                item={<HelpFlood />}
                            />
                        <PalantirTextField
                            label="Flood Map Number"
                            value={projectTDD[TechnicalDueDiligence.columnSchema.floodMapNumber]}
                            onChange={(x) => {
                                if (x && x.length <= 20) updateProjectTDDProxy({[TechnicalDueDiligence.columnSchema.floodMapNumber]: x})
                            }}
                            style={{marginRight: "15px"}}
                        />
                        <PalantirDatePicker
                            label="Flood Map Revision Date"
                            value={projectTDD[TechnicalDueDiligence.columnSchema.floodMapRevisedDate]}
                            onChange={(date) => updateProjectTDDProxy({[TechnicalDueDiligence.columnSchema.floodMapRevisedDate]: date})}
                        />
                    </div>
                    <div className="flow-horizontal" style={{flexWrap: "wrap"}}>
                        <PalantirDispatchedTextField
                            label="Summary"
                            value={projectTDD[TechnicalDueDiligence.columnSchema.summary]}
                            variant="filled"
                            className="fill-parent"
                            style={{marginRight: "15px", marginLeft: "18px", marginBottom: "15px"}}
                            multiline
                            rows={7}
                            onChange={(x) => updateProjectTDDProxy({[TechnicalDueDiligence.columnSchema.summary]: x})}
                        />
                        <PalantirTextField
                            label="Wetlands Document Link"
                            value={projectTDD[TechnicalDueDiligence.columnSchema.wetlandsDocumentLink]}
                            onChange={(x) => updateProjectTDDProxy({[TechnicalDueDiligence.columnSchema.wetlandsDocumentLink]: x})}
                            style={{marginLeft: "18px", marginBottom: "15px", width: "220px"}}
                        />
                        <div className="flow-horizontal fill-parent">
                            <HelpPrompt
                                services={props.services}
                                style={{fontSize: "16px"}}
                                title={"Wetlands"}
                                item={<HelpWetlands />}
                            />
                            <PalantirDispatchedTextField
                                label="Wetlands Description"
                                value={projectTDD[TechnicalDueDiligence.columnSchema.wetlandsDescription]}
                                variant="filled"
                                className="fill-parent"
                                style={{marginRight: "15px", marginBottom: "15px"}}
                                multiline
                                rows={7}
                                onChange={(x) => updateProjectTDDProxy({[TechnicalDueDiligence.columnSchema.wetlandsDescription]: x})}
                            />
                        </div>
                        <PalantirTextField
                            label="Biological Document Link"
                            value={projectTDD[TechnicalDueDiligence.columnSchema.biologicalDocumentLink]}
                            onChange={(x) => updateProjectTDDProxy({[TechnicalDueDiligence.columnSchema.biologicalDocumentLink]: x})}
                            style={{marginLeft: "18px", marginBottom: "15px", width: "220px"}}
                        />
                        <div className="flow-horizontal fill-parent">
                            <HelpPrompt
                                services={props.services}
                                style={{fontSize: "16px"}}
                                title={"Biological"}
                                item={<HelpBiological />}
                            />
                            <PalantirDispatchedTextField
                                label="Biological Description"
                                value={projectTDD[TechnicalDueDiligence.columnSchema.biologicalDescription]}
                                variant="filled"
                                className="fill-parent"
                                multiline
                                rows={7}
                                onChange={(x) => updateProjectTDDProxy({[TechnicalDueDiligence.columnSchema.biologicalDescription]: x})}
                                style={{marginRight: "15px", marginBottom: "15px"}}
                            />
                        </div>
                    </div>
                </div>
            }
        </ViewPanel>
    )
}