import './HomeTab.css'
import { Grid, Stack, Title } from '@mantine/core'
import { HomePageAlert } from './HomePageAlert'
import { ProjectCapacityOverviewPanel } from './home/ProjectCapacityOverviewChart'
import { ProjectMapPanel } from './home/ProjectMap'
import { FormQuickLinksPanel } from './home/FormsQuickLinks'
import { ReleaseLogPanel } from "./home/ChangelogPanel"




export const HomeTab = () => {

    return (
        <Stack m={15} spacing={0} style={{flexGrow: 1}}>

            <Title order={2} style={{marginBottom: "12px"}}>Home</Title>

            <HomePageAlert />
            
            <Grid grow gutter={10} style={{height: "100%", overflow: "auto"}}>

                <Grid.Col span={9} style={{height: "100%", overflowY: "auto", overflowX: "hidden"}}>
                    <Grid gutter={10} m={0} grow style={{height: "100%"}} >

                        <Grid.Col span={1} style={{minHeight: "300px"}}>
                            <ProjectCapacityOverviewPanel />
                        </Grid.Col>

                        <Grid.Col span={7} style={{minHeight: "300px"}}>
                            <ProjectMapPanel />
                        </Grid.Col>
                        
                        <Grid.Col span={12} style={{minHeight: "300px"}}>
                            <FormQuickLinksPanel />
                        </Grid.Col>
                    
                    </Grid>
                </Grid.Col>
                
                <Grid.Col span={3} pl={0} py={10} style={{height: "100%", overflow: "auto", minWidth: "300px"}}>
                    <ReleaseLogPanel />
                </Grid.Col>

            </Grid>
        </Stack>
    )
}