import { useEffect, useState } from 'react'

import { injectServices } from '../utils/ServiceInjector'
import PlantMenu from './menu/PlantMenu'
import { TableMenu } from './menu/TableMenu'
import TableEditingContainer from './TableEditingContainer'
import ViewContainer from './ViewContainer'
import HelpPanel from '../components/help/HelpPanel'

import DisplayMode from './display_mode/DisplayMode'
import { Alert } from 'rsuite'
import { useOutletContext } from 'react-router-dom'


export const TablesTab = () => {

    const { services } = useOutletContext()

    const [selectedTableGroup, setSelectedTableGroup] = useState(null)
    const [selectedTable, setSelectedTable] = useState(null)


    const setSelectedTableProxy = (data) => {
        setSelectedTableGroup(data.tableGroup)
        setSelectedTable(data.table)
    }

    return (
        <div className="flow-horizontal fill-parent">
            {/*<div className="sidebar flow-vertical" style={{minWidth: "250px", maxWidth: "250px"}}>*/}
            <TableMenu
                setSelectedTable={setSelectedTableProxy}
                selectedTable={selectedTable}
            />
            {/*</div>*/}
            <div className="flow-vertical fill-parent">
                <TableEditingContainer
                    services={services}
                    selectedTableGroup={selectedTableGroup}
                    selectedTable={selectedTable}
                />
            </div>
        </div>
    )
}