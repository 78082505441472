import './ProjectEquipment.css'
import React from 'react'
import { ProjectTransformer } from "../../table_configuration/ProjectEquipment"
import { TransformerSpec } from "../../table_configuration/EquipmentSpec"
import ViewPanel from '../ViewPanel'
import { generateUUID } from '../../../utils/databaseAppUtils'
import { useChangeLog, injectedChangeLogIdCol } from '../../../hooks/changeLog'
import { useDBViewFormValidation } from '../../../hooks/databaseViewFormValidation'
import {
    ProjectEquipmentHeader, ProjectEquipmentGroupId, ProjectEquipmentSpec,
    ProjectEquipmentCount, ProjectEquipmentNotes, ProjectEquipmentStatus, ProjectEquipmentOwnerProvided, ProjectEquipmentEPCContract
} from './ProjectEquipment'
import { AddFloaterButtonWithPrompt, DeleteFloaterButtonWithPrompt } from '../../../components/button/FloaterButtonWithPrompt'
import { PalantirDatePicker, PalantirSelector } from '../../../components/input/SelectPicker'
import { makeTextMaskNumber, makeTextMaskPercentage, PalantirTextField } from '../../../components/input/Text'
import { EPCContract } from '../../table_configuration/Contract'
import _ from 'lodash'
import { Text, Alert } from '@mantine/core'


const TextMaskNumber = makeTextMaskNumber({})
const TextMaskPercent = makeTextMaskPercentage()

export default function ProjectTransformerProxy(props) {

    const transformerGroupRecords = props.data[ProjectTransformer.buildId()].filter(x => x[ProjectTransformer.columnSchema.plantId]===props.selectedPlantId)
    const transformerSpecRecords = props.data[TransformerSpec.buildId()]
    const epcContractRecords = props.data[EPCContract.buildId()].filter(x => x[EPCContract.columnSchema.plantId]===props.selectedPlantId)

    return (
        <ProjectTransformerPanel
            initialTransformerGroupRecords={transformerGroupRecords}
            initialTransformerSpecRecords={transformerSpecRecords}
            epcContractRecords={epcContractRecords}
            validateCreateTransformerGroup={(record, newRecord) => true}
            {...props}
        />
    )

}

const ProjectTransformerPanel = ({initialTransformerGroupRecords, initialTransformerSpecRecords, epcContractRecords, ...props}) => {

    const [
        transformerGroupRecords, prepareTransformerGroupLog,
        updateTransformerGroupLog, addToTransformerGroupLog, deleteFromTransformerGroupLog, bulkOpOnTransformerGroupLog, mergeAndResetTransformerGroupLog
    ] = useChangeLog(initialTransformerGroupRecords, ProjectTransformer)
    const [
        transformerSpecRecords, prepareTransformerSpecLog,
        updateTransformerSpecLog, addToTransformerSpecLog, deleteFromTransformerSpecLog, bulkOpOnTransformerSpecLog, mergeAndResetTransformerSpecLog
    ] = useChangeLog(initialTransformerSpecRecords, TransformerSpec)
    const [errors, addErrors, removeErrors, setErrors, verifyChangelogSubmission, resetErrors] = useDBViewFormValidation()

    const addToTransformerGroupLogProxy = () => {
        let transformerGroup = ProjectTransformer.buildNewRecord()
        transformerGroup[ProjectTransformer.columnSchema.transformerGroupId] = generateUUID()
        transformerGroup[ProjectTransformer.columnSchema.plantId] = props.selectedPlantId
        addToTransformerGroupLog(transformerGroup)
    }
    
    const transformerGroupComponents = transformerGroupRecords.map(transformerGroup => {
        const transformerSpec = transformerSpecRecords.find(x => x[TransformerSpec.columnSchema.transformerSpecId]===transformerGroup[ProjectTransformer.columnSchema.transformerSpecId])
        const addTransformerSpec = () => {
            const newSpec = TransformerSpec.buildNewRecord()
            newSpec[TransformerSpec.columnSchema.transformerSpecId] = generateUUID()
            addToTransformerSpecLog(newSpec)
            updateTransformerGroupLog(transformerGroup, {[ProjectTransformer.columnSchema.transformerSpecId]: newSpec[TransformerSpec.columnSchema.transformerSpecId]})
        }
        const deleteTransformerSpec = () => {
            updateTransformerGroupLog(transformerGroup, {[ProjectTransformer.columnSchema.transformerSpecId]: null})
            deleteFromTransformerSpecLog(transformerSpec)
        }
        const deleteFromTransformerGroupLogProxy = (transformerGroup) => {
            if (transformerSpec) deleteFromTransformerSpecLog(transformerSpec)
            deleteFromTransformerGroupLog(transformerGroup)
        }
        return (
            <ProjectTransformerForm
                key={transformerGroup[ProjectTransformer.columnSchema.transformerGroupId]}
                transformerGroup={transformerGroup}
                transformerSpec={transformerSpec}
                epcContractRecords={epcContractRecords}
                addTransformerSpec={addTransformerSpec}
                deleteTransformerSpecLog={deleteTransformerSpec}
                updateTransformerSpecLog={(update) => updateTransformerSpecLog(transformerSpec, update)}
                updateTransformerGroupLog={(update) => updateTransformerGroupLog(transformerGroup, update)}
                deleteFromTransformerGroupLog={() => deleteFromTransformerGroupLogProxy(transformerGroup)}
                errors={errors}
                removeErrors={removeErrors}
                services={props.services}
            />
        )
    })

    return (
        <ViewPanel
            services={props.services}
            title="Project Transformer Groups"
            submitUrl='/api/precious/table/project_transformer'
            verifySubmit={(payload) => {
                return verifyChangelogSubmission(
                    {
                        changeLog: payload.projectTransformerChangelog,
                        checks: [{
                            checkColumn: ProjectTransformer.columnSchema.transformerSpecId,
                            checkFunction: "nullCheck",
                            errMessage: "Please select a transformer specification.",
                            path: [
                                {name: ProjectTransformer.columnSchema.transformerGroupId, type: "eval"},
                                {name: ProjectTransformer.buildId(), type: "static"},
                                {name: injectedChangeLogIdCol, type: "eval"}
                            ]
                        }]
                    }, {
                        changeLog: payload.transformerSpecChangelog,
                        checks: []
                    }
                )
            }}
            onSubmitSuccess={(response, requestPayload) => {
                props.handleUpdate(false)
                resetErrors()
                mergeAndResetTransformerGroupLog()
                mergeAndResetTransformerSpecLog()
            }}
            onSubmitError={null}
            buildSubmitPayload={() => {
                return {
                    projectTransformerChangelog: prepareTransformerGroupLog(),
                    transformerSpecChangelog: prepareTransformerSpecLog(),
                }
            }}
        >
            {transformerGroupComponents}
            <AddFloaterButtonWithPrompt
                onClick={addToTransformerGroupLogProxy}
                labelContent="Add new transformer group"
                height={18}
                width={18}
            />
        </ViewPanel>
    )
}

const ProjectTransformerForm = React.memo(function({transformerGroup,
    transformerSpec, addTransformerSpec, updateTransformerSpecLog, deleteTransformerSpecLog,
    epcContractRecords, updateTransformerGroupLog, deleteFromTransformerGroupLog, errors, removeErrors, services
}) {

    const transformerGroupId = transformerGroup[ProjectTransformer.columnSchema.transformerGroupId]
    const transformerChangeLogId = transformerGroup[injectedChangeLogIdCol]
    
    const transformerSpecErrorPath = [transformerGroupId, ProjectTransformer.buildId(), transformerChangeLogId]
    const transformerSpecError = errors.get(...transformerSpecErrorPath)
    
    return (
        <div className="field-group form-instance project-equipment">
            <ProjectEquipmentHeader
                ProjectEquipmentTable={ProjectTransformer}
                deleteFromEquipmentGroupLog={deleteFromTransformerGroupLog}
                removeErrorsOnDelete={() => removeErrors([transformerGroupId])}
            />
            <div className="flow-vertical body">
                <div className="flow-vertical vertical-children-spacing" style={{maxWidth: "400px"}}>
                    <ProjectEquipmentGroupId
                        ProjectEquipmentTable={ProjectTransformer}
                        value={transformerGroupId}    
                    />
                    <ProjectEquipmentStatus
                        equipmentGroup={transformerGroup}
                        statusCol={ProjectTransformer.columnSchema.status}
                        updateEquipmentGroup={updateTransformerGroupLog}
                    />
                    <ProjectEquipmentEPCContract
                        equipmentContractIdCol={EPCContract.columnSchema.contractId}
                        equipmentGroupEPCContractId={transformerGroup[ProjectTransformer.columnSchema.contractId]}
                        epcContracts={epcContractRecords}
                        updateEquipmentGroup={updateTransformerGroupLog}
                        services={services}
                    />
                    <ProjectEquipmentOwnerProvided
                        equipmentGroup={transformerGroup}
                        ownerProvidedCol={ProjectTransformer.columnSchema.isOwnerProvided}
                        updateEquipmentGroup={updateTransformerGroupLog}
                    />
                    <ProjectEquipmentCount
                        ProjectEquipmentTable={ProjectTransformer}
                        equipmentGroup={transformerGroup}
                        equipmentGroupCountCol={ProjectTransformer.columnSchema.transformerCount}
                        updateProjectEquipmentLog={updateTransformerGroupLog}
                    />
                    <ProjectEquipmentNotes
                        value={transformerGroup[ProjectTransformer.columnSchema.notes]}
                        notesColumn={ProjectTransformer.columnSchema.notes}
                        updateProjectEquipment={updateTransformerGroupLog}
                    />
                </div>
                <div style={{marginRight: "30px", maxWidth: "400px"}}>
                    <div className="header">Transformer Spec</div>
                    <TransformerSpecForm
                        transformerSpec={transformerSpec}
                        addTransformerSpec={() => {
                            removeErrors(...transformerSpecErrorPath)
                            addTransformerSpec()
                        }}
                        updateTransformerSpec={updateTransformerSpecLog}
                        deleteTransformerSpec={deleteTransformerSpecLog}
                        transformerSpecError={transformerSpecError}
                    />
                </div>
                <div className="flow-vertical">
                    <div className="header">Dates</div>
                    <div className="flow-horizontal">
                        <div className="flow-vertical project-equipment-dates">
                            <PalantirDatePicker
                                label="Warranty Start"
                                value={transformerGroup[ProjectTransformer.columnSchema.warrantyStartDate]}
                                onChange={(date) => updateTransformerGroupLog({[ProjectTransformer.columnSchema.warrantyStartDate]: date})}
                                style={{marginBottom: "30px"}}
                            />
                            <div className="header">Current Dates</div>
                            <PalantirDatePicker
                                label="Delivery End"
                                value={transformerGroup[ProjectTransformer.columnSchema.deliveryEndDateCurrent]}
                                onChange={(date) => updateTransformerGroupLog({[ProjectTransformer.columnSchema.deliveryEndDateCurrent]: date})}
                            />
                            <PalantirDatePicker
                                label="Purchase Order"
                                value={transformerGroup[ProjectTransformer.columnSchema.purchaseOrderDateCurrent]}
                                onChange={(date) => updateTransformerGroupLog({[ProjectTransformer.columnSchema.purchaseOrderDateCurrent]: date})}
                            />
                        </div>
                        <div className="flow-vertical project-equipment-dates">
                            <PalantirDatePicker
                                label="Warranty End"
                                value={transformerGroup[ProjectTransformer.columnSchema.warrantyEndDate]}
                                onChange={(date) => updateTransformerGroupLog({[ProjectTransformer.columnSchema.warrantyEndDate]: date})}
                                style={{marginBottom: "30px"}}
                            />
                            <div className="header">Budgeted Dates</div>
                            <PalantirDatePicker
                                label="Delivery End"
                                value={transformerGroup[ProjectTransformer.columnSchema.deliveryEndDateBudgeted]}
                                onChange={(date) => updateTransformerGroupLog({[ProjectTransformer.columnSchema.deliveryEndDateBudgeted]: date})}
                            />
                            <PalantirDatePicker
                                label="Purchase Order"
                                value={transformerGroup[ProjectTransformer.columnSchema.purchaseOrderDateBudgeted]}
                                onChange={(date) => updateTransformerGroupLog({[ProjectTransformer.columnSchema.purchaseOrderDateBudgeted]: date})}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}, (prevProps, nextProps) => {
    return (
        _.isEqual(prevProps.transformerGroup, nextProps.transformerGroup) &&
        _.isEqual(prevProps.transformerSpec, nextProps.transformerSpec) &&
        _.isEqual(prevProps.errors, nextProps.errors)
    )
})

const TransformerSpecForm = ({transformerSpec, addTransformerSpec, updateTransformerSpec, deleteTransformerSpec, transformerSpecError}) => {

    if (!transformerSpec) {
        return (
            <>
                {transformerSpecError ? <Alert color="red" style={{marginBottom: "10px"}}>Please add a Transformer Spec</Alert> : null}
                <AddFloaterButtonWithPrompt labelContent="Add Spec" onClick={addTransformerSpec} height={18} width={18} />
            </>
        )
    }

    return (
        <div className="form-sub-instance project-equipment-spec">
            <DeleteFloaterButtonWithPrompt labelContent="Remove Spec" onClick={deleteTransformerSpec} height={18} width={18} />
            <PalantirTextField
                label="Transformer Spec ID"
                value={transformerSpec[TransformerSpec.columnSchema.transformerSpecId]}
                disabled
                style={{paddingRight: "20px", width: "100%"}}
            />
            <PalantirTextField
                label="Transformer Manufacturer"
                value={transformerSpec[TransformerSpec.columnSchema.manufacturer]}
                onChange={(x) => updateTransformerSpec({[TransformerSpec.columnSchema.manufacturer]: x})}
                style={{paddingRight: "20px", width: "100%"}}
            />
            <PalantirSelector
                label="Transformer Type"
                value={transformerSpec[TransformerSpec.columnSchema.transformerType]}
                onChange={(x) => updateTransformerSpec({[TransformerSpec.columnSchema.transformerType]: x})}
                items={TransformerSpec.options.transformerType}
            />
            <Text size="sm" style={{marginBottom: "5px"}}>Transformer Rating (MV/MVAR/MVA)</Text>
            <div>
                <PalantirTextField
                    value={transformerSpec[TransformerSpec.columnSchema.ratingRealMV]}
                    onChange={(x) => updateTransformerSpec({[TransformerSpec.columnSchema.ratingRealMV]: x})}
                    InputProps={{
                        inputComponent: TextMaskNumber
                    }}
                    style={{width: "50px"}}
                />
                <Text style={{margin: "0 5px", display: "inline"}}>/</Text>
                <PalantirTextField
                    value={transformerSpec[TransformerSpec.columnSchema.ratingReactiveMVAR]}
                    onChange={(x) => updateTransformerSpec({[TransformerSpec.columnSchema.ratingReactiveMVAR]: x})}
                    InputProps={{
                        inputComponent: TextMaskNumber
                    }}
                    style={{width: "50px"}}
                />
                <Text style={{margin: "0 5px", display: "inline"}}>/</Text>
                <PalantirTextField
                    value={transformerSpec[TransformerSpec.columnSchema.ratingApparentMVA]}
                    onChange={(x) => updateTransformerSpec({[TransformerSpec.columnSchema.ratingApparentMVA]: x})}
                    InputProps={{
                        inputComponent: TextMaskNumber
                    }}
                    style={{width: "50px"}}
                />
            </div>
            <Text size="sm" style={{marginBottom: "5px"}}>Windings (kV/kVAR/KVA)</Text>
            <div>
                <PalantirTextField
                    value={transformerSpec[TransformerSpec.columnSchema.windingsRealkV]}
                    onChange={(x) => updateTransformerSpec({[TransformerSpec.columnSchema.windingsRealkV]: x})}
                    InputProps={{
                        inputComponent: TextMaskNumber
                    }}
                    style={{width: "50px"}}
                />
                <Text style={{margin: "0 5px", display: "inline"}}>/</Text>
                <PalantirTextField
                    value={transformerSpec[TransformerSpec.columnSchema.windingsReactivekVAR]}
                    onChange={(x) => updateTransformerSpec({[TransformerSpec.columnSchema.windingsReactivekVAR]: x})}
                    InputProps={{
                        inputComponent: TextMaskNumber
                    }}
                    style={{width: "50px"}}
                />
                <Text style={{margin: "0 5px", display: "inline"}}>/</Text>
                <PalantirTextField
                    value={transformerSpec[TransformerSpec.columnSchema.windingsApparentkVA]}
                    onChange={(x) => updateTransformerSpec({[TransformerSpec.columnSchema.windingsApparentkVA]: x})}
                    InputProps={{
                        inputComponent: TextMaskNumber
                    }}
                    style={{width: "50px"}}
                />
            </div>
            <PalantirTextField
                label="Impedance [Z] (%)"
                value={transformerSpec[TransformerSpec.columnSchema.impedancePercent]}
                onChange={(x) => updateTransformerSpec({[TransformerSpec.columnSchema.impedancePercent]: x})}
                InputProps={{
                    inputComponent: TextMaskPercent
                }}
                
            />
            <PalantirTextField
                label="X/R Ratio"
                value={transformerSpec[TransformerSpec.columnSchema.XRRatio]}
                onChange={(x) => updateTransformerSpec({[TransformerSpec.columnSchema.XRRatio]: x})}
                InputProps={{
                    inputComponent: TextMaskNumber
                }}
                
            />
        </div>
    )
}