import './OandM.css'
import React, { useState } from 'react'

import ViewPanel from '../../ViewPanel'
import { 
    PalantirSelector,
    PalantirDatePicker,
    PalantirToggle
} from '../../../../components/input/SelectPicker'
import { PalantirButton } from '../../../../components/button/Button'
import DialogContentText from '@mui/material/DialogContentText';
import { DialogProvider, useDialog } from '../../../../components/dialog/DialogProvider'
import ContractIdentification from '../ContractIdentification'
import ContractHeader from '../ContractHeader'
import ContractCounterparties from '../ContractCounterparties'
import { useDBViewFormValidation } from '../../../../hooks/databaseViewFormValidation'
import { PalantirTextField, PalantirDispatchedTextField, makeTextMaskNumber, makeTextMaskPercentage } from '../../../../components/input/Text'
import { OandMCounterpartiesContract, OandMContract, OandMContractFeeSchedule, OandMContractScopes } from '../../../table_configuration/Contract'
import { OandM } from '../../../table_configuration/Counterparty'
import { useChangeLog, injectedChangeLogIdCol } from '../../../../hooks/changeLog'
import { AddFloaterButtonWithPrompt, DeleteFloaterButtonWithPrompt } from '../../../../components/button/FloaterButtonWithPrompt'
import { prepareContractRecords, prepareContractCounterpartyRecords, validateCreateContractCounterparty, buildContractNameErrorConfig, buildContractDependencyErrorPath } from '../utils'
import { generateUUID, ErrorRND, RNDErrorInstance, ViewFormError } from '../../../../utils/databaseAppUtils'
import Alerter from '../../../../components/alerter/Alerter'

import _ from 'lodash'


const injectedOandmName = "oandmName"

const TextMaskTermYears = makeTextMaskNumber({
    min: 0
})
const TextMaskFeeScheduleYear = makeTextMaskNumber({
    min: 1
})
const TextMaskFeeSchedulePricing = makeTextMaskNumber({
    min: 0
})
const TextMaskStaticFee = makeTextMaskNumber({
    
})
const TextMaskPercentage = makeTextMaskPercentage()

const iterOandmContractScopes = (callback, scopeRecords) => {
    OandMContractScopes.options.domains.forEach(domainOption => {
        let domainValue = domainOption.value
        let domainScopeOptions = OandMContractScopes.options.scopes[domainValue] || []
        domainScopeOptions.forEach(scopeOption => {
            let scopeValue = scopeOption.value
            let matchingScopeRecord = scopeRecords.find(record => 
                record[OandMContractScopes.columnSchema.domain]===domainValue &&
                record[OandMContractScopes.columnSchema.scope]===scopeValue
            )
            callback(matchingScopeRecord, domainValue, scopeValue)
        })
    })
}

const createScopeRecord = (contractId, domain, scope, coverage) => {
    var scopeRecord = OandMContractScopes.buildNewRecord()
    scopeRecord[OandMContractScopes.columnSchema.contractId] = contractId
    scopeRecord[OandMContractScopes.columnSchema.domain] = domain
    scopeRecord[OandMContractScopes.columnSchema.scope] = scope
    scopeRecord[OandMContractScopes.columnSchema.coverage] = coverage
    return scopeRecord
}


export default function OandMPanelProxy(props) {

    const contractRecords = props.data[OandMContract.buildId()]
    const contractFeeScheduleRecords = props.data[OandMContractFeeSchedule.buildId()]
    const contractScopeRecords = props.data[OandMContractScopes.buildId()]
    const contractCounterpartyRecords = props.data[OandMCounterpartiesContract.buildId()]
    const oandmRecords = props.data[OandM.buildId()]

    const [filteredContractRecords, filteredContractIds] = prepareContractRecords(contractRecords, props.selectedPlantId, OandMContract.columnSchema.contractId, OandMContract.columnSchema.plantId)
    const filteredContractCounterparties = prepareContractCounterpartyRecords(
        filteredContractIds, contractCounterpartyRecords, oandmRecords,
        OandMCounterpartiesContract.columnSchema.contractId, OandMCounterpartiesContract.columnSchema.oandmId, injectedOandmName
    )
    const filteredContractFeeSchedules = contractFeeScheduleRecords.filter(x => filteredContractIds.includes(x[OandMContractFeeSchedule.columnSchema.contractId]))
    const filteredContractScopes = contractScopeRecords.filter(x => filteredContractIds.includes(x[OandMContractScopes.columnSchema.contractId]))

    return (
        <OandMPanel
            selectedPlantId={props.selectedPlantId}
            originalOandmContractRecords={filteredContractRecords}
            oandmRecords={oandmRecords}
            originalContractFeeScheduleRecords={filteredContractFeeSchedules}
            originalContractScopeRecords={filteredContractScopes}
            originalOandmContractCounterpartyRecords={filteredContractCounterparties}
            validateCreateContractCounterparty={(record, newRecord) => validateCreateContractCounterparty(OandMCounterpartiesContract, OandMCounterpartiesContract.columnSchema.oandmId, record, newRecord)}
            {...props}
        />
    )

}

function OandMPanel({selectedPlantId, originalOandmContractRecords, oandmRecords, originalContractFeeScheduleRecords, originalContractScopeRecords, originalOandmContractCounterpartyRecords, validateCreateContractCounterparty, ...props}) {

    const [
        oandmContractRecords, prepareContractLog,
        updateContractLog, addToContractLog, deleteFromContractLog, bulkOpOnContractLog, mergeAndResetContractLog
    ] = useChangeLog(originalOandmContractRecords, OandMContract)
    const [
        oandmContractCounterpartiesRecords, prepareCounterpartyLog,
        updateCounterpartyLog, addToCounterpartyLog, deleteFromCounterpartyLog, bulkOpOnCounterpartyLog, mergeAndResetCounterpartyLog
    ] = useChangeLog(originalOandmContractCounterpartyRecords, OandMCounterpartiesContract, validateCreateContractCounterparty)
    const [
        oandmContractFeeScheduleRecords, prepareFeeScheduleLog,
        updateFeeScheduleLog, addToFeeScheduleLog, deleteFromFeeScheduleLog, bulkOpOnFeeScheduleLog, mergeAndResetFeeScheduleLog
    ] = useChangeLog(originalContractFeeScheduleRecords, OandMContractFeeSchedule)
    const [
        oandmContractScopeRecords, prepareScopeLog,
        updateScopeLog, addToScopeLog, deleteFromScopeLog, bulkOpOnScopeLog, mergeAndResetScopeLog
    ] = useChangeLog(originalContractScopeRecords, OandMContractScopes)
    const [errors, addErrors, removeErrors, setErrors, verifyChangelogSubmission, resetErrors] = useDBViewFormValidation()

    const addToContractLogProxy = () => {
        let contract = OandMContract.buildNewRecord()
        contract[OandMContract.columnSchema.contractId] = generateUUID()
        contract[OandMContract.columnSchema.plantId] = selectedPlantId
        addToContractLog(contract)
    }
    const deleteFromContractLogProxy = (oandmContract) => {
        // Delete all counterparty and fee schedule records associated with this contract, if any
        const contractId = oandmContract[OandMContract.columnSchema.contractId]
        const counterpartiesInDeletedContract = oandmContractCounterpartiesRecords.filter(record => record[OandMCounterpartiesContract.columnSchema.contractId]===contractId)
        const feeSchedulesInDeletedContract = oandmContractFeeScheduleRecords.filter(record => record[OandMContractFeeSchedule.columnSchema.contractId]===contractId)
        const scopesInDeletedContract = oandmContractScopeRecords.filter(record => record[OandMContractScopes.columnSchema.contractId]===contractId)
        deleteFromCounterpartyLog(counterpartiesInDeletedContract)
        deleteFromFeeScheduleLog(feeSchedulesInDeletedContract)
        deleteFromScopeLog(scopesInDeletedContract)
        deleteFromContractLog(oandmContract)
        removeErrors([contractId])
    }

    const addToCounterpartyLogProxy = (contractId) => {
        var contractCounterparty = OandMCounterpartiesContract.buildNewRecord()
        contractCounterparty[OandMCounterpartiesContract.columnSchema.contractId] = contractId
        addToCounterpartyLog(contractCounterparty)
    }
    const addToFeeScheduleLogProxy = (contractId) => {
        var schedule = OandMContractFeeSchedule.buildNewRecord()
        schedule[OandMContractFeeSchedule.columnSchema.contractId] = contractId
        addToFeeScheduleLog(schedule)
    }

    const oandmContractComponents = oandmContractRecords.map(oandmContract => {
        const contractId = oandmContract[OandMContract.columnSchema.contractId]
        const oandmCounterparties = oandmContractCounterpartiesRecords.filter(x => x[OandMCounterpartiesContract.columnSchema.contractId]===contractId)
        const oandmFeeSchedules = oandmContractFeeScheduleRecords.filter(x => x[OandMContractFeeSchedule.columnSchema.contractId]===contractId)
        const oandmScopes = oandmContractScopeRecords.filter(x => x[OandMContractScopes.columnSchema.contractId]===contractId)
        return (
            <OandMContractForm
                key={contractId}
                oandmContract={oandmContract}
                updateContract={(update) => updateContractLog(oandmContract, update)}
                deleteFromContractLog={() => deleteFromContractLogProxy(oandmContract)}

                allOandmCounterparties={oandmRecords}
                oandmCounterparties={oandmCounterparties}
                updateContractCounterparty={updateCounterpartyLog}
                addToCounterpartyLog={() => addToCounterpartyLogProxy(contractId)}
                deleteFromCounterpartyLog={deleteFromCounterpartyLog}

                feeSchedules={oandmFeeSchedules}
                updateFeeSchedule={updateFeeScheduleLog}
                addToFeeSchedule={() => addToFeeScheduleLogProxy(contractId)}
                deleteFromFeeScheduleLog={deleteFromFeeScheduleLog}
                
                oandmScopes={oandmScopes}
                updateOandmScopes={updateScopeLog}
                addToOandmScopeLog={addToScopeLog}
                bulkOpOnScopeLog={bulkOpOnScopeLog}

                errors={errors}
                removeErrors={removeErrors}
            />
        )
    })

    return (
        <ViewPanel
            services={props.services}
            title="O&M Contract"
            submitUrl='/api/precious/table/oandm_contract'
            verifySubmit={(payload) => {

                const newErrors = new ErrorRND()

                const verifyAllScopesPresent = (contractId, records) => {
                    const callback = (matchingScopeRecord, domain, scope) => {
                        // Error path uses domain and scope because in some cases the scope records are not created yet so they don't have an internal changelog id.
                        if (!matchingScopeRecord) newErrors.add(new RNDErrorInstance("Please select a coverage option."), contractId, OandMContractScopes.buildId(), domain, scope)
                    }
                    iterOandmContractScopes(callback, records)
                }

                // Ensure all contract scopes are present
                let uniqueContractIds = oandmContractRecords.map(x => x[OandMContract.columnSchema.contractId])
                uniqueContractIds.forEach(contractId => {
                    let filteredContractScopes = oandmContractScopeRecords.filter(x => x[OandMContractScopes.columnSchema.contractId]===contractId)
                    verifyAllScopesPresent(contractId, filteredContractScopes)
                })
                if (!newErrors.empty()) {
                    setErrors(newErrors)
                    throw new ViewFormError("Please ensure all scopes are filled out before submitting.")
                }

                return verifyChangelogSubmission(
                    {
                        changeLog: payload.oandmContractChangelog,
                        checks: [buildContractNameErrorConfig(OandMContract)]
                    }, {
                        changeLog: payload.oandmContractFeeScheduleChangelog,
                        checks: [{
                            checkColumn: OandMContractFeeSchedule.columnSchema.startYear,
                            checkFunction: "nullCheck",
                            errMessage: "Please select a start year.",
                            path: buildContractDependencyErrorPath(OandMContractFeeSchedule, OandMContractFeeSchedule.columnSchema.startYear), 
                        }, {
                            checkColumn: OandMContractFeeSchedule.columnSchema.endYear,
                            checkFunction: "nullCheck",
                            errMessage: "Please select an end year.",
                            path: buildContractDependencyErrorPath(OandMContractFeeSchedule, OandMContractFeeSchedule.columnSchema.endYear), 
                        }, {
                            checkColumn: OandMContractFeeSchedule.columnSchema.fee,
                            checkFunction: "nullCheck",
                            errMessage: "Please select a fee.",
                            path: buildContractDependencyErrorPath(OandMContractFeeSchedule, OandMContractFeeSchedule.columnSchema.fee), 
                        }
                            /*, {
                            checkColumn: null,
                            checkFunction: (record) => {
                                // validate the year fields
                            },
                            errMessage: "Start year must be less than end year.",
                            path: [
                                {name: OandMContractFeeSchedule.columnSchema.contractId, type: "eval"},
                                {name: OandMContractFeeSchedule.buildId(), type: "static"},
                                {name: OandMContractFeeSchedule.columnSchema.startYear, type: "static"},
                                {name: injectedChangeLogIdCol, type: "eval"}
                            ]
                        }*/]
                    }, {
                        changeLog: payload.oandmContractScopeChangelog,
                        checks: [{
                            checkColumn: OandMContractScopes.columnSchema.coverage,
                            checkFunction: "nullCheck",
                            errMessage: "Please select a coverage option.",
                            path: [
                                {name: OandMContractScopes.columnSchema.contractId, type: "eval"},
                                {name: OandMContractScopes.buildId(), type: "static"},
                                {name: OandMContractScopes.columnSchema.domain, type: "eval"},
                                {name: OandMContractScopes.columnSchema.scope, type: "eval"},
                            ]
                        }]
                    }, {
                        changeLog: payload.oandmContractCounterpartyChangelog,
                        checks: [{
                            checkColumn: OandMCounterpartiesContract.columnSchema.oandmId,
                            checkFunction: "nullCheck",
                            errMessage: "Please select a counterparty.",
                            path: buildContractDependencyErrorPath(OandMCounterpartiesContract, OandMCounterpartiesContract.columnSchema.oandmId),  
                        }]
                    }
                )
            }}
            onSubmitSuccess={(response, requestPayload) => {
                props.handleUpdate(false)
                resetErrors()
                mergeAndResetContractLog()
                mergeAndResetFeeScheduleLog()
                mergeAndResetScopeLog()
                mergeAndResetCounterpartyLog()
            }}
            onSubmitError={null}
            buildSubmitPayload={() => {
                return {
                    oandmContractChangelog: prepareContractLog(),
                    oandmContractFeeScheduleChangelog: prepareFeeScheduleLog(),
                    oandmContractScopeChangelog: prepareScopeLog(),
                    oandmContractCounterpartyChangelog: prepareCounterpartyLog(),
                }
            }}
        >
            <div style={{minWidth: "950px"}}>
                {oandmContractComponents}
                <AddFloaterButtonWithPrompt
                    onClick={addToContractLogProxy}
                    labelContent="Add new contract"
                    height={18}
                    width={18}
                />
            </div>
        </ViewPanel>
    )
}

const OandMContractForm = React.memo(function({
    oandmContract, updateContract, deleteFromContractLog, 
    allOandmCounterparties, oandmCounterparties, updateContractCounterparty, addToCounterpartyLog, deleteFromCounterpartyLog,
    feeSchedules, updateFeeSchedule, addToFeeSchedule, deleteFromFeeScheduleLog, 
    oandmScopes, updateOandmScopes, addToOandmScopeLog, bulkOpOnScopeLog,
    errors, removeErrors
}) {
    
    return (
        <div className="field-group form-instance project-equipment">
            <ContractHeader
                contractName={oandmContract[OandMContract.columnSchema.contractName]}
                deleteFromContractLog={deleteFromContractLog}
            />
            <ContractIdentification
                ContractTable={OandMContract}
                contract={oandmContract}
                contractIdCol={OandMContract.columnSchema.contractId}
                contractNameCol={OandMContract.columnSchema.contractName}
                contractPlantIdCol={OandMContract.columnSchema.plantId}
                onContractNameChange={(x) => updateContract({[OandMContract.columnSchema.contractName]: x})}
                errors={errors}
                removeErrors={removeErrors}
            />
            <div className="flow-horizontal" style={{border: "solid green 0px", padding: "0px", flexWrap: "wrap"}} >
                <ContractCounterparties
                    ContractCounterpartyTable={OandMCounterpartiesContract}
                    contract={oandmContract}
                    contractIdCol={OandMContract.columnSchema.contractId}
                    contractCounterpartyIdCol={OandMCounterpartiesContract.columnSchema.oandmId}
                    contractCounterpartyNameCol={injectedOandmName}
                    contractCounterpartyNotesCol={OandMCounterpartiesContract.columnSchema.notes}
                    allCounterpartyRecords={allOandmCounterparties}
                    filteredContractCounterpartyRecords={oandmCounterparties}
                    deleteFromCounterpartyLog={deleteFromCounterpartyLog}
                    addToCounterpartyLog={addToCounterpartyLog}
                    updateContractCounterparty={updateContractCounterparty}
                    counterpartyType={"O&M"}
                    counterpartyTypePlural={"O&Ms"}
                    orientation="horizontal"
                    errors={errors}
                    removeErrors={removeErrors}
                    style={{paddingBottom: "20px"}}
                />
                <div className="flow-vertical" style={{flexGrow: 1, flexShrink: 1, minWidth: "400px", marginBottom: "20px"}}>
                    <div className="header">Notes</div>
                    <PalantirDispatchedTextField
                        label="Notes"
                        value={oandmContract[OandMContract.columnSchema.notes]}
                        multiline
                        rows={4}
                        fullWidth
                        variant="filled"
                        onChange={(x) => updateContract({[OandMContract.columnSchema.notes]: x})}
                    />
                </div>
            </div>
            <DialogProvider>
                <ContractFee
                    contractId={oandmContract[OandMContract.columnSchema.contractId]}
                    contractFeeStructure={oandmContract[OandMContract.columnSchema.feeStructure]}
                    contractPaymentTerms={oandmContract[OandMContract.columnSchema.paymentTerms]}
                    contractPaymentFrequency={oandmContract[OandMContract.columnSchema.paymentFrequency]}
                    contractFee={oandmContract[OandMContract.columnSchema.fee]}
                    contractAnnualEscalationRate={oandmContract[OandMContract.columnSchema.annualEscalationRate]}
                    contractEscalationDate={oandmContract[OandMContract.columnSchema.escalationDate]}
                    contractEscalationLanguage={oandmContract[OandMContract.columnSchema.escalationLanguage]}
                    updateContract={updateContract}
                    feeSchedules={feeSchedules}
                    updateFeeSchedule={updateFeeSchedule}
                    addToFeeSchedule={addToFeeSchedule}
                    deleteFromFeeScheduleLog={deleteFromFeeScheduleLog}
                    errors={errors}
                    removeErrors={removeErrors}
                />
            </DialogProvider>
            <div className="header">Contract Details</div>
            <div className="flow-horizontal contract-metadata-section">
                <div className="flow-vertical vertical-children-spacing" style={{minWidth: "220px"}}>
                    <PalantirSelector 
                        label="Status"
                        value={oandmContract[OandMContract.columnSchema.status]}
                        items={OandMContract.options.status}
                        onChange={(x) => updateContract({[OandMContract.columnSchema.status]: x})}
                    />
                    <PalantirDatePicker
                        label="Term Start Date"
                        value={oandmContract[OandMContract.columnSchema.termStartDate]}
                        onChange={(date) => updateContract({[OandMContract.columnSchema.termStartDate]: date})}
                    />
                    <PalantirDatePicker
                        label="Term End Date"
                        value={oandmContract[OandMContract.columnSchema.termEndDate]}
                        onChange={(date) => updateContract({[OandMContract.columnSchema.termEndDate]: date})}
                    />
                    <PalantirTextField
                        label="Term (Years)"
                        value={oandmContract[OandMContract.columnSchema.termYears]}
                        InputProps={{
                            inputComponent: TextMaskTermYears
                        }}
                        onChange={(x) => updateContract({[OandMContract.columnSchema.termYears]: x})}
                    />
                </div>
                <div className="flow-vertical vertical-children-spacing" style={{minWidth: "300px"}}>
                    <PalantirDispatchedTextField
                        label="Termination Clause"
                        value={oandmContract[OandMContract.columnSchema.terminationClause]}
                        onChange={(x) => updateContract({[OandMContract.columnSchema.terminationClause]: x})}
                    />
                    <PalantirDispatchedTextField
                        label="Termination Fees"
                        value={oandmContract[OandMContract.columnSchema.terminationFees]}
                        onChange={(x) => updateContract({[OandMContract.columnSchema.terminationFees]: x})}
                    />
                    <PalantirDispatchedTextField
                        label="Termination Notice Requirements"
                        value={oandmContract[OandMContract.columnSchema.terminationNoticeRequirements]}
                        onChange={(x) => updateContract({[OandMContract.columnSchema.terminationNoticeRequirements]: x})}
                        multiline
                        rows={3}
                        variant="filled"
                    />
                </div>
                <div className="flow-vertical vertical-children-spacing" style={{minWidth: "300px"}}>
                    <PalantirDispatchedTextField
                        label="Limit of Liability"
                        value={oandmContract[OandMContract.columnSchema.limitOfLiability]}
                        onChange={(x) => updateContract({[OandMContract.columnSchema.limitOfLiability]: x})}
                    />
                    <PalantirTextField
                        label="Guaranteed Availability (%)"
                        value={oandmContract[OandMContract.columnSchema.guaranteedAvailability]}
                        onChange={(x) => updateContract({[OandMContract.columnSchema.guaranteedAvailability]: x})}
                        InputProps={{
                            inputComponent: TextMaskPercentage
                        }}
                    />
                    <PalantirDispatchedTextField
                        label="Liquidated Damages"
                        value={oandmContract[OandMContract.columnSchema.liquidatedDamages]}
                        onChange={(x) => updateContract({[OandMContract.columnSchema.liquidatedDamages]: x})}
                        multiline
                        rows={3}
                        variant="filled"
                    />
                </div>
                <div className="flow-vertical vertical-children-spacing">
                    <div>
                        <PalantirDispatchedTextField
                            label="Sharepoint Folder Link"
                            value={oandmContract[OandMContract.columnSchema.sharepointLink]}
                            helperText="This should be the folder link, not the file."
                            onChange={(x) => updateContract({[OandMContract.columnSchema.sharepointLink]: x})}
                        />
                        <div style={{marginTop: "4px"}}><a href={oandmContract[OandMContract.columnSchema.sharepointLink]} target="_blank">Click here to visit Sharepoint link</a></div>
                    </div>
                    <PalantirTextField
                        label="Options to Extend"
                        value={oandmContract[OandMContract.columnSchema.optionsToExtend]}
                        onChange={(x) => updateContract({[OandMContract.columnSchema.optionsToExtend]: x})}
                        multiline
                        rows={3}
                        variant="filled"
                        style={{flexGrow: 1}}
                    />
                </div>    
            </div>
            <Scopes
                contractId={oandmContract[OandMContract.columnSchema.contractId]}
                oandmScopes={oandmScopes}
                updateOandmScopes={updateOandmScopes}
                addToOandmScopeLog={addToOandmScopeLog}
                bulkOpOnScopeLog={bulkOpOnScopeLog}
                contractAdditionalFeeServiceAdder={oandmContract[OandMContract.columnSchema.additionalFeeServiceAdder]}
                updateContract={updateContract}
                errors={errors}
                removeErrors={removeErrors}
            />
        </div>
    )
}, (prevProps, nextProps) => {
    return (
        _.isEqual(prevProps.oandmContract, nextProps.oandmContract) &&
        _.isEqual(prevProps.oandmCounterparties, nextProps.oandmCounterparties) &&
        _.isEqual(prevProps.feeSchedules, nextProps.feeSchedules) &&
        _.isEqual(prevProps.oandmScopes, nextProps.oandmScopes) &&
        _.isEqual(prevProps.errors, nextProps.errors)
    )
})

const ContractFee = React.memo(function ({
    contractId, contractFeeStructure, contractPaymentTerms, contractPaymentFrequency, contractFee, contractAnnualEscalationRate, contractEscalationDate, contractEscalationLanguage,
    updateContract, feeSchedules, updateFeeSchedule, addToFeeSchedule, deleteFromFeeScheduleLog, errors, removeErrors
}) {

    const [openDialog, closeDialog] = useDialog();

    const findFeeStructureType = (type) => OandMContract.options.feeStructure.find(x => x.value===type).value
    const feeStructureScheduleValue = findFeeStructureType("Schedule")
    const feeStructureFixedValue = findFeeStructureType("Fixed")

    var contractFeeComponent = null
    if (contractFeeStructure===feeStructureFixedValue) {
        contractFeeComponent = (
            <div className="flow-horizontal" style={{flexGrow: 1, flexWrap: "wrap"}}>
                <div className="flow-vertical" style={{ paddingRight: "20px"}}>
                    <PalantirTextField
                        label="Fee ($ / kW)"
                        value={contractFee}
                        onChange={(x) => updateContract({[OandMContract.columnSchema.fee]: x})}
                        InputProps={{
                            inputComponent: TextMaskStaticFee
                        }}
                    />
                    <PalantirTextField
                        label="Annual Escalation Rate (%)"
                        value={contractAnnualEscalationRate}
                        onChange={(x) => updateContract({[OandMContract.columnSchema.annualEscalationRate]: x})}
                        InputProps={{
                            inputComponent: TextMaskPercentage
                        }}
                        style={{marginTop: "20px"}}
                    />
                    <PalantirDatePicker
                        label="Escalation Date"
                        value={contractEscalationDate}
                        onChange={(x) => updateContract({[OandMContract.columnSchema.escalationDate]: x})}
                        style={{marginTop: "20px"}}
                    />
                </div>
                <PalantirDispatchedTextField
                    label="Escalation Language"
                    value={contractEscalationLanguage}
                    onChange={(x) => updateContract({[OandMContract.columnSchema.escalationLanguage]: x})}
                    multiline
                    rows={6}
                    variant="filled"
                    style={{minWidth: "100px", marginTop: "12px", flexShrink: 1, flexGrow: 1}}
                />
            </div>
        )
    }
    else if (contractFeeStructure===feeStructureScheduleValue) {
        const feeScheduleComponents = feeSchedules.map(schedule => {

            const startYear = schedule[OandMContractFeeSchedule.columnSchema.startYear]
            const endYear = schedule[OandMContractFeeSchedule.columnSchema.endYear]
            const pricing = schedule[OandMContractFeeSchedule.columnSchema.fee]
            const notes = schedule[OandMContractFeeSchedule.columnSchema.notes]
            const scheduleInjectedChangeLogId = schedule[injectedChangeLogIdCol]

            const startYearErrorPath = [contractId, OandMContractFeeSchedule.buildId(), scheduleInjectedChangeLogId, OandMContractFeeSchedule.columnSchema.startYear]
            const endYearErrorPath = [contractId, OandMContractFeeSchedule.buildId(), scheduleInjectedChangeLogId, OandMContractFeeSchedule.columnSchema.endYear]
            const pricingErrorPath = [contractId, OandMContractFeeSchedule.buildId(), scheduleInjectedChangeLogId, OandMContractFeeSchedule.columnSchema.fee]
            
            const startYearError = errors.get(...startYearErrorPath)
            const startYearErrorProps = startYearError ? {error: true, helperText: startYearError.getMessage()} : {}
            const endYearError = errors.get(...endYearErrorPath)
            const endYearErrorProps = endYearError ? {error: true, helperText: endYearError.getMessage()} : {}
            const pricingError = errors.get(...pricingErrorPath)
            const pricingErrorProps = pricingError ? {error: true, helperText: pricingError.getMessage()} : {}

            return (
                <div className="form-sub-instance" style={{minWidth: "220px", maxWidth: "500px", marginRight: "20px"}}>
                    <div>
                        <DeleteFloaterButtonWithPrompt
                            onClick={() => {
                                removeErrors(startYearErrorPath, endYearErrorPath, pricingErrorPath)
                                deleteFromFeeScheduleLog(schedule)
                            }}
                            style={{float: "right", marginTop: "4px"}}
                            height={14}
                            width={14}
                        />
                    </div>
                    <div style={{display: "flex", flexWrap: "wrap"}}>
                        <PalantirTextField
                            label="Start Year"
                            value={startYear}
                            onChange={(x) => {
                                removeErrors(startYearErrorPath)
                                updateFeeSchedule(schedule, {[OandMContractFeeSchedule.columnSchema.startYear]: x})
                            }}
                            InputProps={{
                                inputComponent: TextMaskFeeScheduleYear,
                            }}
                            style={{marginRight: "20px", marginBottom: "20px"}}
                            {...startYearErrorProps}
                        />
                        <PalantirTextField
                            label="End Year"
                            value={endYear}
                            onChange={(x) => {
                                removeErrors(endYearErrorPath)
                                updateFeeSchedule(schedule, {[OandMContractFeeSchedule.columnSchema.endYear]: x})
                            }}
                            InputProps={{
                                inputComponent: TextMaskFeeScheduleYear,
                            }}
                            style={{marginRight: "20px", marginBottom: "20px"}}
                            {...endYearErrorProps}
                        />
                    </div>
                    <div>
                        <PalantirTextField
                            label="Pricing ($)"
                            value={pricing}
                            onChange={(x) => {
                                removeErrors(pricingErrorPath)
                                updateFeeSchedule(schedule, {[OandMContractFeeSchedule.columnSchema.fee]: x})
                            }}
                            InputProps={{
                                inputComponent: TextMaskFeeSchedulePricing,
                            }}
                            style={{marginRight: "20px", marginBottom: "20px"}}
                            {...pricingErrorProps}
                        />
                        <PalantirDispatchedTextField
                            label="Notes"
                            value={notes}
                            onChange={(x) => updateFeeSchedule(schedule, {[OandMContractFeeSchedule.columnSchema.notes]: x})}
                            variant="filled"
                            style={{marginRight: "20px", marginBottom: "20px"}}
                        />
                    </div>
                </div>
            )
        })
        contractFeeComponent = (
            <div>
                <div style={{marginBottom: "20px"}}>
                    <p>*A fee schedule allows you to add dynamic pricing to a contract depending on time elapsed relative to the contract start date.</p>
                    <p>The start and end represent the relative number of years into the contract.
                    Eg. you can set pricing to x for years 1-5 and y for years 6-100.</p>
                </div>
                <div style={{display: "flex", flexWrap: "wrap"}}>
                    {feeScheduleComponents}
                    <AddFloaterButtonWithPrompt
                        onClick={addToFeeSchedule}
                        labelContent={"Add new schedule"}
                        height={14}
                        width={14}
                        labelStyle={{fontSize: "10px"}}
                    />
                </div>
            </div>
        )
    }
    else if (contractFeeStructure===null) {
        contractFeeComponent = <div style={{fontSize: "12px", marginTop: "20px"}}>*Please select a contract fee structure to view options.</div>
    }
    else {
        contractFeeComponent = <div style={{fontSize: "12px", marginTop: "20px"}}>Contract has an unrecognizable fee structure: '{contractFeeStructure}'.</div>
    }

    return (
        <div style={{marginBottom: "20px"}}>
            <div className="header">Contract Fees</div>
            <div className="flow-horizontal" style={{flexWrap: "nowrap"}}>
                <div className="flow-vertical" style={{minWidth: "180px", marginRight: "50px", marginBottom: "20px"}}>
                    <PalantirSelector 
                        label="Fee Structure"
                        value={contractFeeStructure}
                        items={OandMContract.options.feeStructure}
                        onChange={(newFeeStructure) => {
                            // Change from schedule to fixed will delete all schedules
                            if (feeSchedules.length > 0 && contractFeeStructure===feeStructureScheduleValue && newFeeStructure==feeStructureFixedValue) {
                                openDialog({
                                    title: "Really?",
                                    body: <DialogContentText>Are you sure you want to change the fee structure to a Fixed model? Doing so will remove any fee schedules attached to this contract.</DialogContentText>,
                                    onAccept: () => {
                                        closeDialog()
                                        removeErrors(contractId, OandMContractFeeSchedule.buildId())
                                        deleteFromFeeScheduleLog(feeSchedules)
                                        updateContract({[OandMContract.columnSchema.feeStructure]: newFeeStructure})
                                    }
                                });
                            }
                            // Change from fixed to schedule will delete specified fields
                            else if (
                                contractFeeStructure===feeStructureFixedValue && newFeeStructure==feeStructureScheduleValue &&
                                (contractFee || contractAnnualEscalationRate || contractEscalationDate || contractEscalationLanguage)
                            ) {
                                openDialog({
                                    title: "Really?",
                                    body: <DialogContentText>Are you sure you want to change the fee structure to a Schedule model? Doing so will remove all fixed fee data attached to this contract.</DialogContentText>,
                                    onAccept: () => {
                                        closeDialog()
                                        updateContract({
                                            [OandMContract.columnSchema.feeStructure]: newFeeStructure,
                                            [OandMContract.columnSchema.fee]: null,
                                            [OandMContract.columnSchema.annualEscalationRate]: null,
                                            [OandMContract.columnSchema.escalationDate]: null,
                                            [OandMContract.columnSchema.escalationLanguage]: null
                                        })
                                    }
                                });
                            }
                            else {
                                updateContract({[OandMContract.columnSchema.feeStructure]: newFeeStructure})
                            }
                        }}
                        style={{marginBottom: "20px"}}
                    />
                    <PalantirSelector 
                        label="Payment Terms"
                        value={contractPaymentTerms}
                        items={OandMContract.options.paymentTerms}
                        onChange={(newPaymentTerms) => updateContract({[OandMContract.columnSchema.paymentTerms]: newPaymentTerms})}
                        style={{marginBottom: "20px"}}
                    />
                    <PalantirSelector 
                        label="Payment Frequency"
                        value={contractPaymentFrequency}
                        items={OandMContract.options.paymentFrequency}
                        onChange={(newPaymentFrequency) => updateContract({[OandMContract.columnSchema.paymentFrequency]: newPaymentFrequency})}
                    />
                </div>
                {contractFeeComponent}    
            </div>
        </div>
    )
}, (prevProps, nextProps) => {
    return (
        prevProps.contractId===nextProps.contractId &&
        prevProps.contractFeeStructure===nextProps.contractFeeStructure &&
        prevProps.contractPaymentTerms===nextProps.contractPaymentTerms &&
        prevProps.contractPaymentFrequency===nextProps.contractPaymentFrequency &&
        prevProps.contractFee===nextProps.contractFee &&
        prevProps.contractAnnualEscalationRate===nextProps.contractAnnualEscalationRate &&
        prevProps.contractEscalationDate===nextProps.contractEscalationDate &&
        prevProps.contractEscalationLanguage===nextProps.contractEscalationLanguage &&
        _.isEqual(prevProps.feeSchedules, nextProps.feeSchedules) &&
        _.isEqual(prevProps.errors, nextProps.errors)
    )
})

const Scopes = React.memo(function ({contractId, oandmScopes, updateOandmScopes, addToOandmScopeLog, bulkOpOnScopeLog, contractAdditionalFeeServiceAdder, updateContract, errors, removeErrors}) {

    const [applyToExistingScopes, setApplyToExistingScopes] = useState(false)
    const [defaultCoverageApplyAll, setDefaultCoverageApplyAll] = useState(() => OandMContractScopes.options.coverage.find(x => x.value==="Covered").value)

    const domainOptions = OandMContractScopes.options.domains
    const scopeOptionsByDomain = OandMContractScopes.options.scopes

    const domainGroupedScopeComponents = domainOptions.map(domainOptions => {
        let domain = domainOptions.value
        let domainScopeOptions = scopeOptionsByDomain[domain]

        const domainScopes = domainScopeOptions.map(scopeOption => {
            let scope = scopeOption.value
            let matchingScopeRecord = oandmScopes.find(scopeRecord => scopeRecord[OandMContractScopes.columnSchema.domain]===domain && scopeRecord[OandMContractScopes.columnSchema.scope]===scope)
            let updateScope;   // callback function triggered on scope record change

            let scopeErrorPath = [contractId, OandMContractScopes.buildId(), domain, scope]
            let scopeError = errors.get(...scopeErrorPath)
            let scopeErrorProps = scopeError ? {helperText: scopeError.getMessage(), error: true} : {}

           /**
            * Removes scope coverage errors if coverage field is present in scopeRecord.
            * @param {Object} scopeRecord an updated scope record (or subset of the record fields) to validate presence of coverage field.
            */
            const tryRemoveScopeErrors = (scopeRecord) => {
                const coverage = scopeRecord[OandMContractScopes.columnSchema.coverage]
                if (coverage) {
                    removeErrors(scopeErrorPath)
                }
            }

            // If there is no matching scope we should trigger a creation on update instead of an update
            if (!matchingScopeRecord) {
                // This is a temporary record that is just used for displaying the prefilled fields. The final record is created in the callback below when the user updates this record.
                matchingScopeRecord = createScopeRecord(contractId, domain, scope, null)

                updateScope = (newScope) => {
                    const newScopeRecord = Object.assign(matchingScopeRecord, newScope)
                    tryRemoveScopeErrors(newScopeRecord)
                    addToOandmScopeLog(newScopeRecord)
                }
            }
            else {
                updateScope = (newScope) => {
                    tryRemoveScopeErrors(newScope)
                    updateOandmScopes(matchingScopeRecord, newScope)
                }
            }

            return (
                <Scope
                    key={scope}
                    scopeRecord={matchingScopeRecord}
                    updateScope={updateScope}
                    scopeErrorProps={scopeErrorProps}
                />
            )
        })

        return (
            <div key={domain} style={{display: "flex", flexWrap: "wrap", flexDirection: "column", flexGrow: 1}}>
                {domainScopes}
            </div>
        )
    })

    return (
        <div>
            <div className="header">Scopes</div>
            <div style={{padding: "20px"}}>
                <div className="flow-horizontal" style={{justifyContent: "space-between", marginBottom: "30px"}}>
                    <div style={{paddingRight: "20px"}}>
                        <PalantirTextField
                            label="Additional Fee Service Adder (%)"
                            value={contractAdditionalFeeServiceAdder}
                            onChange={(x) => updateContract({[OandMContract.columnSchema.additionalFeeServiceAdder]: x})}
                            InputProps={{
                                inputComponent: TextMaskPercentage
                            }}
                            style={{width: "250px"}}
                        />
                        <p>*The additional fee service is considered applicable to each scope with a coverage option of 'Additional Fee'.</p>    
                    </div>
                    <div>
                        <div style={{display: "flex", flexWrap: "nowrap"}}>
                            <PalantirButton
                                onClick={() => {
                                    const scopeLogInserts = []
                                    const scopeLogDeletes = []
                                    const scopeErrorPathsToRemove = []
                                    const callback = (matchingScopeRecord, domain, scope) => {
                                        if (!matchingScopeRecord) {
                                            scopeLogInserts.push(createScopeRecord(contractId, domain, scope, defaultCoverageApplyAll))
                                            scopeErrorPathsToRemove.push([contractId, OandMContractScopes.buildId(), domain, scope])
                                        }
                                        else if (applyToExistingScopes) {
                                            const newScopeRecord = createScopeRecord(contractId, domain, scope, defaultCoverageApplyAll)
                                            // If the new record is the same as previous we don't have to drop and recreate it
                                            const matchingScopeRecordNoChangelogId = Object.assign({}, matchingScopeRecord)
                                            delete matchingScopeRecordNoChangelogId[injectedChangeLogIdCol]
                                            if (!_.isEqual(matchingScopeRecordNoChangelogId, newScopeRecord)) {
                                                scopeLogDeletes.push(matchingScopeRecord)   // should probably be updating here instead of deleting and recreating but updateMany is not implemented
                                                scopeLogInserts.push(newScopeRecord)
                                            }
                                        }
                                    }
                                    iterOandmContractScopes(callback, oandmScopes)
                                    // Only want to perform updates if inserts are present
                                    // Otherwise you could end up deleting records but not inserting any (eg. when override existing scopes is false, all scopes would be deleted but not recreated)
                                    if (scopeLogInserts.length > 0) {
                                        removeErrors(...scopeErrorPathsToRemove)
                                        bulkOpOnScopeLog([{
                                            method: "delete",
                                            changes: scopeLogDeletes
                                        }, {
                                            method: "insert",
                                            changes: scopeLogInserts
                                        }])
                                    }
                                    else {
                                        Alerter.inform("No scopes could be created. Likely all scopes already exist and the 'override existing scopes' option is disabled, or all scopes already contain the selected coverage option.", 8000)
                                    }
                                }}
                                style={{minWidth: "180px", marginRight: "10px"}}
                            >
                                Set all scopes to
                            </PalantirButton>
                            <PalantirSelector
                                value={defaultCoverageApplyAll}
                                items={OandMContractScopes.options.coverage}
                                onChange={(newCoverage) => setDefaultCoverageApplyAll(newCoverage)}
                                style={{marginTop: "7px"}}
                            />
                        </div>
                        <div>
                            <PalantirToggle
                                label="Override existing scopes"
                                checked={applyToExistingScopes}
                                onChange={(checked) => setApplyToExistingScopes(checked)}
                            />
                        </div>
                    </div>
                </div>
                <div className="flow-horizontal horizontal-children-spacing" style={{fontSize: "14px", marginBottom: "15px"}}>
                    <div style={{width: "180px", flexShrink: 1, borderBottom: "solid 1px grey"}}>Domain</div>
                    <div style={{width: "180px", flexShrink: 1, borderBottom: "solid 1px grey"}}>Scope</div>
                    <div style={{width: "180px", flexShrink: 1, borderBottom: "solid 1px grey"}}>Coverage</div>
                    <div style={{width: "180px", flexShrink: 1, borderBottom: "solid 1px grey", flexGrow: 1}}>Notes</div>
                </div>
                {domainGroupedScopeComponents}
            </div>
        </div>
    )
}, (prevProps, nextProps) => {
    return (
        _.isEqual(prevProps.oandmScopes, nextProps.oandmScopes) &&
        prevProps.contractAdditionalFeeServiceAdder===nextProps.contractAdditionalFeeServiceAdder &&
        _.isEqual(prevProps.errors, nextProps.errors)
    )
})

const Scope = ({scopeRecord, updateScope, scopeErrorProps}) => {

    const domain = scopeRecord[OandMContractScopes.columnSchema.domain]
    const scope = scopeRecord[OandMContractScopes.columnSchema.scope]
    const coverage = scopeRecord[OandMContractScopes.columnSchema.coverage]
    const notes = scopeRecord[OandMContractScopes.columnSchema.notes]

    return (
        <div className="flow-horizontal horizontal-children-spacing" style={{marginBottom: "20px", flexGrow: 1}}>
            <PalantirTextField 
                value={domain}
                disabled
            />
            <PalantirTextField 
                value={scope}
                disabled
            />
            <PalantirSelector
                value={coverage}
                items={OandMContractScopes.options.coverage}
                onChange={(newCoverage) => updateScope({[OandMContractScopes.columnSchema.coverage]: newCoverage})}
                error={scopeErrorProps.error}
                helperText={scopeErrorProps.helperText}
            />
            <PalantirDispatchedTextField
                value={notes}
                onChange={(x) => updateScope({[OandMContractScopes.columnSchema.notes]: x})}
                variant="standard"
                style={{flexGrow: 1}}
                disabled={!Boolean(coverage)}
            />
        </div>
    )
}