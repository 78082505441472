import React, { useEffect, useRef } from 'react'

import Panel from '../components/panel/Panel'
import { Plant } from './table_configuration/Project'
import { ViewConfig, PanelConfig, StandAlonePlantPanelConfig } from './views/ViewSchema'
import ViewSelection from './views/ViewSelection'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRefresh } from '@fortawesome/free-solid-svg-icons'


export default function ViewFormRenderer(props) {

    const updateFlag = useRef(false)

    // This forces data refresh anytime an update has been made and a new site is selected
    useEffect(() => {
        if (updateFlag.current) {
            handleRefresh()
        }
    })
    // refreshAssets should be set to true by callers when the plant or portfolio has been updated
    //    as this will affect the plant menu
    const handleUpdate = (refreshAssets) => {
        //updateFlag = true
        updateFlag.current = true
        if (refreshAssets) props.services.assets.refresh()
    }
    const handleRefresh = () => {
        //updateFlag = false
        updateFlag.current = false
        props.services.data.refresh()
    }

    var plant = props.data[Plant.buildId()].find(x => x.plant_id===props.selectedPlantId)
    //if (!plant) return <Panel>Please select a plant.</Panel>
    if (!plant && props.selectedView instanceof ViewConfig && !props.selectedView.allowNullProject) {
        return (
            <Panel>Please select a plant.</Panel>
        )
    }


    var panels;
    if (props.selectedView instanceof ViewConfig) {
        panels = props.selectedView.components
    }
    else if (props.selectedView instanceof PanelConfig) {
        panels = [props.selectedView]
        // Don't add selected panel if the same as default, otherwise same panel would be added twice
        if (props.selectedView?.component!==StandAlonePlantPanelConfig.component) {
            panels.push(StandAlonePlantPanelConfig)
        }
    }

    // View selection should remount when selected plant changes so it renders the panels for new plant.
    return (
        <div className="section-main flow-vertical fill-parent">
            <div style={{marginBottom: "10px"}}>
                <FontAwesomeIcon icon={faRefresh} style={{float: "right", marginRight: "20px"}} size="lg" onClick={handleRefresh} />
            </div>
            <ViewSelection
                key={`${props.selectedView.key} - ${props.selectedPlantId}`}
                panels={panels}
                selectedPlant={plant} 
                handleUpdate={handleUpdate} 
                {...props}
            />
        </div>
    );

}