import './Panel2.css'
import { Box, Menu, ScrollArea, Switch, Text } from '@mantine/core'
import { PalantirPopMenuItem, PalantirPopMenu } from '../menuv2/Menu'


export const Card = ({children, className, ...props}) => {
    let _className = "form-instance2"// flow-vertical"
    if (className) _className = _className+" "+className
    return (
        <div className={_className} {...props}>
            {children}
        </div>
    )
}
export const CardHeader = ({label, ...props}) => {
    return (
        <div className="header2 flow-horizontal">
            <CardHeaderLabel label={label} />
            <div className="fill-parent"></div>
            {props.children}
        </div>
    )
}
export const CardBody = ({flow="vertical", noOverflow, noPad, ...props}) => {
    let bodyClassName = "body"// flow-vertical fill-parent"
    if (noPad) bodyClassName+=" no-pad"
    let bodyLiningClassName = ""//`body-lining ${flow==="vertical" ? "flow-vertical" : "flow-horizontal"} fill-parent`

    //const ContainerComponent = noOverflow ? ({children, ...props}) => <div {...props}>{children}</div> : ScrollArea
    const ContainerComponent = noOverflow ? Box : ScrollArea

    return (
        <ContainerComponent className={bodyClassName}>
            <div className={bodyLiningClassName}>
                {props.children}
            </div>
        </ContainerComponent>
    )
}
export const CardFooter = ({...props}) => {
    return (
        <div className="footer flow-horizontal">
            {props.children}
        </div>
    )
}

export const CardHeaderLabel = ({label}) => {
    return <Text size="lg" weight={500} style={{}}>{label}</Text>
}



export const CardHeaderWithMenu = ({label, menuItems, inlineComponents, menuProps={placement: "end"}}) => {
    return (
        <CardHeader label={label}>
            {inlineComponents}
            <PalantirPopMenu {...menuProps}>
                {menuItems}
            </PalantirPopMenu>
        </CardHeader>
    )
}