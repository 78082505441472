import { tableGroups } from '../PreciousSchema'
import { views } from '../views/ViewSchema'
import MenuContainer from '../../components/menu/MenuContainer'
import { LinkWithSParams } from '../../components/navigation/Link'
import { useNavigate, useParams } from 'react-router-dom'


export const ViewMenu = ({selectedView, setSelectedView}) => {

    const { viewId } = useParams();
    const navigate = useNavigate()

    const menuSkeleton = views.map(view => {

        const viewPanels = view.components
        const panelConfigs = viewPanels.map(panel => {

            return {
                "key": panel.key,
                "label": panel.label,
                //"label": <LinkWithSParams style={{textDecoration: "none", color: "inherit"}} to={panel.key}>{panel.label}</LinkWithSParams>,
                "cbData": panel,
                "selectable": true,
                "active": viewId===panel.key,
                "children": null
            }
        })

        return {
            "key": view.key,
            "label": view.label,
            //"label": <LinkWithSParams style={{textDecoration: "none", color: "inherit"}} to={view.key}>{view.label}</LinkWithSParams>,
            "cbData": view,
            "selectable": true,
            "active": viewId===view.key,
            "children": panelConfigs
        }
    })

    return (
        <MenuContainer
            menuSkeleton={menuSkeleton}
            headerName={
                        <div className="flow-horizontal fill-parent" style={{justifyContent: "space-around"}}>
                            <div className="edit-mode">Forms</div>
                        </div>
                        }
            leafNodeClick={(item) => navigate(item.key)}
            parentNodeClick={(item) => navigate(item.key)}
            className="table-menu sidebar fill-parent"
            searchable
            collapsable
        />
    )

}

export const TableMenu = ({selectedTable, setSelectedTable}) => {

    const menuSkeleton = tableGroups.map(group => {

        let groupDisplayName = group.displayName
        let groupTables = group.tables

        const tableSkeletons = groupTables.map(table => {

            let schemaName = table.schemaName
            let tableName = table.tableName
            let tableDisplayName = table.displayNamePlural

            let isTableActive = selectedTable?.schemaName===schemaName && selectedTable?.tableName===tableName

            let HelpPanel = <table.buildHelpPanel />

            return {
                "key": tableDisplayName,
                "label": tableDisplayName,
                "meta": HelpPanel,
                "cbData": {
                    tableGroup: group,
                    table: table
                },
                "selectable": true,
                "active": isTableActive,
                "children": null
            }
        })

        return {
            "key": groupDisplayName,
            "label": groupDisplayName,
            "selectable": false,
            "active": false,
            "children": tableSkeletons
        }
    })

    return (
        <MenuContainer
            menuSkeleton={menuSkeleton}
            headerName={
                        <div className="flow-horizontal fill-parent" style={{justifyContent: "space-around"}}>
                            <div className="edit-mode">Tables</div>
                        </div>
                        }
            leafNodeClick={(menuItem) => setSelectedTable(menuItem.cbData)}
            parentNodeClick={(menuItem) => setSelectedTable(menuItem.cbData)}
            className="table-menu sidebar fill-parent"
            searchable
            collapsable
        />
    )

}