import './DebtPartner.css'
import React, { useState } from 'react'

import ViewPanel from '../../ViewPanel'
import {
    PalantirSelector,
    PalantirAutocomplete,
    PalantirDatePicker
} from '../../../../components/input/SelectPicker'
import { PalantirTextField, PalantirDispatchedTextField, makeTextMaskNumber, makeTextMaskPercentage } from '../../../../components/input/Text'
import { DebtPartnerContract, ContractDebtPartnerCounterparties, DebtPartnerPlantLLC, PlantDebtPartnerJunction } from '../../../table_configuration/DebtPartner'
import { Bank } from '../../../table_configuration/Counterparty'
import { useChangeLog, injectedChangeLogIdCol } from '../../../../hooks/changeLog'
import { useDBViewFormValidation } from '../../../../hooks/databaseViewFormValidation'
import { AddFloaterButtonWithPrompt } from '../../../../components/button/FloaterButtonWithPrompt'
import ContractCounterparties from '../ContractCounterparties'
import ContractHeader from '../ContractHeader'
import ContractIdentification from '../ContractIdentification'
import { getContractCounterpartyData, validateCreateContractCounterparty, buildContractNameErrorConfig, buildContractDependencyErrorPath } from '../utils'
import { generateUUID, ErrorRND, RNDErrorInstance, ViewFormError } from '../../../../utils/databaseAppUtils'
import { Counterparty } from '../../../table_configuration/Entity'
import { LLCWithCounterpartyFilter } from '../ContractBaseFields'

import _ from 'lodash'
import { Plant } from '../../../table_configuration/Project'

const injectedDebtPartnerName = "debtPartnerPlantLLCName"

const TextMaskPercentage = makeTextMaskPercentage()


/**
 * Proxy component for DebtPartnerPanel.
 * @param {*} props 
 * @returns 
 */
export default function DebtPartnerPanelProxy(props) {

    const contractRecords = props.data[DebtPartnerContract.buildId()]
    const contractCounterpartyRecords = props.data[ContractDebtPartnerCounterparties.buildId()]
    const debtPartnerPlantLLCRecords = props.data[DebtPartnerPlantLLC.buildId()]
    const debtPartnerPlantLLCBankJunctionRecords = props.data[PlantDebtPartnerJunction.buildId()]
    const bankRecords = props.data[Bank.buildId()]

    const [filteredToPlantContractRecords, filteredContractCounterpartyRecords] = getContractCounterpartyData(
        contractRecords, contractCounterpartyRecords, bankRecords,
        props.selectedPlantId,
        DebtPartnerContract.columnSchema.contractId, DebtPartnerContract.columnSchema.plantId,
        ContractDebtPartnerCounterparties.columnSchema.contractId, ContractDebtPartnerCounterparties.columnSchema.bankId, injectedDebtPartnerName,
    )

    return (
        <DebtPartnerPanel
            debtPartnerContractRecords={filteredToPlantContractRecords}
            allBankRecords={bankRecords}
            debtPartnerRecords={debtPartnerPlantLLCRecords}
            debtPartnerContractCounterpartyRecords={filteredContractCounterpartyRecords}
            debtPartnerPlantLLCBankJunctionRecords={debtPartnerPlantLLCBankJunctionRecords}
            validateCreateContractCounterparty={(record, newRecord) => validateCreateContractCounterparty(ContractDebtPartnerCounterparties, ContractDebtPartnerCounterparties.columnSchema.bankId, record, newRecord)}
            {...props}
        />
    )

}

/**
 * Main stateful DebtPartnerPanel component.
 * @param {*} props 
 * @returns 
 */
function DebtPartnerPanel(props) {

    const [
        debtPartnerContractRecords, prepareContractLog,
        updateContractLog, addToContractLog, deleteFromContractLog, bulkOpOnContractLog, mergeAndResetContractLog
    ] = useChangeLog(props.debtPartnerContractRecords, DebtPartnerContract)
    const [
        debtPartnerContractCounterpartiesRecords, prepareCounterpartyLog,
        updateCounterpartyLog, addToCounterpartyLog, deleteFromCounterpartyLog, bulkOpOnCounterpartyLog, mergeAndResetCounterpartyLog
    ] = useChangeLog(props.debtPartnerContractCounterpartyRecords, ContractDebtPartnerCounterparties, props.validateCreateContractCounterparty)
    const [errors, addErrors, removeErrors, setErrors, verifyChangelogSubmission, resetErrors] = useDBViewFormValidation()

    const addToContractLogProxy = () => {
        let contract = DebtPartnerContract.buildNewRecord()
        contract[DebtPartnerContract.columnSchema.contractId] = generateUUID()
        contract[DebtPartnerContract.columnSchema.plantId] = props.selectedPlantId
        addToContractLog(contract)
    }
    const deleteFromContractLogProxy = (debtPartnerContract) => {
        // Delete all counterparty records associated with this contract, if any
        let contractId = debtPartnerContract[DebtPartnerContract.columnSchema.contractId]
        let counterpartiesInDeletedContract = debtPartnerContractCounterpartiesRecords.filter(record => record[ContractDebtPartnerCounterparties.columnSchema.contractId]===contractId)
        deleteFromCounterpartyLog(counterpartiesInDeletedContract)
        deleteFromContractLog(debtPartnerContract)
        removeErrors([contractId])
    }

    const debtPartnerContractComponents = debtPartnerContractRecords.map(debtPartnerContract => {
        let contractId = debtPartnerContract[DebtPartnerContract.columnSchema.contractId]
        let debtPartnerCounterparties = debtPartnerContractCounterpartiesRecords.filter(x => x[ContractDebtPartnerCounterparties.columnSchema.contractId]===contractId)
        return (
            <DebtPartnerContractForm
                key={contractId}
                debtPartnerContract={debtPartnerContract}
                contractId={contractId}
                allDebtPartnerPlantLLCs={props.debtPartnerRecords}
                allBankRecords={props.allBankRecords}
                debtPartnerCounterparties={debtPartnerCounterparties}
                debtPartnerPlantLLCBankJunctionRecords={props.debtPartnerPlantLLCBankJunctionRecords}
                updateContract={(update) => updateContractLog(debtPartnerContract, update)}
                updateContractCounterparty={updateCounterpartyLog}
                addToCounterpartyLog={() => {
                    let contractCounterparty = ContractDebtPartnerCounterparties.buildNewRecord()
                    contractCounterparty[ContractDebtPartnerCounterparties.columnSchema.contractId] = contractId
                    addToCounterpartyLog(contractCounterparty)
                }}
                deleteFromContractLog={() => deleteFromContractLogProxy(debtPartnerContract)}
                deleteFromCounterpartyLog={deleteFromCounterpartyLog}
                errors={errors}
                setErrors={setErrors}
                removeErrors={removeErrors}
            />
        )
    })

    return (
        <ViewPanel
            services={props.services}
            title="DebtPartner Contract"
            submitUrl='/api/precious/table/debt_partner_contract'
            verifySubmit={(payload) => {
                return verifyChangelogSubmission(
                    {
                        changeLog: payload.debtPartnerContractChangelog,
                        checks: [buildContractNameErrorConfig(DebtPartnerContract)],
                    }, {
                        changeLog: payload.debtPartnerContractCounterpartyChangelog,
                        checks: [{
                            checkColumn: ContractDebtPartnerCounterparties.columnSchema.bankId,
                            checkFunction: "nullCheck",
                            errMessage: "Please select a bank.",
                            path: buildContractDependencyErrorPath(ContractDebtPartnerCounterparties, ContractDebtPartnerCounterparties.columnSchema.bankId),
                        }, {
                            checkColumn: ContractDebtPartnerCounterparties.columnSchema.financingType,
                            checkFunction: "nullCheck",
                            errMessage: "Please select a financing type.",
                            path: buildContractDependencyErrorPath(ContractDebtPartnerCounterparties, ContractDebtPartnerCounterparties.columnSchema.financingType)
                        }]
                    }
                )
            }}
            onSubmitSuccess={(response, requestPayload) => {
                props.handleUpdate(false)
                resetErrors()
                mergeAndResetContractLog()
                mergeAndResetCounterpartyLog()
            }}
            onSubmitError={null}
            buildSubmitPayload={() => {
                return {
                    debtPartnerContractChangelog: prepareContractLog(),
                    debtPartnerContractCounterpartyChangelog: prepareCounterpartyLog(),
                }
            }}
        >
            {debtPartnerContractComponents}
            <AddFloaterButtonWithPrompt
                onClick={addToContractLogProxy}
                labelContent="Add new contract"
                height={18}
                width={18}
            />
        </ViewPanel>
    )
}

/**
 * 
 */
const DebtPartnerContractForm = React.memo(function({
    debtPartnerContract, contractId, allDebtPartnerPlantLLCs, debtPartnerCounterparties, debtPartnerPlantLLCBankJunctionRecords, allBankRecords,
    updateContract, updateContractCounterparty, addToCounterpartyLog, deleteFromCounterpartyLog, deleteFromContractLog, errors, setErrors, removeErrors
}) {
    
    const debtPartnerLLCId = debtPartnerContract[DebtPartnerContract.columnSchema.debtPartnerLLCId]

    // These are the banks that belong to the selected debt partner llc
    const availableBankJunctionRecords = debtPartnerPlantLLCBankJunctionRecords.filter(r => r[PlantDebtPartnerJunction.columnSchema.debtPartnerId]===debtPartnerLLCId)
    const availableBankIds = availableBankJunctionRecords.map(x => x[PlantDebtPartnerJunction.columnSchema.bankId])
    const availableBanks = allBankRecords.filter(bank => availableBankIds.includes(bank[Counterparty.columnSchema.counterpartyId]))

    return (
        <div className="field-group form-instance" style={{scroll: "auto", marginBottom: "100px", minWidth: "1200px"}}>
            <ContractHeader
                contractName={debtPartnerContract[DebtPartnerContract.columnSchema.contractName]}
                deleteFromContractLog={deleteFromContractLog}
            />
            <ContractIdentification
                ContractTable={DebtPartnerContract}
                contract={debtPartnerContract}
                contractIdCol={DebtPartnerContract.columnSchema.contractId}
                contractNameCol={DebtPartnerContract.columnSchema.contractName}
                contractPlantIdCol={DebtPartnerContract.columnSchema.plantId}
                onContractNameChange={(x) => updateContract({[DebtPartnerContract.columnSchema.contractName]: x})}
                errors={errors}
                removeErrors={removeErrors}
            />
            <PalantirSelector
                label="Status"
                value={debtPartnerContract[DebtPartnerContract.columnSchema.status]}
                items={DebtPartnerContract.options.status}
                onChange={(x) => updateContract({[DebtPartnerContract.columnSchema.status]: x})}
                style={{marginRight: "20px"}}
            />
            <PalantirDatePicker
                label="Effective Date"
                value={debtPartnerContract[DebtPartnerContract.columnSchema.effectiveDate]}
                onChange={(date) => updateContract({[DebtPartnerContract.columnSchema.effectiveDate]: date})}
                style={{marginRight: "15px", marginBottom: "15px"}}
            />
            <PalantirDatePicker
                label="End Date"
                value={debtPartnerContract[DebtPartnerContract.columnSchema.endDate]}
                onChange={(date) => updateContract({[DebtPartnerContract.columnSchema.endDate]: date})}
                style={{marginRight: "15px", marginBottom: "15px"}}
            />
            <br></br>
            <PalantirDispatchedTextField
                label="Notes"
                value={debtPartnerContract[DebtPartnerContract.columnSchema.notes]}
                multiline
                rows={3}
                //fullWidth
                variant="filled"
                onChange={(x) => updateContract({[DebtPartnerContract.columnSchema.notes]: x})}
                style={{marginTop: "15px", marginBottom: "15px"}}
            />
            <div className="">
                <div className="header">Debt Partners</div>
                <div className="flow-horizontal" style={{flexWrap: "wrap"}}>
                    <div style={{flexShrink: 0}}>
                        <PalantirDatePicker
                            label="Expected Financial Close Date"
                            value={debtPartnerContract[DebtPartnerContract.columnSchema.expectedFinancialCloseDate]}
                            onChange={(date) => updateContract({[DebtPartnerContract.columnSchema.expectedFinancialCloseDate]: date})}
                            style={{marginRight: "15px", marginBottom: "15px"}}
                        />
                        <PalantirDatePicker
                            label="Actual Financial Close Date"
                            value={debtPartnerContract[DebtPartnerContract.columnSchema.actualFinancialCloseDate]}
                            onChange={(date) => updateContract({[DebtPartnerContract.columnSchema.actualFinancialCloseDate]: date})}
                            style={{marginRight: "15px", marginBottom: "15px"}}
                        />
                    </div>
                </div>
                <div style={{marginBottom: "15px"}}>
                    <PalantirTextField
                        label="Sharepoint Contract Folder Link"
                        value={debtPartnerContract[DebtPartnerContract.columnSchema.sharepointLink]}
                        helperText="This should be the folder link, not the file."
                        onChange={(x) => updateContract({[DebtPartnerContract.columnSchema.sharepointLink]: x})}
                    />
                    <div style={{marginTop: "4px"}}><a href={debtPartnerContract[DebtPartnerContract.columnSchema.sharepointLink]} target="_blank">Click here to visit Sharepoint link</a></div>
                </div>
                <LLCWithCounterpartyFilter
                    contractId={contractId}
                    contractRecord={debtPartnerContract}
                    contractLLCIdCol={DebtPartnerContract.columnSchema.debtPartnerLLCId}
                    ContractCounterpartyTable={ContractDebtPartnerCounterparties}
                    contractCounterpartyRecords={debtPartnerCounterparties}
                    contractCounterpartyIdCol={ContractDebtPartnerCounterparties.columnSchema.bankId}
                    llcIdCol={DebtPartnerPlantLLC.columnSchema.debtPartnerId}
                    llcNameCol={DebtPartnerPlantLLC.columnSchema.debtPartnerName}
                    allLLCRecords={allDebtPartnerPlantLLCs}
                    llcCounterpartyJunctionRecords={debtPartnerPlantLLCBankJunctionRecords}
                    llcCounterpartyJunctionLLCIdCol={PlantDebtPartnerJunction.columnSchema.debtPartnerId}
                    llcCounterpartyJunctionCounterpartyIdCol={PlantDebtPartnerJunction.columnSchema.bankId}
                    llcLabel="Debt Partner LLC"
                    counterpartyLabel="Bank"
                    errors={errors}
                    setErrors={setErrors}
                    updateContract={updateContract}
                />
                <ContractCounterparties
                    ContractCounterpartyTable={ContractDebtPartnerCounterparties}
                    contract={debtPartnerContract}
                    contractIdCol={DebtPartnerContract.columnSchema.contractId}
                    contractCounterpartyIdCol={ContractDebtPartnerCounterparties.columnSchema.bankId}
                    contractCounterpartyNameCol={injectedDebtPartnerName}
                    contractCounterpartyNotesCol={ContractDebtPartnerCounterparties.columnSchema.notes}
                    allCounterpartyRecords={availableBanks}
                    filteredContractCounterpartyRecords={debtPartnerCounterparties}
                    deleteFromCounterpartyLog={deleteFromCounterpartyLog}
                    addToCounterpartyLog={addToCounterpartyLog}
                    updateContractCounterparty={updateContractCounterparty}
                    SupplementalComponent={({contractCounterpartyRecord}) => {
                        const financingTypeErrorPath = [
                            contractId, ContractDebtPartnerCounterparties.buildId(), contractCounterpartyRecord[injectedChangeLogIdCol], ContractDebtPartnerCounterparties.columnSchema.financingType
                        ]
                        const financingTypeEmptyError = errors.get(...financingTypeErrorPath)
                        const financingTypeErrorProps = financingTypeEmptyError ? {error: true, helperText: financingTypeEmptyError.getMessage()} : {}

                        return (
                            <div>
                                <PalantirSelector
                                    label="Financing Type"
                                    value={contractCounterpartyRecord[ContractDebtPartnerCounterparties.columnSchema.financingType]}
                                    items={ContractDebtPartnerCounterparties.options.type}
                                    onChange={(x) => {
                                        removeErrors(financingTypeErrorPath)
                                        updateContractCounterparty(contractCounterpartyRecord, {[ContractDebtPartnerCounterparties.columnSchema.financingType]: x})
                                    }}
                                    FormControlProps={financingTypeErrorProps}
                                    style={{marginRight: "10px"}}
                                    {...financingTypeErrorProps}
                                />
                                <PalantirSelector
                                    label="Financing Status"
                                    value={contractCounterpartyRecord[ContractDebtPartnerCounterparties.columnSchema.financingStatus]}
                                    items={ContractDebtPartnerCounterparties.options.status}
                                    onChange={(x) => updateContractCounterparty(contractCounterpartyRecord, {[ContractDebtPartnerCounterparties.columnSchema.financingStatus]: x})}
                                />
                            </div>
                        )
                    }}
                    counterpartyType="Debt Partner"
                    counterpartyTypePlural="Debt Partners"
                    orientation="horizontal"
                    excludeHeader={true}
                    errors={errors}
                    removeErrors={removeErrors}
                    style={{paddingBottom: "20px"}}
                />
            </div>
        </div>
    )
}, (prevProps, nextProps) => {
    return (
        _.isEqual(prevProps.debtPartnerContract, nextProps.debtPartnerContract) &&
        _.isEqual(prevProps.debtPartnerCounterparties, nextProps.debtPartnerCounterparties) &&
        _.isEqual(prevProps.errors, nextProps.errors)
    )
})